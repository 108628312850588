import { useCallback } from 'react';
import { useNavigate as useRouterNavigate, useSearchParams, To, NavigateOptions } from 'react-router-dom';

const addLeadingSlash = (pathname: string | undefined) => {
    if (pathname === undefined) return pathname;

    if (pathname?.[0] === '/') {
        return pathname;
    }

    return `/${pathname}`;
};

/**
 * Local implementation of the useNavigate hook from react-router-dom.
 * Meant to do the same thing, but with the added benefit of keeping the search params in the URL when navigating.
 * If '/' is missing at the beginning of the pathname in to parameter, it will be added.
 */
export const useKapplaNavigate = () => {
    const routerNavigate = useRouterNavigate();
    const [searchParams] = useSearchParams();

    const navigate = useCallback(({ to, options }: { to: To, options?: NavigateOptions }) => {
        if (typeof to === 'string') {
            routerNavigate({
                pathname: addLeadingSlash(to),
                search: searchParams.toString()
            }, options);
        } else {
            routerNavigate(
                {
                    ...to,
                    pathname: addLeadingSlash(to.pathname),
                    search: new URLSearchParams({ ...Object.fromEntries(searchParams), ...Object.fromEntries(new URLSearchParams(to.search)) }).toString()
                },
                options
            );
        }
    }, [routerNavigate, searchParams]);

    return navigate;
};
