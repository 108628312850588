import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { API } from 'services';
import useData from 'hooks/useData';
import { ComboBoxMultiSelect } from 'types/appContext';
import { BusinessUnitsList } from 'types/configurationPage';
import { useToast } from 'hooks/useToast';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import Tooltip from '@ingka/tooltip';
import useAuth from 'hooks/useAuth';
import Combobox, { MenuItem } from '@ingka/combobox';
import classes from './DisableBusinessUnit.module.scss';

const createUnitsReqData = (list: ComboBoxMultiSelect[]): BusinessUnitsList[] => list?.map((unit: ComboBoxMultiSelect) => ({
    buCode: unit.id,
    buType: unit.type,
    status: unit.isChecked,
    buName: unit.label
}));

const createComboBoxProps = (list: BusinessUnitsList[]): ComboBoxMultiSelect[] => list?.map((unit: BusinessUnitsList) => ({
    id: unit.buCode,
    type: unit.buType,
    label: unit.buName,
    value: unit.buCode,
    status: unit.status,
    isChecked: unit.status,
}));

const DisableBusinessUnit = () => {
    const { access } = useAuth();
    const { t } = useTranslation();
    const { displayToast } = useToast();
    const { currentCountry } = useData();
    const [units, setUnits] = useState<ComboBoxMultiSelect[] | null>(null);
    const totalUnits = units?.length ?? 0;
    const totalEnabledUnits = units?.filter(({ isChecked }) => isChecked)?.length ?? 0;

    useEffect(() => {
        if (currentCountry && access?.api?.enableDisableUnits) {
            API().getUnitsList(currentCountry)
                .then((responseData: BusinessUnitsList[]) => {
                    if (responseData.length > 0) {
                        const getUnitsData = createComboBoxProps(responseData);
                        setUnits(getUnitsData);
                    }
                })
                .catch(err => {
                    displayToast({ title: 'ERROR', message: err.message });
                    setUnits(null);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCountry]);

    const updateUnitsList = (data: ComboBoxMultiSelect[] | null) => {
        if (data && data?.length > 0 && access?.api?.enableDisableUnits) {
            const modifiedUnits = createUnitsReqData(data);
            API().updateUnitsList(currentCountry, modifiedUnits)
                .then(response => {
                    if (response.message) {
                        displayToast({ title: t('SUCCESS'), message: t('BUSINESS_UNITS_UPDATED') });
                    }
                }).catch(() => {
                    displayToast({ title: t('ERROR'), message: t('BUSINESS_UNITS_UPDATION_FAILED') });
                });
        }
    };

    const handleUnitOnClick = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const updatedData = units?.map(unit => (unit.label === e.currentTarget.innerText ? { ...unit, isChecked: !unit.isChecked } : unit)) ?? null;
        setUnits(updatedData);
        updateUnitsList(updatedData?.filter((unit: ComboBoxMultiSelect) => (unit.label === e.currentTarget.innerText)) ?? null);
    };

    const handleOnClearClick = () => {
        const updatedData = units?.map((unit: ComboBoxMultiSelect) => ({ ...unit, isChecked: false })) ?? null;
        setUnits(updatedData);
        updateUnitsList(updatedData);
    };

    return (
        <>
            <div id="config-disable-unit-toggle-div" className={classes['disable-bu__header']}>
                <Text tagName="h5" headingSize="xs" id="config-disable-unit-title" data-testid="config-disable-unit-title">
                    {t('ENABLE_DISABLE_BUSINESS_UNITS')}
                </Text>
                <Tooltip
                    tooltipText={t('ENABLE_UNITS_TOOLTIP')}
                    data-testid="config-disable-unit-icon"
                    position="trailing"
                    ssrIcon={informationCircle}
                    className={classes['disable-bu__tooltip']}
                />
            </div>

            <div className={classes['disable-bu__container']}>
                <div className={classes['disable-bu__dropdown']}>
                    <Combobox
                        label={t('ENABLE_DISABLE_BUSINESS_UNITS')}
                        listboxId="enable-disable-units"
                        labelId="enable-disable-units"
                        placeholder={t('CLICK_ENABLE_DISABLE')}
                        disabled={!units}
                        multiple
                        value={units?.filter(({ isChecked }) => isChecked).map(({ id }) => `item-id-${id}`)}
                        onClearClicked={handleOnClearClick}
                    >
                        {units?.map(({ id, label }) => (
                            <MenuItem
                                data-testid={`item-id-${id}`}
                                key={`item-id-${id}`}
                                id={`item-id-${id}`}
                                title={label}
                                onClick={handleUnitOnClick}
                            />
                        ))}
                    </Combobox>
                    <div id="disabled-units-note" data-testid="disabled-units-note" className={classes['disable-bu__note']}>
                        <Text tagName="" bodySize="l">
                            {t('NOTE_ENABLE_UNITS', { totalEnabledUnits, totalUnits })}
                        </Text>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DisableBusinessUnit;
