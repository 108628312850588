export const ROUTE_CAPACITY_OVERVIEW = 'capacityOverview';
export const ROUTE_MANAGE_CAPACITY = 'manageCapacity';
export const ROUTE_CAPACITY_ACTION_PLAN = 'capacityActionPlan';
export const ROUTE_UNAUTHORIZED = 'unauthorized';
export const ROUTE_CONFIGURATION = 'configuration';
export const ROUTE_RECRUITMENT_NEEDS = 'recruitmentNeeds';

export const ROUTE_DEFAULT = ROUTE_CAPACITY_OVERVIEW;

/**
 * Type with all routes used in Kappla
 */
export type KapplaRoute = typeof ROUTE_CAPACITY_OVERVIEW
| typeof ROUTE_MANAGE_CAPACITY
| typeof ROUTE_CAPACITY_ACTION_PLAN
| typeof ROUTE_UNAUTHORIZED
| typeof ROUTE_CONFIGURATION
| typeof ROUTE_RECRUITMENT_NEEDS;
