import { Fragment, memo, useState } from 'react';
import Button from '@ingka/button';
import Pencil from '@ingka/ssr-icon/paths/pencil';
import ArrowRight from '@ingka/ssr-icon/paths/arrow-right';
import { useTranslation } from 'react-i18next';
import Table, { TableBody, TableHeader } from '@ingka/table';
import useApp from 'hooks/useApp';
import { useKapplaNavigate } from 'components/Router/useKapplaNavigate';
import * as colours from '@ingka/variables/colours-css';
import { CoworkerDetailsEditModal } from 'components/CoworkerDetails/CoworkerDetailsEditModal';
import { COWORKER_CHANGE_CONTRACT_BUTTON__CLICK, MANAGE_CAPACITY_REDIRECT_BUTTON__CLICK } from 'types/analytics';
import useData from 'hooks/useData';
import { CoworkerAction,
    TypeAction,
    TypeCCDistributionAction,
    TypeContractDatesAction,
    TypeContractHoursAction,
    TypeContractTypeAction,
    TypeCoworkerActionPlan,
    TypeModulationHoursAction,
    TypeNewCoworkerAction,
    TypeWeeklyHoursAction } from 'types/actionPlan';
import { useUpdateActionPlanCoworkerNote } from 'hooks/api/useUpdateActionPlanCoworkerNote';
import { useToast } from 'hooks/useToast';
import { calculateNumberByPercentage, calculateSum, getHoursInDecimal } from 'utils/number';
import { ContractDelta, ScenarioDelta, ScenarioDeltaCategory, WeeklyDelta } from 'types/scenario';
import { BEGINNING_OF_TIME, isoWeekFromDateString } from 'utils/date';
import moment from 'moment';
import { ROUTE_MANAGE_CAPACITY } from 'types/routes';
import classes from './ActionTable.module.scss';
import ModulationAction from './Actions/ModulationAction';
import ActionGroup from './Actions/ActionGroup';
import { ContractDates, ContractHours, ContractType } from './Actions/ContractAction';
import AdditionalHoursAction from './Actions/AdditionalHoursAction';
import AvailabilityAction from './Actions/AvailabilityAction';
import NewCoworkerAction from './Actions/NewCoworkerAction';
import CCDistributionAction from './Actions/CCDistributionAction';
import WeeklyHoursAction from './Actions/WeeklyHoursAction';

// We create two new return types to avoid type casting and to ensure the delta and type are aligned
const findLatestCoworkerDelta = (scenarioDelta: ScenarioDelta | undefined, targetStartDate: string): {
    delta: WeeklyDelta;
    type: ScenarioDeltaCategory.WEEKLY_DELTA;
} | {
    delta: ContractDelta;
    type: ScenarioDeltaCategory.CONTRACT_DELTA;
} | null => {
    const weeklyDelta = scenarioDelta?.weeklyDeltas?.find(
        wd => isoWeekFromDateString(wd.startDate) === isoWeekFromDateString(targetStartDate),
    );
    const contractDelta = scenarioDelta?.contractDeltas?.find(wd => moment(targetStartDate).isBetween(moment(wd.startDate), moment(wd.endDate)));

    if (weeklyDelta && moment(weeklyDelta.timeStamp).isAfter(moment(contractDelta?.timeStamp ?? BEGINNING_OF_TIME))) {
        return {
            delta: weeklyDelta,
            type: ScenarioDeltaCategory.WEEKLY_DELTA,
        };
    }

    if (contractDelta) {
        return {
            delta: contractDelta,
            type: ScenarioDeltaCategory.CONTRACT_DELTA,
        };
    }

    return null;
};

const getTotalHours = (
    actions: TypeAction[],
    actionGroupStartDate: string,
    scenarioDelta: ScenarioDelta | undefined,
): number => {
    // When group contains contract hours action or weekly hours action
    const contractHoursActions = actions.filter(
        (a): a is (TypeContractHoursAction | TypeWeeklyHoursAction) => a.type === CoworkerAction.CONTRACT_HOURS || a.type === CoworkerAction.WEEKLY_HOURS,
    );
    const modulationAction = actions.find((a): a is TypeModulationHoursAction => a.type === CoworkerAction.MODULATION);
    if (contractHoursActions?.length && !modulationAction) {
        return calculateSum(contractHoursActions.map(el => {
            if (typeof el.to === 'number') {
                return el.to;
            }

            return el.to.range.max;
        }));
    }

    // When group contains cc distribution action but no contract hours action
    const ccDistributionAction = actions.find((a): a is TypeCCDistributionAction => a.type === CoworkerAction.CC_DISTRIBUTION);
    if (ccDistributionAction && !contractHoursActions?.length) {
        const anyValidCCDistribution = ccDistributionAction.ccDistributions.find(
            cc => !!cc.to.percentage,
        );

        if (anyValidCCDistribution) {
            const { hours, percentage } = anyValidCCDistribution.to;

            return calculateNumberByPercentage(hours, percentage);
        }
    }

    // When group is creation of a coworker
    const newCoworkerAction = actions.find((a): a is TypeNewCoworkerAction => a.type === CoworkerAction.NEW_COWORKER);
    if (newCoworkerAction) {
        if (typeof newCoworkerAction.hours === 'number') {
            return newCoworkerAction.hours;
        }

        return newCoworkerAction.hours.range.max;
    }

    // When group only contains contract type ation
    const contractTypeAction = actions.find((a): a is TypeContractTypeAction => a.type === CoworkerAction.CONTRACT_TYPE);
    if (contractTypeAction && !contractHoursActions?.length && !ccDistributionAction) {
        return contractTypeAction.hoursPerWeek;
    }

    if (modulationAction) {
        const modulationDeltaHours = getHoursInDecimal(modulationAction.toHours, modulationAction.toMinutes)
            - getHoursInDecimal(modulationAction.fromHours, modulationAction.fromMinutes);

        const latestDelta = findLatestCoworkerDelta(scenarioDelta, actionGroupStartDate);
        if (latestDelta?.type === ScenarioDeltaCategory.WEEKLY_DELTA) {
            return latestDelta.delta.costCentreHours + modulationDeltaHours;
        }

        if (latestDelta?.type === ScenarioDeltaCategory.CONTRACT_DELTA) {
            if (typeof latestDelta.delta.hoursPerWeek === 'number') {
                return latestDelta.delta.hoursPerWeek + modulationDeltaHours;
            }

            return latestDelta.delta.hoursPerWeekRange.range.max + modulationDeltaHours;
        }

        return modulationDeltaHours;
    }

    const contractDateAction = actions.find((a): a is TypeContractDatesAction => a.type === CoworkerAction.CONTRACT_DATES);
    if (contractDateAction) {
        return contractDateAction.hoursPerWeek;
    }

    return 0;
};

const ContractChanges = ({ coworkerActionPlan }: { coworkerActionPlan: TypeCoworkerActionPlan }) => {
    const { currentScenario } = useData();
    const scenarioCoworker = currentScenario?.scenarioData.find(el => el.personId === coworkerActionPlan.personId);

    return (
        <>
            {coworkerActionPlan.actionGroups.map((actionGroup, actionGroupIndex) => {
                const baseKey = `${coworkerActionPlan.personId}-${actionGroup.startDate}-${actionGroup.endDate}-${actionGroupIndex}`;

                return (
                    <Fragment key={baseKey}>
                        <ActionGroup
                            actionGroup={actionGroup}
                            totalHours={getTotalHours(actionGroup.actions, actionGroup.startDate, scenarioCoworker)}
                        >
                            {actionGroup.actions.map(action => {
                                switch (action.type) {
                                case CoworkerAction.NEW_COWORKER:
                                    return (
                                        <NewCoworkerAction
                                            action={action}
                                            contractType={coworkerActionPlan.contractType}
                                            key={`${baseKey}-${action.type}`}
                                        />
                                    );
                                case CoworkerAction.ADDITIONAL_HOURS:
                                    return <AdditionalHoursAction action={action} key={`${baseKey}-${action.type}`} />;
                                case CoworkerAction.MODULATION:
                                    return <ModulationAction action={action} key={`${baseKey}-${action.type}`} />;
                                case CoworkerAction.CONTRACT_TYPE:
                                    return <ContractType action={action} key={`${baseKey}-${action.type}`} />;
                                case CoworkerAction.CONTRACT_DATES:
                                    return <ContractDates action={action} key={`${baseKey}-${action.type}`} />;
                                case CoworkerAction.AVAILABILITY:
                                    return <AvailabilityAction action={action} key={`${baseKey}-${action.type}`} />;
                                case CoworkerAction.WEEKLY_HOURS:
                                    return <WeeklyHoursAction action={action} key={`${baseKey}-${action.type}`} />;
                                case CoworkerAction.CC_DISTRIBUTION:
                                    return <CCDistributionAction action={action} key={`${baseKey}-${action.type}`} />;
                                default:
                                    return <ContractHours action={action} key={`${baseKey}-${action.type}`} />;
                                }
                            })}
                        </ActionGroup>
                    </Fragment>
                );
            })}
        </>
    );
};

const ActionTableCoworkerDetails = memo(({ coworkerActionPlan }: { coworkerActionPlan: TypeCoworkerActionPlan }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const { setCurrentCoworkerId } = useApp();
    const navigate = useKapplaNavigate();
    const { coworkerData, currentScenario } = useData();
    const { personId } = coworkerActionPlan;
    const coworker = coworkerActionPlan.isDummy
        ? currentScenario?.scenarioData.find(el => el.personId === personId)?.dummyCoworker
        : coworkerData?.find(el => el.personId === personId);
    const { updateActionPlanCoworkerNote } = useUpdateActionPlanCoworkerNote();
    const { displayToast } = useToast();

    const handleClickChange = () => {
        setIsModalOpen(true);
    };

    const handleClickManageCapacity = () => {
        setCurrentCoworkerId(personId);
        navigate({ to: ROUTE_MANAGE_CAPACITY });
    };

    const saveNote = async (note: string) => {
        updateActionPlanCoworkerNote({ personId, note }).then(resp => {
            if (!resp.isResponseOk) {
                displayToast({ title: t('ERROR'), message: t('SAVE_FAILED') });

                return;
            }

            displayToast({ title: t('SUCCESS'), message: t('SAVE_SUCCEEDED') });
        });
    };

    return (
        <div className={classes['details-container']} data-testid="action-plan-coworker-details">
            <div className={classes['details-container-buttons']}>
                <Button
                    data-testid="user-detail-button"
                    text={t('CHANGE_SCENARIO_DATA')}
                    iconPosition="leading"
                    size="small"
                    ssrIcon={Pencil}
                    type="primary"
                    onClick={handleClickChange}
                    data-analytics={COWORKER_CHANGE_CONTRACT_BUTTON__CLICK}
                />

                <Button
                    data-testid="user-detail-button"
                    text={t('MANAGE_CAPACITY')}
                    iconPosition="trailing"
                    size="small"
                    ssrIcon={ArrowRight}
                    type="secondary"
                    onClick={handleClickManageCapacity}
                    color={colours.colourNeutral2}
                    data-analytics={MANAGE_CAPACITY_REDIRECT_BUTTON__CLICK}
                />
            </div>

            <div className={classes['details-table']}>
                <Table>
                    <TableHeader>
                        <tr>
                            <th>{t('DATE')}</th>
                            <th>{t('CHANGES')}</th>
                            <th>{t('WEEKLY_TOTAL_HOURS')}</th>
                            <th>{t('CHANGED_BY')}</th>
                        </tr>
                    </TableHeader>
                    <TableBody>
                        <ContractChanges coworkerActionPlan={coworkerActionPlan} />
                    </TableBody>
                </Table>
                <div className={classes['details-note']}>
                    <label htmlFor="contractChangesNote">{t('NOTES')}</label>
                    <textarea
                        className={coworkerActionPlan.note ? 'filled' : 'empty'}
                        name="contractChangesNote"
                        placeholder={`${t('NOTE_PLACEHOLDER')}...`}
                        maxLength={4096}
                        cols={60}
                        rows={10}
                        defaultValue={coworkerActionPlan.note}
                        onBlur={event => saveNote(event.target.value)}
                    />
                </div>
            </div>
            {coworker && (
            <CoworkerDetailsEditModal coworker={coworker} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            )}
        </div>
    );
});

export default ActionTableCoworkerDetails;
