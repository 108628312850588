import { TypeCCDistributionAction } from 'types/actionPlan';
import { useTranslation } from 'react-i18next';
import useData from 'hooks/useData';
import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import { getShortCC } from 'utils/text';
import Text from '@ingka/text';
import classes from '../ActionTable.module.scss';

const CCDistributionAction = ({ action }:{ action: TypeCCDistributionAction }) => {
    const { t } = useTranslation();
    const { orgData } = useData();
    const { ccDistributions } = action;

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-cost-distribution-header"
                >
                    {t('COST_CENTER_DISTRIBUTION')}
                </Text>
                {ccDistributions.map(cc => (
                    <Text
                        className={classes['details-changes__cc-distribution']}
                        key={cc.to.costCentre}
                        data-testid="action-plan-cost-distribution-change"
                    >
                        {getCostCentreDescription(cc.from.costCentre, orgData)}
                        {' '}
                        {`CC${getShortCC(cc.from.costCentre)}`}
                        <b>
                            {` (${cc.from.hours} ${t('HOURS_ABBREVIATED')}) ➔ `}
                        </b>
                        {getCostCentreDescription(cc.to.costCentre, orgData)}
                        {' '}
                        {`CC${getShortCC(cc.to.costCentre)}`}
                        <b>
                            {' '}
                            {`(${cc.to.hours} ${t('HOURS_ABBREVIATED')})`}
                        </b>
                    </Text>
                ))}

            </div>
        </div>
    );
};

export default CCDistributionAction;
