import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import useData from 'hooks/useData';
import { API } from 'services';
import { useTranslation } from 'react-i18next';
import { FileFormat } from 'types/fileFormat';
import { useToast } from 'hooks/useToast';
import useAuth from 'hooks/useAuth';
import FileUploadPanel from './FileUpload/FileUploadPanel';
import { APIPath } from './FileUpload/types';
import CapacityFactorGroup from './CapacityFactorPanelGroup';
import classes from './CapacityFactor.module.scss';
import ScenarioFileUpload from './FileUpload/ScenarioFileUpload';
import ExternalBudget from './ExternalBudget';

const CapacityFactorPanel = () => {
    const { t } = useTranslation();
    const {
        currentCountry,
        orgData,
        currentUnit,
        currentUnitType,
        currentScenario,
        scenarioBudget,
        getUploadedFileData,
        config,
    } = useData();
    const [currentUnitName, setCurrentUnitName] = useState<string | undefined>();
    const { displayToast } = useToast();
    const { access } = useAuth();

    const getScenarioID = (Category: string): string | undefined => {
        if (scenarioBudget && Category === APIPath.BUDGET_HOURS) {
            return currentScenario?.id;
        }

        return undefined;
    };

    const onClickDownloadFile = async (load: string, category: string) => {
        if (load && category) {
            API()
                .getDownloadFile(category, currentCountry, currentUnit, currentUnitType, getScenarioID(category))
                .then(data => {
                    const hyperLink = document.createElement('a');
                    hyperLink.href = URL.createObjectURL(new Blob([data], { type: FileFormat.xlsx }));
                    hyperLink.download = `${category}_${currentCountry}_${currentUnit}`; // Setting the FileName as budget_DK_121
                    hyperLink.click();
                    setTimeout(() => {
                        URL.revokeObjectURL(hyperLink.href);
                    }, 1000);
                })
                .catch(() => {
                    displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });
                });
        }
    };

    useEffect(() => {
        const labelName = orgData?.businessUnits.find(u => u.code === currentUnit)?.name;
        setCurrentUnitName(labelName);
    }, [orgData, currentUnit]);

    return (
        <div className={classes['main-div']}>
            <div className={classes['unit-name']} data-testid="CapacityFactorPanel-unit-name">
                <Text tagName="span" headingSize="s">
                    {t('UNIT')}
                </Text>
                : &nbsp;
                <Text tagName="span" bodySize="l">
                    {currentUnitName ?? 'N/A'}
                </Text>
            </div>
            <CapacityFactorGroup headerText={t('CORE_FUNCTIONALITY')}>
                <FileUploadPanel
                    heading={t('CAPACITY_FACTORS')}
                    description={t('CAPACITY_FACTOR_REVIEW')}
                    id="CAPACITY_FACTORS"
                    data-testid="CapacityFactorPanel-capacity-factors"
                    apiType={APIPath.CAPACITY_FACTORS}
                    handleDownloadFile={onClickDownloadFile}
                />
                {config?.isBudgetExternal ? (
                    <ExternalBudget
                        disabled={scenarioBudget}
                        reFetchBudget={() => getUploadedFileData(APIPath.BUDGET_HOURS)}
                    />
                )
                    : (
                        <FileUploadPanel
                            heading={t('BUGDET_HOURS')}
                            description={t('BUGDET_HOURS_REVIEW')}
                            id="BUDGET_HOURS"
                            data-testid="CapacityFactorPanel-budget-hours"
                            apiType={APIPath.BUDGET_HOURS}
                            handleDownloadFile={onClickDownloadFile}
                            disabled={scenarioBudget}
                        />
                    )}
                {currentScenario && (
                    <div>
                        <ScenarioFileUpload enableFileUpload={scenarioBudget} />
                        <div>
                            {scenarioBudget && (
                                <FileUploadPanel
                                    heading={t('BUGDET_HOURS_SCENARIO')}
                                    description={t('BUGDET_HOURS_SCENARIO_REVIEW')}
                                    id="BUDGET_HOURS_SCENARIO"
                                    data-testid="CapacityFactorPanel-budget-hours-scenario"
                                    apiType={APIPath.BUDGET_HOURS}
                                    handleDownloadFile={onClickDownloadFile}
                                    scenarioId={currentScenario.id}
                                />
                            )}
                        </div>
                    </div>
                )}
            </CapacityFactorGroup>
            <CapacityFactorGroup headerText={t('EVENINGS_WEEKENDS')}>
                <FileUploadPanel
                    heading={t('DEFAULT_CONTRACT_CONTRIBUTION')}
                    description={t('EVENINGS_WEEKENDS_DESCRIPTION')}
                    id="DEFAULT_CONTRIBUTIONS"
                    data-testid="CapacityFactorPanel-default-contributions"
                    apiType={APIPath.DEFAULT_CONFIG}
                    handleDownloadFile={onClickDownloadFile}
                />
                <FileUploadPanel
                    heading={t('COWORKER_EXCEPTIONS')}
                    description={t('COWORKER_CONTRIBUTIONS_DESCRIPTION')}
                    id="CUSTOM_CONTRIBUTIONS"
                    data-testid="CapacityFactorPanel-custom-contributions"
                    apiType={APIPath.CUSTOM_CONTRIBUTIONS}
                    handleDownloadFile={onClickDownloadFile}
                />
                <FileUploadPanel
                    heading={t('HEADCOUNT_DEMAND')}
                    description={t('WORKLOAD_DESCRIPTION')}
                    id="WORKLOAD_DRIVERS"
                    data-testid="CapacityFactorPanel-workload-drivers"
                    apiType={APIPath.WORKLOAD}
                    handleDownloadFile={onClickDownloadFile}
                />
            </CapacityFactorGroup>
            {access?.api?.uploadRecruitmentNeeds && (
                <CapacityFactorGroup headerText={t('RECRUITMENT_NEEDS')}>
                    <FileUploadPanel
                        heading={t('COWORKER_TURN_OVER')}
                        description={t('COWORKER_TURN_OVER_REVIEW')}
                        id="TARGET_HEADCOUNT"
                        data-testid="CapacityFactorPanel-coworker-turnover"
                        apiType={APIPath.COWORKER_TURN_OVER}
                        handleDownloadFile={onClickDownloadFile}
                    />
                </CapacityFactorGroup>
            )}
        </div>
    );
};

export default CapacityFactorPanel;
