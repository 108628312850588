import { zeroHoursContractRange } from 'utils/constants';
import { OpenEndedRange, ConfigurationData } from 'types/api';
import { EditableContractHoursCellProps, CoworkerGridData, DummyGridData, ContractMixGraphData } from 'types/appContext';
import { Coworker, ContractRange } from 'types/coworker';
import { DummyCoworker } from 'types/scenario';
import { costCentreIsInList, costCentresAreSame } from 'utils/text';
import { configRangeIsContract, rowIsContractHours } from './gridFunctionsShared';
import { sortContractMixData } from './gridFunctionsSorting';

/**
 * Get the default range for a working coworker. If the coworker has a contract range, it will be used.
 * Else, hours per week will be used to find the range from the available ranges.
 */
const getRange = (
    partialCoworker: Pick<Coworker | DummyCoworker, 'contractRange' | 'hoursPerWeek'>,
    availableRanges: (ContractRange | OpenEndedRange)[]
) => (partialCoworker.contractRange ? partialCoworker.contractRange : availableRanges.find(range => {
    if (('type' in range)) {
        return false;
    }

    if (
        (range.max == null || range.max > partialCoworker.hoursPerWeek) // Non-inclusive max
        && (range.min == null || range.min <= partialCoworker.hoursPerWeek) // Inclusive min
    ) {
        return true;
    }

    return false;
}));

const cellsHaveScenarios = (cells: Array<EditableContractHoursCellProps>) => cells
    .some(cell => (cell.scenarioDeltas?.latestContractDelta || cell.scenarioDeltas?.latestWeeklyDelta));

type CostCentreRange = {
    costCentre: string,
    rangeData: ContractRange | OpenEndedRange
};
type CostCentreRanges = Array<CostCentreRange | null>;

/**
 * Gets the cost centre for a week which is to be considered the 'home' cost centre for that coworker,
 * as well as the range that the coworker falls into.
 *
 * Note: Weekly deltas should not affect contract mix. So only use the contractDeltas and coworkerDeltas.
 *
 * Priorities goes as follows. If CW works any hours in the PA cost centre, use that.
 * Else, use the cost centre from the cost distribution with the highest percentage.
 * If equal distributions (e.g. 50-50 between two new cost centres, use the first in the array).
 */
const scenarioCostCentreRange = (
    weeklyCells: EditableContractHoursCellProps[],
    coworker: Coworker | DummyCoworker,
    availableRange: (ContractRange | OpenEndedRange)[]
): CostCentreRange | null => {
    const paCostCentre = coworker.costCentre;
    if (weeklyCells.length === 0) return null;
    // Note: same deltas for each row, so it doesn't matter which row we take it from.
    const deltas = weeklyCells.find(cell => cell.scenarioDeltas)?.scenarioDeltas;
    if (!deltas) return null;

    if (deltas.latestContractDelta) {
        // Contract deltas should always take priority over other changes.
        // Being appended to this weeks cells means that the delta applies to this week, no need to verify dates.

        // Return null if the contract delta hours is 0
        if (!deltas.latestContractDelta.hoursPerWeekRange
            && Number(deltas.latestContractDelta.hoursPerWeek) === 0) return null; // TODO: remove typecast after backend alignment

        const hoursAsPartialCoworker = {
            hoursPerWeek: deltas.latestContractDelta.hoursPerWeek,
            contractRange: deltas.latestContractDelta.hoursPerWeekRange
        } as (
            Pick<Coworker, 'contractRange' | 'hoursPerWeek'>
        );

        const range = getRange(hoursAsPartialCoworker, availableRange);
        if (!range) return null;

        // Priority 1: If the coworker has any hours in the PA cost centre, use that.
        const homeCostCentreIsInDelta = deltas.latestContractDelta.costDistributions
            .some(cd => costCentresAreSame(cd.costCentre, paCostCentre) && cd.costCentrePercent > 0);

        if (homeCostCentreIsInDelta) {
            return { costCentre: paCostCentre, rangeData: range };
        }
        // Priority 2: Use the cost centre from the cost distribution with the highest percentage or
        // 3: If equal distributions (e.g. 50-50 between two new cost centres, use the first in the array).
        const { costCentre } = deltas.latestContractDelta.costDistributions.sort((a, b) => b.costCentrePercent - a.costCentrePercent)[0];

        return { costCentre, rangeData: range };
    }

    if (deltas.latestCoworkerDelta) {
        // Coworker deltas are meant to overwrite pa data - take homeCC from delta ?? PA.
        // Then - since delta is appended to this weeks cells, means it's inside the contract period.

        const costCentre = deltas.latestCoworkerDelta.costCentre ?? paCostCentre;
        const updatedCoworker = {
            ...coworker,
            ...deltas.latestCoworkerDelta
        };
        const range = getRange(updatedCoworker, availableRange);
        if (!range) return null;

        return { costCentre, rangeData: range };
    }

    if (deltas.latestWeeklyDelta) {
        // Only affected by weekly deltas - ignore any scenario data.

        // Coworker can only have weekly deltas if the week is inside the contract period.
        const range = getRange(coworker, availableRange);
        if (!range) return null;

        return {
            costCentre: paCostCentre,
            rangeData: range,
        };
    }

    return null;
};

/**
 * Given the CoworkerGridData and the available ranges, this function will return which cost centre the coworker 'belongs to' and which range it is
 * in for each week.
 */
const getRangeAndCostCentre = (
    coworker: CoworkerGridData | DummyGridData,
    timeArray: string[],
    availableRanges: (ContractRange | OpenEndedRange)[]
): Array<{ costCentre: string, rangeData: ContractRange | OpenEndedRange } | null > => {
    const rangeAndCostCentre: CostCentreRanges = [];
    const contractHoursRows = coworker.gridRows.filter(rowIsContractHours);
    const defaultWeeklyRange = getRange(coworker.coworker, availableRanges);
    timeArray.forEach((_date, index) => {
        // Note: for each week, either null or a CostCentreRange must be pushed exactly once to rangeAndCostCentre.
        // Else the array will be out of sync with timeArray.

        const columns = contractHoursRows.map(row => row.editableCells[index]);
        if (!cellsHaveScenarios(columns)) {
            // Without scenarios, the range/hours can be default, or 0 outside of contract, or some inbetween if it is the week the contract
            // ends. In this case, treat as default.

            // If there are no scenarios, the costCentre should be the home costCentre
            const { costCentre } = coworker.coworker;
            if (!defaultWeeklyRange) {
                // null means no contract during this week.
                rangeAndCostCentre.push(null);

                return;
            }
            if (columns.some(cell => (typeof cell.currentValue === 'number' && cell.currentValue > 0))) {
                // Non-zero currentValue is used as a proxy for (partially) active contract
                rangeAndCostCentre.push({ costCentre, rangeData: defaultWeeklyRange });

                return;
            }

            rangeAndCostCentre.push(null);

            return;
        }

        // This means the coworker has been affected by a scenario change during this week.
        const weeklyCostCentreRange = scenarioCostCentreRange(columns, coworker.coworker, availableRanges);
        rangeAndCostCentre.push(weeklyCostCentreRange);
    });

    return rangeAndCostCentre;
};

const isRangeDataEqual = (rangeData: ContractRange | OpenEndedRange, otherRangeData: ContractRange | OpenEndedRange) => {
    if ('type' in rangeData && 'type' in otherRangeData && rangeData.type === otherRangeData.type) return true;

    if (('min' in rangeData && 'min' in otherRangeData && rangeData.min === otherRangeData.min)
    && ('max' in rangeData && 'max' in otherRangeData && rangeData.max === otherRangeData.max)) return true;

    return false;
};

export const generateContractMixData = ({
    coworkers,
    timeArray,
    config,
    costCentreList
}: {
    coworkers: (CoworkerGridData | DummyGridData)[],
    timeArray: string[],
    config: ConfigurationData | undefined,
    costCentreList: string[]
}): ContractMixGraphData | undefined => {
    if (!config || !config.contractRanges || !config.contractRanges.length || !coworkers.length) { return undefined; }
    // Initialize response with correct shape and 0 values
    const contractMixData: ContractMixGraphData = [ // Get the contract ranges from the config and initialize the data
        ...config.contractRanges.map(range => ({
            rangeData: range,
            numberOfCoworkers: timeArray.map(() => 0),
            fractionOfCoworkers: timeArray.map(() => 0)
        })),
        { // Add a zero hours contract range
            rangeData: config.isContractRangeSupported ? zeroHoursContractRange : zeroHoursContractRange.range,
            numberOfCoworkers: timeArray.map(() => 0),
            fractionOfCoworkers: timeArray.map(() => 0)
        }];

    const availableRanges = contractMixData.map(({ rangeData }) => rangeData);

    // Iterate over all coworkers and add them to the correct place
    coworkers.forEach(coworker => {
        const coworkerRangeAndCostCentre = getRangeAndCostCentre(coworker, timeArray, availableRanges);
        coworkerRangeAndCostCentre.forEach((rangeCostCentre, index) => {
            if (!rangeCostCentre) { return; }
            const { costCentre, rangeData } = rangeCostCentre;
            if (costCentreIsInList(costCentre, costCentreList)) { // Note: coworker can be in grid but not have their 'home cost centre' in the list
                const cmdIndex = contractMixData.findIndex(cmd => isRangeDataEqual(cmd.rangeData, rangeData));
                if (cmdIndex !== -1) {
                    contractMixData[cmdIndex].numberOfCoworkers[index] += 1;
                }
            }
        });
    });

    // Filter away empty ranges and 0 hours contract range
    const cmdNonZero: ContractMixGraphData = contractMixData
        .filter(cmd => cmd.numberOfCoworkers.some(val => val > 0))
        .filter(cmd => {
            if (configRangeIsContract(cmd.rangeData)) {
                return (cmd.rangeData.type !== zeroHoursContractRange.type);
            }

            return true;
        });

    // Convert to fractions
    // 1. Getting the total for each week
    const totalCoworkersPerWeek = cmdNonZero.map(cmd => cmd.numberOfCoworkers).reduce((acc, val) => {
        if (acc.length === 0) {
            return val;
        }

        return acc.map((accVal, i) => accVal + val[i]);
    }, []);
    // 2. Dividing each value by the total
    const cmdIncludingFractions: ContractMixGraphData = totalCoworkersPerWeek.some(val => val === 0) ? cmdNonZero
        : cmdNonZero.map(cmd => ({
            ...cmd,
            fractionOfCoworkers: cmd.numberOfCoworkers.map((val, i) => val / totalCoworkersPerWeek[i])
        }));

    // Sort the array to perserve order (and thus colors in the graph).
    const sortedContractMixData = cmdIncludingFractions.sort(sortContractMixData);

    return sortedContractMixData;
};
