import moment, { Moment } from 'moment';
import { TimeSelection } from 'types/appContext';

export const DATE_FORMAT_STANDARD = 'YYYY-MM-DD';
export const DATE_FORMAT_FULLMONTH_FULLYEAR = 'MMMM YYYY';
export const DATE_FORMAT_DATE_MONTH_YEAR = 'MMMM d, YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD-MMM-YYYY HH:mm';
export const DATE_SHORT_MONTH_AND_YEAR = 'MMM YY';
export const DATE_FORMAT_DAY_FULLMONTH_FULLYEAR = 'DD MMMM YYYY';
export const DATE_FORMAT_SHORT_MONTH_FULL_YEAR = 'MMM YYYY';
export const DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR = 'D MMM YYYY';
export const DATE_FORMAT_FULL_YEAR_ISO_WEEK = 'YYYY-[W]WW';

const TOTAL_WEEKS_IN_A_YEAR = 52;
const DAYS_IN_A_WEEK = 7;
const BEGINNING_OF_TIME = '1970-01-01';
const INDEFINITE_END_DATE = '9999-12-31';
const PRESENTATION_DATE_FORMAT = new Intl.DateTimeFormat(navigator.language);
const SERVER_DATEFORMAT = new Intl.DateTimeFormat('sv-SE');
const I18N_DATEFORMAT = new Intl.DateTimeFormat(navigator.language);

/**
 * Generate a Map containing the values of starting dates of weeks for the current, last and previous years,
 * in order to avoid the slow processing of Moment.js
 */
const dateMap = new Map<string, { startOfWeek: Moment, endOfWeek: Moment, isoWeek: number }>();
const years = new Array<number>();
years.push(new Date().getFullYear() - 1);
years.push(new Date().getFullYear());
years.push(new Date().getFullYear() + 1);

years.forEach(year => {
    let key;
    let value;
    for (let i = 1; i <= 53; i += 1) {
        key = `${year}-W${i.toString().padStart(2, '0')}`;
        value = {
            startOfWeek: moment(key).startOf('isoWeek').toISOString(),
            endOfWeek: moment(key).endOf('isoWeek').toISOString(),
            isoWeek: moment(key).isoWeek(),
            // @ts-ignore
            startOfWeekDate: moment(key).startOf('isoWeek').format(DATE_FORMAT_STANDARD),
        };
        Object.freeze(value);
        dateMap.set(key, value);
    }
});
Object.freeze(dateMap);

const DateHelper = {
    /**
     * Get the start of the week
     * @param key YYYY-W01 Moment week format
     * @returns Moment object
     */
    getISOWeekStartDate: (key: string): Moment => {
        const value = dateMap.get(key);
        if (!value) {
            return moment(key).startOf('isoWeek');
        }

        return moment(dateMap.get(key)?.startOfWeek, moment.ISO_8601);
    },
    /**
     * Get the end of the week
     * @param key YYYY-W01 Moment week format
     * @returns Moment object
     */
    getISOWeekEndDate: (key: string): Moment => {
        const value = dateMap.get(key);
        if (!value) {
            return moment(key).endOf('isoWeek');
        }

        return moment(dateMap.get(key)?.endOfWeek, moment.ISO_8601);
    },
    /**
     * Get ISO week number
     * @param key YYYY-W01 Moment week format
     * @returns number
     */
    getISOWeek: (key: string): number => dateMap.get(key)?.isoWeek || moment(key).isoWeek(),
    /**
     * Get ISO year
     * @param key any moment-parseable date string
     * @returns number
     */
    getISOYear: (key: string): number => moment(key).isoWeekYear(),
    /**
     * @param key any moment-parseable date string
     * @returns string in YYYY-[W]WW format
     */
    isoWeekFromDate: (key: string): string => moment(key).format(DATE_FORMAT_FULL_YEAR_ISO_WEEK),
    /**
     * Get overlap between 2 date ranges
     * @param compareFor date to check overlap for
     * @param compareWith date to check overlap with
     */
    getOverlapBetweenRanges: (compareFor:{ startDate :string, endDate: string }, compareWith:{ startDate :string, endDate: string }) => {
        const startDate = moment(compareFor.startDate).valueOf() - moment(compareWith.startDate).valueOf() >= 0 ? compareFor.startDate : compareWith.startDate;
        const endDate = moment(compareFor.endDate).valueOf() - moment(compareWith.endDate).valueOf() <= 0 ? compareFor.endDate : compareWith.endDate;

        if (moment(compareFor.endDate).valueOf() < moment(compareWith.startDate).valueOf()) {
            return null; // no overlap
        }

        return {
            startDate,
            endDate,
        };
    },

    /**
     * Get the number of weeks between two dates. Both dates need to be parseable by moment.
     * The dates are converted to the start of the isoweek before the difference is calculated.
     * @returns number
     * @example getDateDifferenceInWeeks('2022-01-01', '2022-01-08') // returns 1
     * getDateDifferenceInWeeks('2022-01-08', '2022-01-01') // returns -1
     * getDateDifferenceInWeeks('2022-01-01', '2022-01-02') // returns 0
     * getDateDifferenceInWeeks('2022-01-02', '2022-01-03') // returns 1, because they are in different isoweeks
     */
    getDateDifferenceInWeeks: (firstDate: string, secondDate: string): number => moment(firstDate)
        .startOf('isoWeek')
        .diff(moment(secondDate).startOf('isoWeek'), 'weeks'),

    /**
     * Gets the last date of an array of parse'able date strings.
     *
     * @param dates - string[] of dates, parse'able by moment
     * @param weekBasedFormat - optional, if provided, will return use the startOf or endOf method on each date (so the result is always monday or sunday).
     * Else it will use the specificy of the provided dates.
     * @returns string in DATE_FORMAT_STANDARD ("YYYY-MM-DD"). Returns empty string if no dates are provided.
     */
    getLatestDate: (dates: string[], weekBasedFormat?: 'startOf' | 'endOf') => {
        if (dates.length === 0) {
            return '';
        }
        const moments = weekBasedFormat
            ? dates.map(date => moment(date)[weekBasedFormat]('isoWeek'))
            : dates.map(date => moment(date));

        return moment.max(moments).format(DATE_FORMAT_STANDARD);
    },

    /**
     * Gets the earliest date of an array of parse'able date strings.
     *
     * @param dates - string[] of dates, parse'able by moment
     * @param weekBasedFormat - optional, if provided, will return use the startOf or endOf method on each date (so the result is always monday or sunday).
     * Else it will use the specificy of the provided dates.
     * @returns string in DATE_FORMAT_STANDARD ("YYYY-MM-DD"). Returns empty string if no dates are provided.
     */
    getEarliestDate: (dates: string[], weekBasedFormat?: 'startOf' | 'endOf') => {
        if (dates.length === 0) {
            return '';
        }
        const moments = weekBasedFormat
            ? dates.map(date => moment(date)[weekBasedFormat]('isoWeek'))
            : dates.map(date => moment(date));

        return moment.min(moments).format(DATE_FORMAT_STANDARD);
    },

    /**
     * Subtract weeks from date, return the date of the end of the week.
     * Used for grid formatting
     * @param date
     * @param numberOfWeeks
     * @returns string
     */
    subtractWeeksFromDate: (date: string, numberOfWeeks: number): string => moment(date ?? INDEFINITE_END_DATE)
        .subtract(numberOfWeeks, 'weeks')
        .endOf('isoWeek')
        .format(DATE_FORMAT_STANDARD),

    /**
     * Get week day as a number (1 = Monday, 7 = Sunday). If YYYY-[W]WW format is provided, it will return 1 for monday.
     * @param date a moment-parseable date string
     */
    getWeekDay: (date: string) => moment(date).isoWeekday(),
};

// Source: https://weeknumber.com/how-to/javascript#:~:text=To%20get%20the%20ISO%20week,getWeekYear()%20.
const getWeek = (dateStr: string): number => {
    const date = new Date(dateStr);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);

    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
};

const formatDate = (date: string | null) => (date ? PRESENTATION_DATE_FORMAT.format(Date.parse(date)) : null);

const toIntlDateTimeShort = (date: moment.Moment) => (
    Intl.DateTimeFormat([navigator.language, 'en-GB'], { dateStyle: 'short', timeStyle: 'short' }).format(date.toDate())
);

const toIntlShortDateMedMonthFullYear = (date: Date) => (
    Intl.DateTimeFormat([navigator.language, 'en-GB'], { day: 'numeric', month: 'short', year: 'numeric' }).format(date));

const toIntlMonthLong = (date: moment.Moment) => (
    Intl.DateTimeFormat([navigator.language, 'en-GB'], { month: 'long' }).format(date.toDate())
);

const toIntlMonthShort = (date: moment.Moment) => (
    Intl.DateTimeFormat([navigator.language, 'en-GB'], { month: 'short', year: '2-digit' }).format(date.toDate())
);

const toIntlLongMonthNumericDayAndYear = (date: Date | string) => (
    Intl.DateTimeFormat([navigator.language, 'en-GB'], { month: 'short', day: 'numeric', year: 'numeric' }).format(date instanceof Date ? date : new Date(date))
);

const toIntlLongMonthFullYear = (date: Date | string) => (
    Intl.DateTimeFormat([navigator.language, 'en-GB'], { month: 'long', year: 'numeric' }).format(date instanceof Date ? date : new Date(date))
);

const toIntlMedMonthFullYear = (date: Date | string) => (
    Intl.DateTimeFormat([navigator.language, 'en-GB'], { month: 'short', year: 'numeric' }).format(date instanceof Date ? date : new Date(date))
);
const toServerDate = (date: Date) => (SERVER_DATEFORMAT.format(date));

const timeToHeader = (time: string) => {
    if (time.includes('W')) {
        const [, weekNumber] = time.split('W');

        return weekNumber;
    }

    return toIntlMonthShort(moment(time));
};

/**
 * Adds a leading zero to week numbers below 10
 */
const formatWeekNumber = (weekNumber: number) => {
    if (weekNumber < 10) {
        return `0${weekNumber}`;
    }

    return weekNumber;
};

/**
 * Returns a string in the format 'YYYY-[W]WW' from a moment object
 */
const getIsoYearWeek = (momentObj: moment.Moment): string => `${momentObj.isoWeekYear()}-W${formatWeekNumber(momentObj.isoWeek())}`;
/**
 * Returns a string in the format 'YYYY-[W]WW' from a string, using moment to parse the string
 */
const isoWeekFromDateString = (dateString: string) => `${moment(dateString).isoWeekYear()}-W${formatWeekNumber(moment(dateString).isoWeek())}`;

/**
 * Checks if two moment objects are in the same week and same year
 */
const isSameWeek = (m1: moment.Moment, m2: moment.Moment) => m1.isoWeek() === m2.isoWeek() && m1.isoWeekYear() === m2.isoWeekYear();
/**
 * Checks if two moment objects are in the same month and same year
 */
const isSameMonth = (m1: moment.Moment, m2: moment.Moment) => m1.month() === m2.month() && m1.isoWeekYear() === m2.isoWeekYear();

const generateTimeArray = (startDate: string, endDate: string, selection: 'week' | 'month') => {
    const newTimeArr: string[] = [];

    let start = moment(startDate);
    const end = moment(endDate);
    while (
        start.isBefore(end)
        || (selection === 'month' && isSameMonth(start, end))
        || (selection === 'week' && isSameWeek(start, end))
    ) {
        if (selection === 'week') {
            newTimeArr.push(getIsoYearWeek(start));
        } else {
            newTimeArr.push(start.format('YYYY-MM'));
        }
        start = start.add(1, selection); // shifts start one week or one month forwards
    }

    return newTimeArr;
};

// TODO: Write tests
const initializeTimeSelection = (selection: 'month' | 'week') => {
    if (selection === 'month') {
        const start = moment().startOf('month').format(DATE_FORMAT_STANDARD);
        const end = moment().endOf('month').add(12, 'month').format(DATE_FORMAT_STANDARD);

        return ({
            selection,
            startDate: start,
            endDate: end,
            valid: true,
            timeArray: generateTimeArray(start, end, selection)
        } as TimeSelection);
    }

    const start = moment().startOf('isoWeek').format(DATE_FORMAT_STANDARD);
    const end = moment().endOf('isoWeek').add(52, 'week').format(DATE_FORMAT_STANDARD);

    return ({
        selection,
        startDate: start,
        endDate: end,
        valid: true,
        timeArray: generateTimeArray(start, end, selection)
    } as TimeSelection);
};

const weekYearFromDateString = (str: string) => {
    const week = getWeek(str);
    let year = new Date(str).getFullYear();
    const dateFromParam = new Date(str);
    const firstWeekDate = new Date(`${year}-01-04`);

    // handle special cases where the date is before the first known date that is in week 1
    if (dateFromParam.getTime() < firstWeekDate.getTime()) {
        if (week > 1) { year -= 1; }
    }

    return `${week.toString().padStart(2, '0')} ${year}`;
};

const getActionWeekDate = (startDate: string, endDate: string | null, weekTitle: string) => {
    // TODO: Remove hardcoded string after BE changes Action Plan API indefinite date format to '9999-12-31'
    if (!endDate || endDate === INDEFINITE_END_DATE || endDate === '9999-12-31T00:00:00.000Z') {
        return `${weekTitle} ${moment(startDate).format(DATE_FORMAT_FULL_YEAR_ISO_WEEK).slice(-2)}`;
    }

    const timeArray = generateTimeArray(
        startDate ?? toServerDate(new Date()),
        endDate,
        'week'
    );

    if ((timeArray.length > TOTAL_WEEKS_IN_A_YEAR) || timeArray.length <= 1) {
        return `${weekTitle} ${timeArray[0]?.slice(-2)}`;
    }

    return `${weekTitle} ${timeArray[0]?.slice(-2)}-${timeArray[timeArray.length - 1]?.slice(-2)}`;
};

const isLatestTimeStamp = (
    weeklyTimeStamp: string,
    contractTimeStamp: string
): boolean => new Date(weeklyTimeStamp).getTime() > new Date(contractTimeStamp).getTime();

/**
 * Takes an ISO week string and returns the start and end dates of the week
 * using moment as an intermediary
 * @param isoWeek 'YYYY-[W]WW'
 * @returns { startDate, endDate } as per DATE_FORMAT_STANDARD
 */
const getWeekStartEndDatesFromISOWeek = (isoWeek: string) => {
    const momentOfWeek = DateHelper.getISOWeekStartDate(isoWeek);

    return {
        startDate: momentOfWeek.format(DATE_FORMAT_STANDARD),
        endDate: momentOfWeek.endOf('isoWeek').format(DATE_FORMAT_STANDARD)
    };
};
/**
 * Checks if the given start or end date falls within the specified time selection period.
 * @param Date - moment format date
 * @param timeSelection - Object containing startDate and endDate of the time selection period
 * @returns {boolean} - True if either startDate or endDate is within the time selection period
 */
const isBetweenTimePeriod = (date: string, { startDate: selectionStartDate, endDate: selectionEndDate }: TimeSelection): boolean => moment(date)
    .isBetween(selectionStartDate, selectionEndDate, 'day', '[]');

export {
    PRESENTATION_DATE_FORMAT,
    I18N_DATEFORMAT,
    SERVER_DATEFORMAT,
    TOTAL_WEEKS_IN_A_YEAR,
    DAYS_IN_A_WEEK,
    BEGINNING_OF_TIME,
    INDEFINITE_END_DATE,
    DateHelper,
    timeToHeader,
    formatDate,
    weekYearFromDateString,
    toIntlDateTimeShort,
    toIntlLongMonthFullYear,
    toIntlMonthLong,
    toIntlMedMonthFullYear,
    toIntlMonthShort,
    toIntlShortDateMedMonthFullYear,
    getIsoYearWeek,
    getWeekStartEndDatesFromISOWeek,
    toServerDate,
    initializeTimeSelection,
    isoWeekFromDateString,
    generateTimeArray,
    toIntlLongMonthNumericDayAndYear,
    getActionWeekDate,
    isLatestTimeStamp,
    isBetweenTimePeriod,
};
