import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@ingka/tooltip';
import useData from 'hooks/useData';
import Button from '@ingka/button';
import { COPY_SCENARIO_BUTTON__CLICK } from 'types/analytics';
import copy from '@ingka/ssr-icon/paths/copy';
import Modal, { Sheets, ModalBody, ModalFooter, ModalHeader } from '@ingka/modal';
import InputField from '@ingka/input-field';
import { ScenarioList } from 'types/scenario';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TFunction } from 'i18next';
import { useListener } from 'hooks/useListener';
import { useCopyScenarioAPI } from 'hooks/api/useCopyScenarioAPI';
import { useToast } from 'hooks/useToast';
import FormField from '@ingka/form-field';
import Text from '@ingka/text';
import classes from './CopyScenario.module.scss';

const getValidationSchema = (t: TFunction<'translation', undefined>, scenarioList: ScenarioList | undefined) => z.object({
    copyScenarioName: z.string()
        .min(1, { message: t('SCENARIO_NAME_MISSING') })
        .refine(
            value => !scenarioList?.scenarios?.map(scenario => scenario.name.trim().toLowerCase()).includes(value.trim().toLowerCase()),
            { message: t('SCENARIO_NAME_EXISTS_SELECT_NEW') }
        )
});

const CopyScenario = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { currentUnit, currentScenario, scenarioList } = useData();
    const isDisabled = !(currentScenario);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validationSchema = useMemo(() => getValidationSchema(t, scenarioList), [scenarioList]);
    const { writeData: copyScenario } = useCopyScenarioAPI();
    const { displayToast } = useToast();

    const {
        handleSubmit,
        formState: { errors, isSubmitting, isValidating, isValid, isDirty, touchedFields },
        control,
        reset,
        getValues,
        trigger,
    } = useForm<z.infer<typeof validationSchema>>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            copyScenarioName: ''
        },
        resolver: zodResolver(validationSchema),
    });

    const onSubmit = async (formState: z.infer<typeof validationSchema>) => {
        setIsLoading(true);
        if (errors.copyScenarioName) {
            trigger();
            setIsLoading(false);

            return;
        }

        copyScenario({ name: formState.copyScenarioName, sourceScenarioId: Number(currentScenario?.id) }).then(response => {
            if (!response.isResponseOk) {
                displayToast({ title: t('ERROR'), message: t('COPY_SCENARIO_SAVE_FAILED') });

                return;
            }

            displayToast({ title: t('SUCCESS'), message: t('COPY_SCENARIO_SAVE_SUCCEEDED') });
            setIsModalOpen(false);

            reset();
        }).finally(() => { setIsLoading(false); });
    };

    // Handle enter key event
    useListener('keydown', 'Enter', () => {
        if (isValid && isDirty) {
            const formState = getValues();
            handleSubmit(() => onSubmit(formState));
        }
    });

    return (
        <span data-testid="copy-scenario-button-span">
            <Tooltip
                interactiveCustomTrigger={!isDisabled}
                tooltipText={isDisabled ? t('COWORKERS_SELECT_SCENARIO') : t('COPY_SCENARIO')}
            >
                <Button
                    data-testid="copy-scenario-button"
                    type="primary"
                    ssrIcon={copy}
                    onClick={() => setIsModalOpen(true)}
                    size="small"
                    iconOnly
                    disabled={isDisabled}
                    data-analytics={COPY_SCENARIO_BUTTON__CLICK}
                    // Using text with iconOnly for accessibility
                    text={t('COPY_SCENARIO')}
                />
            </Tooltip>

            <Modal
                visible={isModalOpen}
                handleCloseBtn={() => { setIsModalOpen(false); reset(); }}
                escapable
                onModalClosed={() => reset()}
                focusLockProps={{ locked: false }}
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={(
                        <ModalHeader
                            title={t('COPY_SCENARIO')}
                            data-testid="copy-scenario-header"
                            backBtnClick={() => { setIsModalOpen(false); reset(); }}
                            className={classes['copy-scenario__header']}
                        />
                    )}
                    footer={(
                        <ModalFooter>
                            <Button
                                onClick={() => { setIsModalOpen(false); reset(); }}
                                text={t('CANCEL')}
                                type="secondary"
                                size="small"
                                data-testid="copy-scenario-cancel-button"
                            />
                            <Button
                                disabled={isLoading}
                                onClick={() => { onSubmit(getValues()); }}
                                text={t('SAVE')}
                                type="primary"
                                size="small"
                                loading={isValidating || isSubmitting || isLoading}
                                data-testid="copy-scenario-save-button"
                            />

                        </ModalFooter>
                    )}
                >
                    <form onSubmit={handleSubmit(onSubmit)} data-testid="copy-scenario-form" className={classes['copy-scenario__form']}>
                        <ModalBody>
                            <div className={classes['copy-scenario__title']}>
                                <Text data-testid="copy-scenario-header-info">
                                    {t('SCENARIO_CREATED_FOR', { unitCode: currentUnit })}
                                </Text>
                            </div>
                            <Controller
                                control={control}
                                name="copyScenarioName"
                                render={({
                                    field: { onChange, onBlur }
                                }) => (
                                    <FormField
                                        shouldValidate={touchedFields.copyScenarioName}
                                        valid={!errors.copyScenarioName}
                                        validation={{
                                            msg: errors.copyScenarioName?.message,
                                        }}
                                    >
                                        <InputField
                                            autoComplete="off"
                                            label={`${t('COPY_SCENARIO')} ${t('NAME')}`}
                                            id="copyScenarioName"
                                            type="text"
                                            className={classes['copy-scenario__input-scenario-name']}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            data-testid="copy-scenario-form-input"
                                            autoFocus
                                        />
                                    </FormField>
                                )}
                            />
                        </ModalBody>
                    </form>
                </Sheets>
            </Modal>
        </span>
    );
};

export default CopyScenario;
