import { OrgData } from 'types/dataContext';
import { sortBy } from 'utils/sorting';

export const sortOrgData = (orgData: OrgData): OrgData => ({
    ...orgData,
    businessUnits: orgData.businessUnits.sort(sortBy('name', 'ASC')),
    divisions: orgData.divisions.sort(sortBy('name', 'ASC')),
    departments: orgData.departments.sort(sortBy('name', 'ASC')),
    costCentres: orgData.costCentres.sort(sortBy('desc', 'ASC')),
});
