export type RecriutmentNeedsData = {
    recruitmentNeeds: Array<RecruitmentNeedsDelta>;
};

export type RecruitmentNeedsDelta = {
    id: string;
    data: Array<TurnOverDelta & ContractsDelta>;
    newHires: Array<NewHiresDelta>;
};

export type TurnOverDelta = {
    startDate: string;
    endDate: string;
    turnOverRate: number;
    coworkerHeadCount: number;
};

export type ContractsDelta = {
    contigentContracts: number;
    permanentContracts: number;
    temporaryContracts: number;
};

export type NewHiresDelta = {
    contractHours: number;
    contractType: string;
    costCentre: string;
    employmentType: string;
    endDate: string;
    notes: string;
    startDate: string;
};

export type WeekBasedData = {
    eachWeek: string;
    permanentContracts: number;
    temporaryContracts: number;
    contingentContracts: number;
    turnOverRate: number;
    turnOverHeadCount: number;
    internalMovements: number;
    recruitmentActions: number;
};

export type HiringNeedsData = {
    week: Array<WeekBasedData>;
    totalPermanentContracts: number;
    totalTemporaryContracts: number;
    totalContingentContracts: number;
    totalTurnOverRates: number;
    totalTurnOverHeadCount: number;
    totalInternalMovements: number;
    totalActions: number;
};

export enum HiringNeedsRowTypes {
    PERMANENT_CONTRACTS = 'permanentContracts',
    TEMPORARY_CONTRACTS = 'temporaryContracts',
    CONTIGENT_CONTRACTS = 'contingentContracts',
    ESTIMATED_TURNOVER_RATE = 'turnOverRate',
    ESTIMATED_TURNOVER_HEADCOUNT = 'turnOverHeadCount',
    INTERNAL_MOVEMENTS = 'internalMovements',
    RECRUITMENT_ACTIONS = 'recruitmentActions',
}

export type HiringNeedsRowProps = Array<{
    title: string;
    tooltip: string;
    type: HiringNeedsRowTypes;
    total: number;
}>;
