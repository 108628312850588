import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import Button from '@ingka/button';
import { API } from 'services';
import useData from 'hooks/useData';
import { useToast } from 'hooks/useToast';
import { useListener } from 'hooks/useListener';
import useAuth from 'hooks/useAuth';
import classes from './EnableNewUnit.module.scss';

const getValidationSchema = (t: TFunction<'translation', undefined>) => z.object({
    configNewBusinessUnitCode: z.string().min(3, { message: t('ENTER_BUSINESS_UNIT') }).regex(/^\d*$/, { message: t('VALUE_MUST_BE_A_NUMBER') }),
    configNewBusinessUnitType: z.string().min(3, { message: t('ENTER_BUSINESS_UNIT_TYPE') }).regex(/^[a-zA-Z ]*$/, { message: t('VALUE_MUST_BE_ALPHABETS') }),
    configNewBusinessUnitName: z.string().regex(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]*$/, { message: t('VALUE_MUST_BE_ALPHABETS') }).optional()
});

const EnableNewUnit = ({ handleIsOpen }: { handleIsOpen: (isOpen: boolean) => void }) => {
    const { t } = useTranslation();
    const { access } = useAuth();
    const { displayToast } = useToast();
    const { currentCountry } = useData();
    const validationSchema = useMemo(() => getValidationSchema(t), [t]);

    const { handleSubmit, formState: { errors, isValid, isDirty }, control, getValues, trigger } = useForm<z.infer<typeof validationSchema>>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        defaultValues: {
            configNewBusinessUnitCode: '',
            configNewBusinessUnitType: '',
            configNewBusinessUnitName: ''
        },
        resolver: zodResolver(validationSchema),
    });

    const onSave = async (formState: z.infer<typeof validationSchema>) => {
        if (errors.configNewBusinessUnitCode || errors.configNewBusinessUnitType) {
            trigger();

            return;
        }
        const { configNewBusinessUnitCode, configNewBusinessUnitType, configNewBusinessUnitName } = formState;
        if (currentCountry && configNewBusinessUnitCode && configNewBusinessUnitType && access?.api?.onBoardNewUnits) {
            API().enableNewUnit(currentCountry, configNewBusinessUnitCode, configNewBusinessUnitType, configNewBusinessUnitName)
                .then(async response => {
                    if (response.message) {
                        displayToast({ title: t('SUCCESS'), message: t('BUSINESS_UNIT_CONFIGURED') });
                    }
                })
                .catch(err => { displayToast({ title: t('ERROR'), message: `${t('BUSINESS_UNIT_CONFIGURATION_FAILED')} ${err}` }); })
                .finally(() => {
                    // Display Toast is colliding with the handle function at the same time hence added setTimeOut
                    setTimeout(() => {
                        handleIsOpen(false);
                    }, 0);
                });
        }
    };

    useListener('keydown', 'Enter', () => {
        if (isValid && isDirty) {
            const formState = getValues();
            handleSubmit(() => onSave(formState));
        }
    });

    return (
        <form onSubmit={handleSubmit(onSave)} data-testid="config-new-business-unit-form">
            <div id="enable-unit-fields" className={classes['enable-bu__container']}>
                <div id="config-new-bu-code-div">
                    <Controller
                        control={control}
                        name="configNewBusinessUnitCode"
                        render={({ field: { onChange } }) => (
                            <FormField
                                shouldValidate
                                valid={!errors.configNewBusinessUnitCode}
                                validation={{
                                    id: 'config-business-code-error',
                                    msg: errors.configNewBusinessUnitCode?.message,
                                    type: 'error'
                                }}
                            >
                                <InputField
                                    id="config-new-unit-code"
                                    data-testid="config-new-unit-code"
                                    autoComplete="off"
                                    label={`${t('BUSINESS_UNIT')} ${t('CODE').toLocaleLowerCase()}`}
                                    type="number"
                                    maxLength={6}
                                    required
                                    onChange={onChange}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                        const { code, key } = event;
                                        if (!(code.startsWith('Digit') || code.startsWith('Numpad') || code.startsWith('Arrow')
                                           || key === 'Delete' || key === 'Backspace' || key === 'Tab')) {
                                            event.preventDefault();
                                        }
                                    }}
                                    autoFocus
                                />
                            </FormField>
                        )}
                    />
                </div>
                <div id="config-new-bu-type-div">
                    <Controller
                        control={control}
                        name="configNewBusinessUnitType"
                        render={({ field: { onChange } }) => (
                            <FormField
                                shouldValidate
                                valid={!errors.configNewBusinessUnitType}
                                validation={{
                                    id: 'config-business-code-error',
                                    msg: errors.configNewBusinessUnitType?.message,
                                    type: 'error'
                                }}
                            >
                                <InputField
                                    id="config-new-unit-type"
                                    data-testid="config-new-unit-type"
                                    autoComplete="off"
                                    label={`${t('BUSINESS_UNIT')} ${t('TYPE').toLocaleLowerCase()}`}
                                    type="text"
                                    maxLength={3}
                                    required
                                    onChange={onChange}
                                />
                            </FormField>
                        )}
                    />
                </div>
                <div id="config-new-bu-name-div">
                    <Controller
                        control={control}
                        name="configNewBusinessUnitName"
                        render={({ field: { onChange } }) => (
                            <FormField
                                shouldValidate
                                valid={!errors.configNewBusinessUnitName}
                                validation={{
                                    id: 'config-business-code-optional',
                                    msg: errors.configNewBusinessUnitName?.message,
                                    type: 'error'
                                }}
                            >
                                <InputField
                                    id="config-new-unit-name"
                                    data-testid="config-new-unit-name"
                                    label={`${t('BUSINESS_UNIT')} ${t('NAME').toLocaleLowerCase()} (${t('OPTIONAL').toLocaleLowerCase()})`}
                                    autoComplete="off"
                                    type="text"
                                    maxLength={30}
                                    onChange={onChange}
                                />
                            </FormField>
                        )}
                    />
                </div>
                <div id="enable-unit-button-container" className={classes['enable-bu__btns']}>
                    <div>
                        <Button
                            id="enable-unit-cancel-button"
                            data-testid="enable-unit-cancel-button"
                            className={classes['enable-bu__cancel-btn']}
                            size="small"
                            type="secondary"
                            text={t('CANCEL')}
                            onClick={() => handleIsOpen(false)}
                        />
                    </div>
                    <div>
                        <Button
                            id="enable-unit-save-button"
                            data-testid="enable-unit-save-button"
                            className={classes['enable-bu__save-btn']}
                            size="small"
                            type="primary"
                            text={t('SAVE')}
                            disabled={!isValid}
                            onClick={() => onSave(getValues())}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EnableNewUnit;
