import { PageContainer, PageToolbar } from 'components';
import useAuth from 'hooks/useAuth';
import NewHires from 'components/HiringNeeds/NewHires';
import HiringNeeds from 'components/HiringNeeds/HiringNeeds';
import { useRecruitmentNeeds } from 'hooks/api/useRecruitmentNeeds';
import classes from './RecruitmentNeeds.module.scss';

const RecruitmentNeeds = () => {
    const { access } = useAuth();
    const { data: recruitmentNeedsResponse, isLoading: isFetching } = useRecruitmentNeeds();

    return (
        <PageContainer testid="recruitment-needs-page" className={classes['recruitment-needs__container']}>
            <>
                <PageToolbar showAllElements={false} />
                {access?.features?.recruitmentNeedsView && (
                    <>
                        <HiringNeeds isLoading={isFetching} recruitmentNeeds={recruitmentNeedsResponse?.recruitmentNeeds ?? null} />
                        <NewHires isLoading={isFetching} recruitmentNeeds={recruitmentNeedsResponse?.recruitmentNeeds ?? null} />
                    </>
                )}
            </>
        </PageContainer>
    );
};
export default RecruitmentNeeds;
