import ReactDOM from 'react-dom';
import Button from '@ingka/button';
import moment from 'moment';
import ModalService from 'services/modal';
import { useTranslation } from 'react-i18next';
import { useUpdateScenarioAPI } from 'hooks/api/useUpdateScenarioAPI';
import { DATE_FORMAT_STANDARD } from 'utils/date';
import { useToast } from 'hooks/useToast';
import useData from 'hooks/useData';
import useApp from 'hooks/useApp';
import { ChangeType } from 'types/scenario';
import { ScenarioUpdateSource, TypeScenarioUpdateRequestAPI } from 'types/api';
import { ContractRange, EmploymentStatus } from 'types/coworker';
import { useContractDates, useContractHours, useContractType, useCostCentre, useCoworkerName, useEmploymentType, useUnitName } from './AddCoworkerHooks';
import AddCoworkerPanel from './AddCoworkerPanel';
import classes from './AddCoworker.module.scss';

const AddCoworkerModal = ({ modalOpen, setModalOpen }: { modalOpen: boolean, setModalOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { t } = useTranslation();
    const { config, selectScenario, currentScenario } = useData();
    const { unitName } = useUnitName();
    const costCentreProps = useCostCentre();
    const { costCentre, resetCostCentre, validCostCentre } = costCentreProps;
    const contractHoursProps = useContractHours();
    const { contractHours, contractRange, resetContractHours, validContractHours } = contractHoursProps;
    const contractTypeProps = useContractType();
    const contractDatesProps = useContractDates(contractTypeProps.contractType);
    const { startDate, endDate, resetDates, validDates } = contractDatesProps;
    const coworkerNameProps = useCoworkerName();
    const { coworkerName, resetCoworkerName } = coworkerNameProps;
    const employmentTypeProps = useEmploymentType();
    const { employmentType } = employmentTypeProps;
    const { updateScenario } = useUpdateScenarioAPI();
    const { displayToast } = useToast();
    const { orgSelection: { selections: { departmentSelection } } } = useApp();

    const handleClickSaveChanges = () => {
        if (!validDates) {
            displayToast({ title: t('FAILED'), message: t('ENTER_VALID_PERIOD') });

            return;
        }

        const coworkerContractHours = config?.isContractRangeSupported
            ? (contractRange as ContractRange | undefined)
            : (Number(contractHours) as number | undefined);

        if (!validContractHours || !coworkerContractHours) {
            displayToast({ title: t('FAILED'), message: t('ENTER_CONTRACT_HOURS') });

            return;
        }
        if (!validCostCentre || costCentre === undefined) {
            displayToast({ title: t('FAILED'), message: t('SELECT_COST_CENTRE') });

            return;
        }

        setModalOpen(false);

        const contractStartDate = moment(startDate).startOf('isoWeek').format(DATE_FORMAT_STANDARD);
        const contractEndDate = endDate === null ? null : moment(endDate).endOf('isoWeek').format(DATE_FORMAT_STANDARD);
        const scenarioUpdate = {
            source: ScenarioUpdateSource.NEW_COWORKER_MODAL,
            personId: window.crypto.randomUUID(),
            coworkerName,
            startDate: contractStartDate,
            endDate: contractEndDate,
            division: '',
            costCentre,
            department: departmentSelection?.length === 1 ? departmentSelection[0].value : null,
            contractType: contractTypeProps.contractType,
            dummyCoworker: {
                dummyName: coworkerName,
                employmentStatus: EmploymentStatus.ACTIVE,
                employmentType,
                buName: unitName
            },
            contractHours: coworkerContractHours,
            delta: [
                {
                    type: ChangeType.NEW_COWORKER,
                    from: null,
                    to: null
                },
                {
                    type: ChangeType.CONTRACT_HOURS,
                    from: 0,
                    to: coworkerContractHours,
                },
                {
                    type: ChangeType.CONTRACT_TYPE,
                    from: null,
                    to: contractTypeProps.contractType,
                    hoursPerWeek: Number(contractHours)
                },
            ],
        } satisfies TypeScenarioUpdateRequestAPI;
        updateScenario(scenarioUpdate).then(response => {
            if (!response.isResponseOk) {
                displayToast({ title: t('ERROR'), message: t('SAVE_FAILED') });

                return;
            }

            displayToast({ title: t('SUCCESS'), message: t('COWORKER_SUCCESSFULLY_CREATED') });
            selectScenario(currentScenario?.id ?? '');
        });
        resetContractHours();
        resetCostCentre();
        resetDates();
        resetCoworkerName();
        contractTypeProps.resetContractType();
    };

    const modalPortal = document.getElementById('portal');
    const addCoworkerModal = ModalService().open(
        'sheets',
        modalOpen,
        setModalOpen,
        (
            <AddCoworkerPanel
                costCentreProps={costCentreProps}
                contractHoursProps={contractHoursProps}
                contractDatesProps={contractDatesProps}
                contractTypeProps={contractTypeProps}
                coworkerNameProps={coworkerNameProps}
                employmentTypeProps={employmentTypeProps}
                unitName={unitName}
            />
        ),
        <>
            <Button
                data-testid="new-coworker-cancel-button"
                size="small"
                text={t('CANCEL')}
                type="secondary"
                className={classes['button-item']}
                onClick={() => { setModalOpen(false); }}
            />
            <Button
                data-testid="new-coworker-save-button"
                size="small"
                text={t('SAVE_CHANGES')}
                type="primary"
                className={classes['button-item']}
                onClick={handleClickSaveChanges}
            />
        </>,
        '',
        'left',
        'small'
    );

    return (
        <div>
            {modalPortal && ReactDOM.createPortal(addCoworkerModal, modalPortal)}
        </div>
    );
};

export default AddCoworkerModal;
