import Pill from '@ingka/pill';
import { useTranslation } from 'react-i18next';
import useData from 'hooks/useData';
import { memo, useEffect, useState } from 'react';
import useActionPlan from 'hooks/useActionPlanContext';
import { ContractChangeType } from 'types/actionPlan';
import SearchBar from 'components/SearchBar';
import { useLocation } from 'react-router-dom';
import useApp from 'hooks/useApp';
import printer from '@ingka/ssr-icon/paths/printer';
import Button from '@ingka/button';
import useAuth from 'hooks/useAuth';
import Tooltip from '@ingka/tooltip';
import classes from './ActionPlanFilterBar.module.scss';

export const ActionPlanFilterBar = memo(() => {
    const [activeFilter, setActiveFilter] = useState<ContractChangeType>(ContractChangeType.ALL_CHANGES);
    const { t } = useTranslation();
    const { access } = useAuth();
    const { currentScenario } = useData();
    const { setSearchParam } = useApp();
    const { setActiveFilter: setActiveFilterInContext } = useActionPlan();
    const { pathname } = useLocation();

    useEffect(() => {
        setActiveFilterInContext(activeFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter]);

    return (
        <div className={classes['action-plan-filter-bar']} data-testid="action-plan-filter-bar">
            <div className={classes['action-plan-filter-bar__pill-container']}>
                <Pill
                    label={t('ALL_CHANGES')}
                    size="small"
                    disabled={!currentScenario}
                    onClick={() => setActiveFilter(ContractChangeType.ALL_CHANGES)}
                    selected={activeFilter === ContractChangeType.ALL_CHANGES}
                />
                <Pill
                    label={t('CONTRACT_HOURS')}
                    size="small"
                    disabled={!currentScenario}
                    onClick={() => setActiveFilter(ContractChangeType.CONTRACT_HOURS)}
                    selected={activeFilter === ContractChangeType.CONTRACT_HOURS}
                />
                {access?.features?.modulation && (
                <Pill
                    label={t('MODULATION')}
                    size="small"
                    disabled={!currentScenario}
                    onClick={() => setActiveFilter(ContractChangeType.MODULATION)}
                    selected={activeFilter === ContractChangeType.MODULATION}
                />
                )}
            </div>
            <div className={classes['action-plan-filter-bar__search']}>
                <Tooltip
                    interactiveCustomTrigger
                    tooltipText={t('PRINT')}
                    position="leading"
                >
                    <Button
                        ssrIcon={printer}
                        type="tertiary"
                        size="small"
                        iconOnly
                        onClick={() => window.print()}
                        disabled={!currentScenario}
                        // Using text with iconOnly for accessibility
                        text={t('PRINT')}
                    />
                </Tooltip>
                <SearchBar
                    callback={setSearchParam}
                    pathName={pathname}
                    disabled={!currentScenario}
                />
            </div>
        </div>
    );
});
