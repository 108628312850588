import React from 'react';
import Text from '@ingka/text';
import classes from './UserName.module.scss';

const UserName = (
    { userName }:
    { userName:string | undefined }
) => (userName ? (
    <Text tagName="h6" bodySize="l" headingSize="m" data-testid="user-info-name" className={classes['user-name']}>
        {userName}
    </Text>
) : (
    <div
        data-testid="user-info-name-undefined"
        className={classes['user-name']}
    />
));

export default UserName;
