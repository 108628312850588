import Button from '@ingka/button';
import useOpp from 'hooks/useOpp';
import { useState } from 'react';
import circlePathTwo from '@ingka/ssr-icon/paths/circlepath-two-triangles';
import Tooltip from '@ingka/tooltip';
import { useNestedTranslation } from 'hooks/useNestedTranslation';
import classes from './CapacityFactor.module.scss';
import { FileUploadPanelWrapper } from './FileUpload/FileUploadPanelWrapper';

const ExternalBudget = ({ disabled, reFetchBudget }: { disabled: boolean; reFetchBudget: () => void }) => {
    const [isExternalBudgetClicked, setIsExternalBudgetClicked] = useState<boolean>(false);
    const { hoursBudgeting } = useOpp();
    const t = useNestedTranslation('toolbar.capacityFactors');

    return (
        <FileUploadPanelWrapper heading={t('budgetHours')} description={t('externalBudgetDesc')}>
            <div className={classes['external-budget__buttons-container']}>
                {isExternalBudgetClicked && (
                    <Tooltip tooltipText={t('refreshBudgetDesc')} interactiveCustomTrigger position="trailing">
                        <Button
                            onClick={() => {
                                setIsExternalBudgetClicked(false);
                                reFetchBudget();
                            }}
                            size="small"
                            text={t('refreshBudgetDesc')}
                            iconOnly
                            ssrIcon={circlePathTwo}
                            data-testid="external-budget-refresh-button"
                        />
                    </Tooltip>
                )}
                <Button
                    // if href is given, the button does not grey out when disabled
                    href={disabled ? undefined : hoursBudgeting?.href}
                    target="_blank"
                    data-testid="external-budget-link-button"
                    ssrIcon={hoursBudgeting?.icon}
                    text={`${hoursBudgeting?.label} (${t.global('opensInNewTab')})`}
                    fluid
                    size="small"
                    disabled={disabled}
                    onClick={() => setIsExternalBudgetClicked(true)}
                />
            </div>
        </FileUploadPanelWrapper>
    );
};

export default ExternalBudget;
