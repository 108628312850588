import useApp from 'hooks/useApp';
import { useState } from 'react';

const useCostCentre = () => {
    const { orgSelection: { utils: { selectedCostCentres } } } = useApp();

    const costCentreOptions = selectedCostCentres.map(cc => ({
        costCentre: cc.costCentreCode,
        costCentreDescription: cc.costCentreDescription
    }));

    const defaultCostCentre = '';
    const validateCostCentre = (cc: string | undefined) => {
        if (cc === undefined || cc === defaultCostCentre) {
            return false;
        }

        return true;
    };
    const [costCentre, setCostCentre] = useState<string | undefined>(defaultCostCentre);
    const [validCostCentre, setValidCostCentre] = useState(validateCostCentre(defaultCostCentre));

    const handleChangeCostCentre = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue: string | undefined = e.target.value;
        setCostCentre(newValue);
        setValidCostCentre(validateCostCentre(newValue));
    };

    const resetCostCentre = () => {
        setCostCentre(defaultCostCentre);
        setValidCostCentre(validateCostCentre(defaultCostCentre));
    };

    return { costCentre, costCentreOptions, handleChangeCostCentre, validCostCentre, resetCostCentre };
};

export default useCostCentre;
