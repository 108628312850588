import React from 'react';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import Text from '@ingka/text';
import Tooltip from '@ingka/tooltip';
import { HiringNeedsRowTypes } from 'types/recruitmentNeeds';

type HiringNeedsRowProps = {
    rowTitle: string;
    tooltipText: string;
    data: number[];
    type: string;
    totalCell: number;
};
const renderCell = (cellData: number, type: HiringNeedsRowTypes) => {
    if (type === HiringNeedsRowTypes.ESTIMATED_TURNOVER_RATE) {
        return `${cellData.toFixed(2)}%`;
    }
    if (type === HiringNeedsRowTypes.ESTIMATED_TURNOVER_HEADCOUNT) {
        return cellData.toFixed(2);
    }
    if (type === HiringNeedsRowTypes.RECRUITMENT_ACTIONS) {
        return Math.round(cellData);
    }

    return cellData;
};

const HiringNeedsRow: React.FC<HiringNeedsRowProps> = ({
    rowTitle,
    tooltipText,
    data,
    type,
    totalCell,
}) => (
    <tr key={`${type}-row`}>
        <td data-testid={`${type}`}>
            <Text tagName="span" id="hiring-needs-title" data-testid="hiring-needs-title">
                {rowTitle}
            </Text>
        </td>
        <td>
            <Tooltip tooltipText={tooltipText} ssrIcon={informationCircle} position="trailing" />
        </td>
        {data.map((item, index) => (
            <td key={`${type}-cell-${index + 0}`}>
                <Text tagName="span" id="hiring-needs-cell" data-testid={`hiring-needs-${type}-cell`}>
                    {renderCell(item, type as HiringNeedsRowTypes)}
                </Text>
            </td>
        ))}
        <td key={`total-${type}-cell`}>
            <Text tagName="span" headingSize="xs" id="hiring-needs-total" data-testid={`hiring-needs-${type}-total`}>
                {renderCell(totalCell, type as HiringNeedsRowTypes)}
            </Text>
        </td>
    </tr>
);

export default HiringNeedsRow;
