import useData from 'hooks/useData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useUnitName = () => {
    const { t } = useTranslation();
    const defaultUnitName = t('Unit');
    const { orgData, currentUnit } = useData();
    const [unitName, setUnitName] = useState<string>(defaultUnitName);

    useEffect(() => {
        setUnitName(previousName => {
            const newName = orgData?.businessUnits.find(bu => bu.code === currentUnit)?.name;
            if (newName !== undefined && newName !== previousName) {
                return newName;
            }

            return previousName;
        });
    }, [currentUnit, orgData]);

    return { unitName };
};

export default useUnitName;
