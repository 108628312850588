import React from 'react';
import { DebouncedFunc } from 'lodash';
import { ContractRange, Coworker, CoworkerAvailability } from './coworker';
import { DummyCoworker, ContractDelta, Scenario, WeeklyDelta, ScenarioDelta } from './scenario';
import { HoverData } from './table';
import { OMFilter as OMFilterCategory } from './enums';
import { OpenEndedRange } from './api';
import { CustomContributions, DefaultContributions, OrgCostCentre } from './dataContext';

export interface AppContextValues {
    updateOrgSelection: (category: OMFilterCategory, key: string, isSelectAll?: boolean) => void;
    orgSelection: OrgSelection;
    coworkerData: Array<Coworker> | undefined,
    timeSelection: TimeSelection,
    setTimeSelection: React.Dispatch<React.SetStateAction<TimeSelection>>,
    gridData: GridData | undefined,
    currentCoworkerId: string | undefined,
    setCurrentCoworkerId: React.Dispatch<React.SetStateAction<string | undefined>>,
    currentWeek: string | undefined,
    setCurrentWeek: React.Dispatch<React.SetStateAction<string | undefined>>,
    searchParam: string,
    setSearchParam: React.Dispatch<React.SetStateAction<string>>,
    showAllDetails: boolean,
    setShowAllDetails: React.Dispatch<React.SetStateAction<boolean>>,
    showContributionDetails: boolean,
    setShowContributionDetails: React.Dispatch<React.SetStateAction<boolean>>,
    isCalculating: boolean,
    currentScenario: Scenario | undefined,
    setCurrentScenario: React.Dispatch<React.SetStateAction<Scenario | undefined>>,
    handleAnalytics: (DebouncedFunc<() => void> | (() => void)) | undefined,
}

export type EditableContractHoursCellProps = {
    elementId: string,
    originalValue: number,
    currentValue: number,
    key: string,
    coworkerAvailability: CoworkerAvailability,
    scenarioDeltas?: {
        latestWeeklyDelta?: WeeklyDelta,
        latestContractDelta?: ContractDelta,
        latestCoworkerDelta?: Partial<Coworker>
    },
    contributions: {
        evenings: number,
        saturdays: number,
        sundays: number,
    },
};

export type EditableModulationCellProps = {
    elementId: string,
    originalHours: number,
    originalMinutes: number,
    currentHours: number,
    currentMinutes: number,
    key: string,
};

export type CoworkerGridData = {
    coworker: Coworker,
    gridRows: Array<CoworkerGridRow>,
};

export type DummyGridData = {
    coworker: DummyCoworker,
    gridRows: Array<CoworkerGridRow>,
};

export type HeaderContribution = {
    contractHoursContribution: WeeklyHeaderContribution,
    contractRangeContributionMin: WeeklyHeaderContribution,
};

export type WeeklyHeaderContribution = {
    costCentre: string,
    coworkerHours: number,
    sicknessHours: number,
    vacationHours: number,
    otherAbsenceHours: number,
    eveningShifts: number,
    saturdayShifts: number,
    sundayShifts: number,
};

export type CoworkerGridRow = CoworkerGridRowContract | CoworkerGridRowModulation | CoworkerGridRowTotalHours;
export enum RowType {
    TOTAL_HOURS = 'TOTAL_HOURS',
    CONTRACT_HOURS = 'CONTRACT_HOURS',
    MODULATION = 'MODULATION'
}
export type CoworkerGridRowTotalHours = CoworkerGridRowBase & {
    editableCells: Array<EditableContractHoursCellProps>,
    rowType: RowType.TOTAL_HOURS,
};
export type CoworkerGridRowContract = CoworkerGridRowBase & CoworkerGridRowHeaderContributions & {
    editableCells: Array<EditableContractHoursCellProps>,
    rowType: RowType.CONTRACT_HOURS,
};
export type CoworkerGridRowModulation = CoworkerGridRowBase & CoworkerGridRowHeaderContributions & {
    editableCells: Array<EditableModulationCellProps>,
    rowType: RowType.MODULATION,
};

export type CoworkerGridRowHeaderContributions = {
    headerContributions: Array<HeaderContribution>,
};

export type CoworkerGridRowBase = {
    costCentre: string,
    rowAverage: number,
    rowSum: number,
};

export type HoverDataCostCentre = {
    hoverData: Array<HoverData>
    costCentre: string,
};

export type HoverDataDivision = {
    hoverData: Array<HoverData>
    divisionId: string,
};

export type ContractMixGraphData = Array<{
    rangeData: ContractRange | OpenEndedRange,
    numberOfCoworkers: Array<number>,
    fractionOfCoworkers: Array<number>
}>;

export type GridData = {
    hoverData: Array<HoverData>,
    hoverDataPerCostCentre: Array<HoverDataCostCentre>,
    coworkers: Array<(DummyGridData | CoworkerGridData)>,
    contractMixData: ContractMixGraphData | undefined,
};

export type GridDataCoworkers = Pick<GridData, 'coworkers'>;

export interface CoworkerGridDataRef extends GridDataCoworkers {
    rateIdentifier: string,
    defaultContributionsIdentifier: string,
    customContributionsIdentifier: string,
    timeSelection: TimeSelection,
    currentUnit: string,
    currentCountry: string
}

export interface DateObject {
    date: string,
    week: number;
    month: number;
    year: number;
}

export type TimeSelection = {
    selection: 'month' | 'week',
    startDate: string,
    endDate: string,
    valid: boolean,
    errorMessage : string | null,
    timeArray: Array<string>,
};

export type CheckBox = {
    autocomplete: boolean;
    id: string;
    type: OMFilterCategory;
    label: string;
    subLabel?: string,
    defaultChecked: boolean;
    value: string;
    disabled?: boolean;
};

export type OrgSelection = {
    /**
     * The props to be used in the OM filter
     */
    selections: {
        countrySelection: CheckBox[],
        unitSelection: CheckBox[],
        divisionSelection: CheckBox[],
        departmentSelection: CheckBox[],
        costCentreSelection: CheckBox[],
    }
    /**
     * Extra data that depends on the org selection/org data in some way for use throughout the application
     */
    utils: {
        /**
         * An array of the cost centre that are selected by the current combination of selected items in the filters.
         * Contains the cost centre code (long version ending with the 4 digits) the description of the cost centre, and the id which is the uuid.
         */
        selectedCostCentres: Array<{
            costCentreCode: OrgCostCentre['code'],
            costCentreDescription: OrgCostCentre['desc'],
            costCentreId: OrgCostCentre['id'],
        }>,

        /**
         * An array of all the cost centre that regardless of the state of the filters.
         * Contains the cost centre code (long version ending with the 4 digits) the description of the cost centre, and the id which is the uuid.
         */
        allCostCentres: Array<{
            costCentreCode: OrgCostCentre['code'],
            costCentreDescription: OrgCostCentre['desc'],
            costCentreId: OrgCostCentre['id'],
        }>,
    }
};

export type CoworkersOMFiltersCheckBox = {
    divisionNames: Array<string>,
    costCentres: Array<string>,
    departments: Array<{ departmentCode: string, departmentName: string }>
};

export type ComboBoxMultiSelect = {
    id: string,
    type: string,
    label: string,
    value: string,
    status?: boolean,
    isChecked: boolean
};

/**
 * Note: For contributions, null means that the value is not set (does not overwrite any previous values).
 * 0 means that the value is set to 0
 */
export type BaseContractType = {
    startWeeksFromZero: number,
    endWeeksFromZero: number,
    weeklyHours: number,
    timeStamp: string,
    availability: CoworkerAvailability,
    defaultContributions: {
        evenings: number,
        saturdays: number,
        sundays: number,
    } | null,
    customContributions: {
        startWeeksFromZero: number,
        endWeeksFromZero: number,
        evenings: number,
        saturdays: number,
        sundays: number,
    }[] | null,
};
export type CellGenContractData = BaseContractType & { startDay: number, endDay: number };
export type CellGenContractDeltaData = Array<BaseContractType & { delta: undefined | ContractDelta }>;
export type CellGenWeeklyDeltaData = Array<BaseContractType & { delta: undefined | WeeklyDelta }>;
export type CellGenCoworkerDelta = (BaseContractType & { delta: Partial<Coworker> } | undefined);
export type GenerateEditableCells = {
    timeArray: Array<string>;
    costCentre: string;
    defaultContributions: DefaultContributions | undefined;
    customContributions: CustomContributions | undefined;
} & (
    | {
        coworker: Coworker;
        delta: ScenarioDelta | null;
    }
    | {
        coworker: undefined;
        delta: ScenarioDelta;
    }
);
