import Button from '@ingka/button';
import Text from '@ingka/text';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right';
import chevronLeft from '@ingka/ssr-icon/paths/chevron-left';
import calendar from '@ingka/ssr-icon/paths/calendar';
import moment from 'moment';
import { TimeSelection } from 'types/appContext';
import { toIntlMonthLong, DateHelper } from 'utils/date';
import { TIME_FILTER_CALENDAR_BUTTON__CLICK, TIME_FILTER_NEXT_WEEK_BUTTON__CLICK, TIME_FILTER_PREVIOUS_WEEK_BUTTON__CLICK } from 'types/analytics';
import { useNestedTranslation } from 'hooks/useNestedTranslation';
import classes from './TimePeriodDisplay.module.scss';

const TimePeriodDisplay = (
    {
        timeSelection,
        disabled,
        handlePeriodShift,
        openCalendar,
    }
    : {
        timeSelection: TimeSelection,
        disabled?: boolean,
        handlePeriodShift: (s: 'left' | 'right') => void,
        openCalendar: (type: 'start' | 'end' | 'both') => void
    }
) => {
    const t = useNestedTranslation('toolbar.timeSelection');

    return (
        <div className={classes['time-period-display']} data-testid="time-period-display">
            <div className={classes['icon-row']}>
                <Button
                    iconOnly
                    size="small"
                    ssrIcon={chevronLeft}
                    onClick={() => handlePeriodShift('left')}
                    disabled={
                        (timeSelection.selection === 'week' && timeSelection.startDate === '2022-01-03') || disabled
                    }
                    type="tertiary"
                    data-analytics={TIME_FILTER_PREVIOUS_WEEK_BUTTON__CLICK}
                    // Using text with iconOnly for accessibility
                    text={timeSelection.selection === 'week' ? t('shiftBackwardsWeek') : t('shiftBackwardsMonth')}
                />
                <div className={classes['week-range']}>
                    <Button
                        iconOnly
                        size="small"
                        ssrIcon={calendar}
                        onClick={() => openCalendar('both')}
                        type="tertiary"
                        data-analytics={TIME_FILTER_CALENDAR_BUTTON__CLICK}
                        disabled={disabled}
                        // Using text with iconOnly for accessibility
                        text={t('openCalendar')}
                    />
                    <div className={`${classes['week-range-text']} ${disabled ? classes.disabled : ''}`}>
                        <div>
                            <div onClick={() => openCalendar('start')} role="button">
                                <span className={classes['bold-text']}>
                                    <Text>
                                        <b data-testid="time-period-display-start-week">
                                            {timeSelection.selection === 'week'
                                                ? t('displayWeekYear', {
                                                    weekNumber: DateHelper.getISOWeek(timeSelection.startDate),
                                                    year: DateHelper.getISOYear(timeSelection.startDate),
                                                })
                                                : toIntlMonthLong(moment(timeSelection.startDate))}
                                        </b>
                                    </Text>
                                </span>
                            </div>
                            <span className={classes.dot}> </span>
                            <div onClick={() => openCalendar('end')} role="button">
                                <span className={classes['bold-text']}>
                                    <Text>
                                        <b data-testid="time-period-display-end-week">
                                            {timeSelection.selection === 'week'
                                                ? t('displayWeekYear', {
                                                    weekNumber: DateHelper.getISOWeek(timeSelection.endDate),
                                                    year: DateHelper.getISOYear(timeSelection.endDate),
                                                })
                                                : toIntlMonthLong(moment(timeSelection.endDate))}
                                        </b>
                                    </Text>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <Button
                    iconOnly
                    size="small"
                    ssrIcon={chevronRight}
                    onClick={() => handlePeriodShift('right')}
                    type="tertiary"
                    data-analytics={TIME_FILTER_NEXT_WEEK_BUTTON__CLICK}
                    disabled={disabled}
                    // Using text with iconOnly for accessibility
                    text={timeSelection.selection === 'week' ? t('shiftForwardWeek') : t('shiftForwardMonth')}
                />
            </div>
        </div>
    );
};

export default TimePeriodDisplay;
