import { useTranslation } from 'react-i18next';
import { NumberFormatterNoDigits } from 'utils/number';
import classes from './Graph.module.scss';

const GraphLegend = ({
    legends
}: {
    legends: { label: string, value?: number, color: string }[]
}) => {
    const { t } = useTranslation();

    return (
        <div className={classes.legend}>
            {legends.map((legend, index) => (
                <span className={classes['legend-item-container']} key={`${legend.value}-${legend.color}-${legend.label}-${index + 0}`}>
                    <div className={classes['legend-title-container']}>
                        <div className={`${classes['legend-dot']}`} style={{ backgroundColor: legend.color }} />
                        <span className={classes['legend-title']}>{t(legend.label)}</span>
                    </div>
                    {typeof legend.value === 'number' && (
                    <div className={classes['legend-number-unit-container']}>
                        <span className={classes['legend-number']}>{NumberFormatterNoDigits.format(legend.value)}</span>
                        <span className={classes['legend-unit']}>{t('TOTAL_HOURS')}</span>
                    </div>
                    )}
                </span>
            ))}
        </div>
    );
};

export default GraphLegend;
