import { Routes, Route } from 'react-router-dom';
import { AppBar, OMFilters, Unauthorized } from 'components/';
import { useTranslation } from 'react-i18next';
import GlobalToast from 'components/GlobalToast';
import Text from '@ingka/text';
import useAnalytics from 'hooks/useAnalytics';
import useAuth from 'hooks/useAuth';
import { ROUTE_CONFIGURATION,
    ROUTE_DEFAULT,
    ROUTE_UNAUTHORIZED } from 'types/routes';
import { ProtectedRoute } from 'routes/ProtectedRoute/ProtectedRoute';
import { appRoutes } from 'routes/appRoutes';
import KapplaNavigate from 'routes/KapplaNavigate';
import classes from './App.module.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'focus-visible';

const App = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    useAnalytics(user);
    const { pathname } = window.location;
    const [, configurationPath] = pathname.split('/');

    return (
        <div className={classes.app}>
            <AppBar />
            <div className={classes.main}>
                <div className={classes.view}>
                    {configurationPath !== `${ROUTE_CONFIGURATION}` && <OMFilters />}
                    <Routes>
                        <Route
                            path="/"
                            element={<KapplaNavigate to={ROUTE_DEFAULT} />}
                        />
                        {appRoutes.map(({ path, element }) => (
                            <Route
                                key={path}
                                path={path}
                                element={<ProtectedRoute path={path}>{element}</ProtectedRoute>}
                            />
                        ))}
                        <Route path={ROUTE_UNAUTHORIZED} element={<Unauthorized />} />
                        <Route path="*" element={<Text tagName="h1">{t('PAGE_NOT_FOUND_ERROR')}</Text>} />
                    </Routes>
                </div>
            </div>
            <GlobalToast />
            <div id="portal" />
        </div>
    );
};
export default App;
