import React, { createContext, useEffect, useMemo, useState } from 'react';
import useAuth from 'hooks/useAuth';
import useData from 'hooks/useData';
import { AppContextValues, TimeSelection } from 'types/appContext';
import { initializeTimeSelection } from 'utils/date';
import useGrid from 'hooks/useGrid/useGrid';
import { useGapAnalytics } from 'hooks/useGapAnalytics';
import { getEmptyOrgSelection } from 'hooks/useOrgSelection/useOrgSelection.utils';
import { useOrgSelection } from 'hooks/useOrgSelection/useOrgSelection';

const AppContext = createContext<AppContextValues>({
    updateOrgSelection: () => {},
    orgSelection: getEmptyOrgSelection(),
    coworkerData: undefined,
    timeSelection: initializeTimeSelection('week'),
    setTimeSelection: () => {},
    gridData: undefined,
    currentCoworkerId: undefined,
    setCurrentCoworkerId: () => {},
    currentWeek: undefined,
    setCurrentWeek: () => {},
    searchParam: '',
    setSearchParam: () => {},
    showAllDetails: false,
    setShowAllDetails: () => {},
    showContributionDetails: true,
    setShowContributionDetails: () => {},
    isCalculating: false,
    currentScenario: undefined,
    setCurrentScenario: () => {},
    handleAnalytics: () => {},
});

const AppProvider = ({ children }: { children: JSX.Element }) => {
    const { countriesList, user } = useAuth();
    const [timeSelection, setTimeSelection] = useState<TimeSelection>(initializeTimeSelection('week'));
    const [currentCoworkerId, setCurrentCoworkerId] = useState<string>();
    const [currentWeek, setCurrentWeek] = useState<string>();
    const [searchParam, setSearchParam] = useState<string>('');
    const [showAllDetails, setShowAllDetails] = useState<boolean>(false);
    const [showContributionDetails, setShowContributionDetails] = useState<boolean>(true);
    const [isCalculating, setIsCalculating] = useState<boolean>(false);
    const { handleAnalytics } = useGapAnalytics();

    const {
        coworkerData,
        orgData,
        selectCountry,
        selectUnit,
        currentScenario,
        setCurrentScenario,
        setDateInterval,
    } = useData();

    const {
        updateOrgSelection,
        orgSelection,
    } = useOrgSelection({
        user,
        countries: countriesList,
        orgData,
        selectCountry,
        selectUnit
    });

    const { gridData } = useGrid(
        {
            coworkers: coworkerData,
            timeSelection,
            currentScenario,
            user,
            orgSelection,
            setIsCalculating,
        }
    );

    const contextValue = useMemo(
        () => ({
            updateOrgSelection,
            orgSelection,
            coworkerData,
            timeSelection,
            setTimeSelection,
            gridData,
            currentCoworkerId,
            setCurrentCoworkerId,
            currentWeek,
            setCurrentWeek,
            searchParam,
            setSearchParam,
            showAllDetails,
            setShowAllDetails,
            showContributionDetails,
            setShowContributionDetails,
            isCalculating,
            currentScenario,
            setCurrentScenario,
            handleAnalytics,
        }),
        [
            updateOrgSelection,
            orgSelection,
            coworkerData,
            timeSelection,
            gridData,
            currentCoworkerId,
            currentWeek,
            searchParam,
            showAllDetails,
            showContributionDetails,
            isCalculating,
            currentScenario,
            setCurrentScenario,
            handleAnalytics,
        ],
    );

    useEffect(() => {
        if (timeSelection) {
            setDateInterval({
                startDate: timeSelection.startDate,
                endDate: timeSelection.endDate
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSelection]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };
