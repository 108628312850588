import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import { FileUploadSuccess } from 'types/dataContext';

export const SuccessMessage = ({ fileName, fileUploadRespData }:{ fileName: string, fileUploadRespData: FileUploadSuccess }) => {
    const { t } = useTranslation();
    const { userAction, isUpdatedAt, isUpdatedBy } = fileUploadRespData;

    return (
        <InlineMessage
            data-testid={`FileUpload-inline-message-${userAction ? 'success' : 'informative'}`}
            title={t('UPLOAD_SUCCESSFUL')}
            body={`${fileName} ${t('UPLOADED_AT')} ${isUpdatedAt} ${t('BY')} ${isUpdatedBy}`}
            variant={userAction ? 'positive' : undefined}
            ssrIcon={!userAction ? information : undefined}
            subtle={!userAction}
            dismissable={false}
        />
    );
};
