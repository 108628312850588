import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import useData from 'hooks/useData';
import { useTranslation } from 'react-i18next';
import { getShortCC } from 'utils/text';
import classes from './ContractHoursRow.module.scss';

export const ContractDetails = ({
    divisionName,
    costCentre,
}:{
    divisionName: string,
    costCentre: string,
}) => {
    const { orgData } = useData();
    const { t } = useTranslation();

    return (
        <>
            <td className={classes.details}>
                {divisionName ?? t('N/A')}
            </td>
            <td className={classes.details}>
                {`${getCostCentreDescription(costCentre, orgData)} (${getShortCC(costCentre)})`}
            </td>
        </>

    );
};
