import React, { useRef, useId, memo, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import { useKapplaNavigate } from 'components/Router/useKapplaNavigate';
import useApp from 'hooks/useApp';
import { CAPACITY_OVERVIEW_GRAPH__CLICK } from 'types/analytics';
import _ from 'lodash';
import { ROUTE_MANAGE_CAPACITY } from 'types/routes';
import classes from './Graph.module.scss';
import { generateNewChart, getPlugins, generateGraphOptions } from './helpers';
import { TypeGraph } from './types';

const Graph = memo(({ graphData, graphOptions, customOptions }: TypeGraph) => {
    const id = useId();
    Chart.register(...registerables ?? []);
    const chartInstance = useRef<Chart | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const navigate = useKapplaNavigate();
    const { setCurrentWeek } = useApp();

    useEffect(() => {
        if (canvasRef.current) {
            const canvasNode = canvasRef.current;
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
            chartInstance.current = generateNewChart({
                node: canvasNode,
                data: graphData,
                plugins: getPlugins(),
                options: generateGraphOptions(graphOptions)

            });
        }
    }, [graphData, graphOptions]);

    const handleClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (!chartInstance.current) {
            return;
        }

        const element = chartInstance.current?.getElementsAtEventForMode(event as unknown as Event, 'nearest', { intersect: true }, true);

        if (!element.length) {
            return;
        }

        setCurrentWeek(graphData.labels[element[0].index]);
        setTimeout(() => {
            navigate({
                to: ROUTE_MANAGE_CAPACITY,
            });
        });
    };

    return (
        <div style={{ minHeight: customOptions?.height ?? 'initial' }}>
            {customOptions?.horizontalWidth && customOptions?.horizontalWidth > 100 && graphData.datasets[0].data.length ? (
                <div className={classes['scroll-container']}>
                    <div className={classes['scroll-container-body']} style={{ width: `${customOptions?.horizontalWidth}%` }}>
                        <canvas
                            data-testid="graph-canvas"
                            data-test-first-set={graphData.datasets[0]?.data[0] ?? 'NO_DATA'}
                            data-test-second-set={graphData.datasets[1]?.data[0] ?? 'NO_DATA'}
                            onClick={handleClick}
                            className={classes.chart}
                            ref={canvasRef}
                            id={id}
                            data-analytics={CAPACITY_OVERVIEW_GRAPH__CLICK}
                        />
                        {graphOptions.plugins.tooltip?.external && (
                        <div id={graphOptions.plugins.tooltip.external.tooltipDivId} className={classes.tooltip} />
                        )}
                    </div>
                </div>
            )
                : (
                    <>
                        <canvas
                            data-testid="graph-canvas"
                            data-test-first-set={graphData.datasets[0]?.data[0] ?? 'NO_DATA'}
                            data-test-second-set={graphData.datasets[1]?.data[0] ?? 'NO_DATA'}
                            onClick={handleClick}
                            className={classes.chart}
                            ref={canvasRef}
                            id={id}
                            data-analytics={CAPACITY_OVERVIEW_GRAPH__CLICK}
                        />
                        {graphOptions.plugins.tooltip?.external && (
                        <div id={graphOptions.plugins.tooltip.external.tooltipDivId} className={classes.tooltip} />
                        )}
                    </>
                )}
        </div>
    );
    // Added memo cb to minimize re-renders (as rerenders trigger animations, which means data jumps up and down)
}, (prevProps, nextProps) => _.isEqual(prevProps.graphData, nextProps.graphData));

export default Graph;
