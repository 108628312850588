import { useCallback, SyntheticEvent, useEffect, Dispatch, SetStateAction } from 'react';
import useAuth from 'hooks/useAuth';
import ConfigurationNavButton from 'components/Configuration/ConfigurationNavButton/ConfigurationNavButton';
import { HOME_LINK__CLICK } from 'types/analytics';
import { Header as CWDSHeader } from '@ingka-group-digital/cwds-react-header';
import useOpp from 'hooks/useOpp';
import { useProtectedLinks } from 'routes/ProtectedRoute/ProtectedRoute';
import useApp from 'hooks/useApp';
import { useKapplaNavigate } from 'components/Router/useKapplaNavigate';
import { ROUTE_DEFAULT } from 'types/routes';
import classes from './NavigationBar.module.scss';
import { getCWDSUserProfile, getWaterMarkClass } from './NavigationBar.utils';

const NavigationBar = ({
    setUserModalOpen,
    setIsAppSwitcherOpen,
}: {
    setUserModalOpen: Dispatch<SetStateAction<boolean>>;
    setIsAppSwitcherOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const { user, access } = useAuth();
    const { setCurrentCoworkerId, setCurrentWeek } = useApp();
    const { isOpp } = useOpp();
    const navLinks = useProtectedLinks();
    const navigate = useKapplaNavigate();

    const handleLocationChange = useCallback(
        (href: string, _unused: string, event: SyntheticEvent<Element, Event>) => {
            event.preventDefault();

            // Resets the states used to track a change from action plan to manage capacity table
            setCurrentCoworkerId(undefined);
            setCurrentWeek(undefined);

            // Then navigate to new page
            navigate({ to: href });
        },
        [navigate, setCurrentCoworkerId, setCurrentWeek],
    );

    useEffect(() => {
        // Using this to attach testids and analytics ids to the navlinks to maintain same data structure as with
        // previous navbar implementation
        navLinks?.forEach(link => {
            const navLink = document.querySelector(`a[href="${link.href}"]`);
            navLink?.setAttribute('data-testid', link.testid);
            navLink?.setAttribute('data-analytics', link.analyticsId);
        });
        const homeLink = document.querySelector('a[href="/"]');
        homeLink?.setAttribute('data-testid', 'home-link');
        homeLink?.setAttribute('data-analytics-id', HOME_LINK__CLICK);
    }, [navLinks]);

    return (
        <div className={classes['global-header__container']}>
            <CWDSHeader
                className={getWaterMarkClass()}
                isShowingBottomNav={false}
                user={getCWDSUserProfile(user)}
                onRedirectChange={handleLocationChange}
                onTitleClick={() => navigate({ to: ROUTE_DEFAULT })}
                narrowContent={false}
                title="People capacity planning"
                navItems={navLinks}
                isAppSwitcherShown={isOpp}
                setVisibleAppSwitcher={setIsAppSwitcherOpen}
                setVisibleUserProfile={() => setUserModalOpen(true)}
                utilities={access?.routes?.configuration && <ConfigurationNavButton />}
            />
        </div>
    );
};

export default NavigationBar;
