import { useKapplaNavigate } from 'components/Router/useKapplaNavigate';
import { useEffect } from 'react';

type KapplaNavigateProps = Parameters<ReturnType<typeof useKapplaNavigate>>[0];
/**
 * Local implementation of the Navigate component from react-router-dom.
 * Meant to do the same thing, but with the added benefit of keeping the search params in the URL when navigating.
 */
const KapplaNavigate = (navigateParams: KapplaNavigateProps) => {
    const navigate = useKapplaNavigate();

    useEffect(() => {
        navigate(navigateParams);
    }, [navigate, navigateParams]);

    return null;
};
export default KapplaNavigate;
