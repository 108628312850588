import _ from 'lodash';
import { OrgSelection, CheckBox } from 'types/appContext';
import { UserProfile } from 'types/authContext';
import { CountriesList, OrgData } from 'types/dataContext';
import { OMFilter as OMFilterCategory } from 'types/enums';
import { filterCCByUnitCode } from 'utils/filters';
import { getShortCC } from 'utils/text';

export const getEmptyOrgSelection: () => OrgSelection = () => ({
    selections: {
        countrySelection: [],
        unitSelection: [],
        divisionSelection: [],
        departmentSelection: [],
        costCentreSelection: [],
    },
    utils: {
        selectedCostCentres: [],
        allCostCentres: [],
    }
});

export type OrgActionCountries = {
    type: 'populateCountries';
    countries: CountriesList;
    homeCountry: string | undefined;
};
export type OrgActionUnit = { type: 'populateOrgData'; orgData: OrgData; homeUnit: string | undefined };
export type OrgActionSelection = {
    type: 'updateSelection';
    orgData: OrgData | undefined;
    category: OMFilterCategory;
    key: string;
    isSelectAll?: boolean;
};
export type OrgAction = OrgActionCountries | OrgActionUnit | OrgActionSelection;

export const ORG_SELECTION_SELECT_ALL = 'select-all';

/**
 * Filters array of objects based on value of defaultChecked. If defaultChecked is false for all objects, it does not filter away any objects.
 * It then returns the value of the key, passed as param.
 *
 * @param data Array of objects with a defaultChecked property
 * @param key The key of the object to return
 * @returns Array of values of the key
 * @example getValueOfChecked([{ defaultChecked: true, value: 'a' }, { defaultChecked: false, value: 'b' }], 'value') // returns ['a']
 */
export const getValueOfChecked = <
    TObj extends { defaultChecked: boolean },
    TKey extends keyof TObj,
>(
    data: Array<TObj>,
    key: TKey
): Array<TObj[TKey]> => (data.some(({ defaultChecked }) => defaultChecked)
    ? data.filter(({ defaultChecked }) => defaultChecked).map(obj => obj[key])
    : data.map(obj => obj[key]));

const getCountrySelection = (action: OrgActionCountries) => action.countries.map(c => ({
    autocomplete: true,
    id: c.code,
    type: OMFilterCategory.COUNTRY,
    label: c.name,
    defaultChecked: c.code === action.homeCountry,
    value: c.code,
}));

const updateCountrySelection = (oldState: OrgSelection, action: OrgActionSelection) => oldState.selections.countrySelection.map(c => ({
    ...c,
    defaultChecked: c.id === action.key,
}));

const getUnitSelection = (action: OrgActionUnit) => action.orgData.businessUnits.map(u => ({
    autocomplete: true,
    id: u.code,
    type: OMFilterCategory.UNIT,
    label: u.name,
    defaultChecked: u.code === action.homeUnit,
    value: u.code,
}));

const updateUnitSelection = (oldState: OrgSelection, action: OrgActionSelection) => oldState.selections.unitSelection.map(u => ({
    ...u,
    defaultChecked: u.id === action.key,
}));

const getDivisionSelection = (orgData: OrgData, unitSelection: OrgSelection['selections']['unitSelection']) => {
    const selectedUnitKey = unitSelection.find(unit => unit.defaultChecked)?.id ?? '';
    const unitId = orgData.businessUnits.find(u => u.code === selectedUnitKey)?.id ?? '';
    const divisionsUnderUnit = orgData.divisions.filter(div => div.unitId === unitId);

    const divisionSelection = divisionsUnderUnit.map(div => ({
        autocomplete: true,
        id: div.code,
        type: OMFilterCategory.DIVISION,
        label: div.name,
        defaultChecked: false,
        value: div.code,
    }));

    return divisionSelection;
};

const updateDivisionSelection = (oldState: OrgSelection, action: OrgActionSelection) => {
    if (action.key === ORG_SELECTION_SELECT_ALL) {
        const areAllSelected = oldState.selections.divisionSelection.every(div => div.defaultChecked);
        const selection = oldState.selections.divisionSelection.map(div => ({
            ...div,
            defaultChecked: !areAllSelected,
        }));

        return selection;
    }
    const selection = oldState.selections.divisionSelection.map(div => ({
        ...div,
        defaultChecked: div.id === action.key ? !div.defaultChecked : div.defaultChecked,
    }));

    return selection;
};

const getCostCentreSelection = (orgData: OrgData, divisionSelection: OrgSelection['selections']['divisionSelection']) => {
    const divisionCodes = getValueOfChecked(divisionSelection, 'id');
    const divisionIds = orgData.divisions.filter(div => divisionCodes.includes(div.code)).map(div => div.id);
    const departmentsUnderDivision = orgData.departments.filter(dept => divisionIds.includes(dept.divisionId));
    const costCentreIds = departmentsUnderDivision
        .flatMap(dept => dept.costCentre.map(cc => cc.id))
        .filter((cc, index, self) => self.indexOf(cc) === index);
    const costCentres = orgData.costCentres.filter(cc => costCentreIds.includes(cc.id));

    // extract the unit code for the selected unit
    const unitUuidFromDivision = orgData.divisions.find(div => divisionCodes.includes(div.code))?.unitId;
    const unitCode = orgData.businessUnits.find(u => u.id === unitUuidFromDivision)?.code ?? '';
    const uniqueCostCentres = filterCCByUnitCode(costCentres, unitCode);

    const costCentreSelection: CheckBox[] = uniqueCostCentres.map(cc => ({
        autocomplete: true,
        id: cc.code,
        type: OMFilterCategory.COST_CENTER,
        label: cc.desc,
        defaultChecked: false,
        value: cc.code,
        subLabel: getShortCC(cc.code),
    }));

    return costCentreSelection;
};
const updateCostCentreSelection = (oldState: OrgSelection, action: OrgActionSelection) => {
    if (action.key === ORG_SELECTION_SELECT_ALL) {
        const areAllSelected = oldState.selections.costCentreSelection.every(cc => cc.defaultChecked);
        const selection = oldState.selections.costCentreSelection.map(cc => ({
            ...cc,
            defaultChecked: !areAllSelected,
        }));

        return selection;
    }
    const selection = oldState.selections.costCentreSelection.map(cc => ({
        ...cc,
        defaultChecked: cc.id === action.key ? !cc.defaultChecked : cc.defaultChecked,
    }));

    return selection;
};

const getCostCentres = (orgData: OrgData, costCentreSelection: CheckBox[]): OrgSelection['utils']['selectedCostCentres'] => {
    const selectedCostCentreCodes = getValueOfChecked(costCentreSelection, 'id');
    const costCentres = orgData.costCentres
        .filter(cc => selectedCostCentreCodes.includes(cc.code))
        .map(costCentre => ({
            costCentreCode: costCentre.code,
            costCentreDescription: costCentre.desc,
            costCentreId: costCentre.id,
        }));

    return costCentres;
};

const getDepartmentSelection = (orgData: OrgData, costCentreSelection: OrgSelection['selections']['costCentreSelection']) => {
    const selectedCostCentreCodes = getValueOfChecked(costCentreSelection, 'id');
    const costCentreIds = orgData.costCentres.filter(cc => selectedCostCentreCodes.includes(cc.code)).map(cc => cc.id);
    const departmentsUnderCostCentre = orgData.departments.filter(dept => dept.costCentre.some(cc => costCentreIds.includes(cc.id)));

    const departmentSelection = departmentsUnderCostCentre.map(dept => ({
        autocomplete: true,
        id: dept.code,
        type: OMFilterCategory.DEPARTMENT,
        label: dept.name,
        defaultChecked: false,
        value: dept.code,
    }));

    return departmentSelection;
};

const updateDepartmentSelection = (oldState: OrgSelection, action: OrgActionSelection) => oldState.selections.departmentSelection.map(dept => ({
    ...dept,
    defaultChecked: dept.id === action.key ? !dept.defaultChecked : dept.defaultChecked,
}));

/**
 * Filters the org data based on the selected unit and the chain of uuids, to remove any divisions/costCentres/departments outside of the unit.
 * Needed in case some of the codes are duplicated, real example division codes in CH.
 * Then it is not possible to filter the org data based on the code, but instead it needs to be done using uuids.
 */
const filterOrgDataForUnit = (orgData: OrgData, unitSelection: OrgSelection['selections']['unitSelection']) => {
    const selectedUnitCode = unitSelection.find(unit => unit.defaultChecked)?.id ?? '';
    const unitUuid = orgData.businessUnits.find(u => u.code === selectedUnitCode)?.id ?? '';
    const divisionsUnderUnit = orgData.divisions.filter(div => div.unitId === unitUuid);
    const departmentsUnderUnit = orgData.departments.filter(dept => divisionsUnderUnit.some(div => div.id === dept.divisionId));
    const costCentresUnderUnit = orgData.costCentres.filter(cc => departmentsUnderUnit.some(dept => dept.costCentre.some(deptCc => deptCc.id === cc.id)));

    const filteredOrgData: OrgData = {
        country: orgData.country,
        businessUnits: orgData.businessUnits,
        divisions: divisionsUnderUnit,
        departments: departmentsUnderUnit,
        costCentres: costCentresUnderUnit,
    };

    return filteredOrgData;
};

const orgSelector = (oldState: OrgSelection, action: OrgActionSelection): OrgSelection => {
    if (action.category === OMFilterCategory.COUNTRY) {
        const countrySelection = updateCountrySelection(oldState, action);

        const newState = getEmptyOrgSelection();
        newState.selections.countrySelection = countrySelection;

        return newState;
    }
    if (action.orgData === undefined) {
        // Can only select country if there is no org data
        return oldState;
    }
    if (action.category === OMFilterCategory.UNIT) {
        const newState = _.cloneDeep(oldState);

        newState.selections.unitSelection = updateUnitSelection(oldState, action);

        // Filter the org data based on the selected unit. Fixed bug caused by CH having duplicate
        const unitOrgData = filterOrgDataForUnit(action.orgData, newState.selections.unitSelection);

        newState.selections.divisionSelection = getDivisionSelection(unitOrgData, newState.selections.unitSelection);
        newState.selections.costCentreSelection = getCostCentreSelection(unitOrgData, newState.selections.divisionSelection);
        newState.selections.departmentSelection = getDepartmentSelection(unitOrgData, newState.selections.costCentreSelection);
        newState.utils.selectedCostCentres = getCostCentres(unitOrgData, newState.selections.costCentreSelection);
        newState.utils.allCostCentres = oldState.utils.allCostCentres;

        return newState;
    }
    if (action.category === OMFilterCategory.DIVISION) {
        const newState = _.cloneDeep(oldState);

        newState.selections.divisionSelection = updateDivisionSelection(oldState, action);
        newState.selections.costCentreSelection = getCostCentreSelection(action.orgData, newState.selections.divisionSelection);
        newState.selections.departmentSelection = getDepartmentSelection(action.orgData, newState.selections.costCentreSelection);
        newState.utils.selectedCostCentres = getCostCentres(action.orgData, newState.selections.costCentreSelection);
        newState.utils.allCostCentres = oldState.utils.allCostCentres;

        return newState;
    }
    if (action.category === OMFilterCategory.COST_CENTER) {
        const newState = _.cloneDeep(oldState);

        newState.selections.costCentreSelection = updateCostCentreSelection(oldState, action);
        newState.selections.departmentSelection = getDepartmentSelection(action.orgData, newState.selections.costCentreSelection);
        newState.utils.selectedCostCentres = getCostCentres(action.orgData, newState.selections.costCentreSelection);
        newState.utils.allCostCentres = oldState.utils.allCostCentres;

        return newState;
    }
    if (action.category === OMFilterCategory.DEPARTMENT) {
        const newState = _.cloneDeep(oldState);

        newState.selections.departmentSelection = updateDepartmentSelection(oldState, action);
        newState.utils.selectedCostCentres = getCostCentres(action.orgData, newState.selections.costCentreSelection);

        return newState;
    }

    return oldState;
};

export const orgSelectionReducer = (oldState: OrgSelection, action: OrgAction): OrgSelection => {
    switch (action.type) {
    case 'populateCountries': {
        const newState = _.cloneDeep(oldState);
        newState.selections.countrySelection = getCountrySelection(action);

        return newState;
    }
    case 'populateOrgData': {
        const newState = _.cloneDeep(oldState);
        newState.selections.unitSelection = getUnitSelection(action);
        newState.selections.divisionSelection = getDivisionSelection(action.orgData, newState.selections.unitSelection);
        newState.selections.costCentreSelection = getCostCentreSelection(action.orgData, newState.selections.divisionSelection);
        newState.selections.departmentSelection = getDepartmentSelection(action.orgData, newState.selections.costCentreSelection);
        newState.utils.selectedCostCentres = getCostCentres(action.orgData, newState.selections.costCentreSelection);
        newState.utils.allCostCentres = oldState.utils.allCostCentres;

        return newState;
    }
    case 'updateSelection': {
        return orgSelector(oldState, action);
    }

    default:
        return getEmptyOrgSelection();
    }
};

export const getHomeUnitId = (userBusinessUnit: UserProfile['businessUnit'] | undefined) => userBusinessUnit?.split('.')?.[1];
