import { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getLinks } from '@ingka-group-digital/opp-components';
import { isProd, isDemo, Environment, isTest } from 'utils/environments';

const hoursBudgetingId = 5;

const useOpp = () => {
    const [searchParams] = useSearchParams();
    const [isOpp, setIsOpp] = useState(searchParams.get('opp') === 'true');
    const [version, setVersion] = useState(1);
    const oppHomeUrl = process.env[`REACT_APP_URLS_opp_home_v${version}_url`];

    const environment = useMemo(() => {
        if (isProd) return Environment.PROD;
        if (isDemo) return Environment.DEMO;
        if (isTest) return Environment.TEST;

        return Environment.DEV;
    }, []);

    const availableApps = useMemo(() => {
        const links = getLinks(environment);

        return links.filter(link => link.version === version);
    }, [environment, version]);

    const hoursBudgeting = useMemo(() => availableApps.find(app => app.id === hoursBudgetingId), [availableApps]);

    useEffect(() => {
        setIsOpp(searchParams.get('opp') === 'true');
        const versionString = searchParams.get('version');
        setVersion(versionString ? Number.parseInt(versionString, 10) : 1);
    }, [searchParams]);

    return { isOpp, version, oppHomeUrl, availableApps, hoursBudgeting };
};

export default useOpp;
