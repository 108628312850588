import { CostCentreContribution, EmploymentStatus, ContractRange, CostDistribution, Coworker, CoworkerAvailability } from './coworker';

export enum ScenarioDeltaCategory {CONTRACT_DELTA, WEEKLY_DELTA}

export enum ContractLegacy {
    temporary = 'TEMPORARY',
    permanent = 'PERMANENT',
}

export type ContractType = 'PERMANENT' | 'TEMPORARY';

export enum TypeContract {
    PERMANENT = '01',
    TEMPORARY = '02',
}

export type WeeklyDelta = {
    costCentre: string;
    costCentreHours: number;
    startDate: string;
    endDate: string;
    coworkerAvailability: CoworkerAvailability;
    timeStamp: string;
};

export type QualificationsDelta = {
    qualificationId: string;
    qualificationName: string;
};

export type ScenarioListItem = Pick<Scenario, 'id' | 'name' | 'isMain'>;
export type ScenarioList = {
    scenarios: Array<ScenarioListItem>;
};

export type PeriodsOverlap =
    | 'samePeriod'
    | 'afterReference'
    | 'beforeReference'
    | 'withinReference'
    | 'surroundsReference'
    | 'startsInReference'
    | 'endsInReference'
    | 'invalid';

export enum EmploymentType {
    COWORKER = 'COWORKER',
    CONTINGENT_WORKER = 'CONTINGENT_WORKER',
}

export type AddCoworker = {
    contractType: ContractType;
    hoursPerWeek: number | undefined;
    legalFullName: string;
    contractStartDate: string | null;
    contractEndDate: string | null;
    employmentType: EmploymentType | undefined;
    costCentre: string | undefined;
    personalAreaName: string;
};

export type TypeModulationHoursDelta = {
    costCentre: string;
    startDate: string;
    endDate: string;
    hours: number;
    minutes: number;
    timeStamp: string;
};

export type DummyCoworker = {
    contractStartDate: string | null;
    contractEndDate: string | null;
    contractType: ContractType;
    contractRange: ContractRange | null;
    contributions: Array<CostCentreContribution>;
    costCentre: string;
    costDistributions: Array<CostDistribution>;
    departmentName?: string;
    departmentCode: string;
    employmentType: EmploymentType;
    employeeId: string;
    personId: string;
    hoursPerWeek: number;
    isDummy: true;
    jobTitle?: string;
    legalFullName: string;
    personalAreaName: string;
    division: string;
    divisionName: string;
    employmentStatus: EmploymentStatus;
    timeStamp: string;
};

export type BaseContractDelta = {
    type: ContractType;
    startDate: string;
    endDate: string | null;
    costDistributions: Array<CostDistribution>;
    timeStamp: string;
};

export type ContractDeltaHours = BaseContractDelta & { hoursPerWeek: number, hoursPerWeekRange: null };
export type ContractDeltaRanges = BaseContractDelta & { hoursPerWeekRange: ContractRange, hoursPerWeek: null };
export type ContractDelta = ContractDeltaHours | ContractDeltaRanges;

export type CCDistribution = {
    fromCC: string;
    toCC: string;
    fromHours: number;
    toHours: number;
    percent: number;
};

export enum EventType {
    CONTRACT = 'contract',
    WEEK = 'week',
    DUMMY_COWORKER = 'dummy_coworker',
}

export type CoworkerNote = {
    text: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    updatedBy: string;
};

/**
 * @param weeklyDeltas stores hours or availability changes for each individual week
 * @param contractDeltas stores contract changes for each period
 * @param coworkerDelta stores overrides of default coworker props
 */
export type ScenarioDelta = {
    personId: string;
    employeeId: string;
    name: string;
    coworkerSource: 'DUMMY' | 'PA';
    dummyCoworker: DummyCoworker | null;
    qualificationDeltas: Array<QualificationsDelta>;
    weeklyDeltas: Array<WeeklyDelta>;
    contractDeltas: Array<ContractDelta>;
    modulationDeltas: Array<TypeModulationHoursDelta>;
    coworkerDelta?: Partial<Coworker>;
    department: string;
};

export type Scenario = {
    id: string;
    name: string;
    isMain : boolean;
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
    unitId: string;
    country: string;
    scenarioData: Array<ScenarioDelta>;
    executed: boolean;
    category?: 'GENERAL' | 'MASTER';
};

export type CostCentre = {
    costCentre: string;
    displayName: string;
    sourceIsPa: boolean;
};

export type CoworkerDetailsEditForm = ContractDelta & { globalError?: string };

export type AddScenarioForm = {
    scenarioType: {
        genericScenario: boolean;
        masterScenario: boolean;
    };
    scenarioName: string;
};
export enum ChangeType {
    WEEKLY_HOURS = 'WEEKLY_HOURS',
    AVAILABILITY = 'AVAILABILITY',
    CONTRACT_HOURS = 'CONTRACT_HOURS',
    NEW_COWORKER = 'NEW_COWORKER',
    CONTRACT_TYPE = 'CONTRACT_TYPE',
    COST_DISTRIBUTION = 'CC_DISTRIBUTION',
    DELETE_COWORKER = 'DELETE_COWORKER',
    MODULATION_HOURS = 'MODULATION_HOURS',
    CONTRACT_DATES = 'CONTRACT_DATES',
}
export type WeeklyHoursScenarioDelta = {
    type: ChangeType.WEEKLY_HOURS;
    from: number;
    to: number;
    costCentre: string;
};

export type AvailabilityScenarioDelta = {
    type: ChangeType.AVAILABILITY;
    from: number;
    to: CoworkerAvailability.ABSENCE | CoworkerAvailability.HOLIDAY;
    costCentre: string;
};

export type ContractHoursScenarioDelta = {
    type: ChangeType.CONTRACT_HOURS;
    from: number | ContractRange;
    to: number | ContractRange;
};

export type NewCoworkerScenarioDelta = {
    type: ChangeType.NEW_COWORKER;
    from: null;
    to: null;
};

export type ContractTypeScenarioDelta = {
    type: ChangeType.CONTRACT_TYPE;
    from: ContractType | null;
    to: ContractType;
    hoursPerWeek: number;
};

export type ContractDatesAction = {
    type: ChangeType.CONTRACT_DATES;
    from: string;
    to: string;
    hoursPerWeek: number;
};

export type CostDistributionScenarioDelta = {
    type: ChangeType.COST_DISTRIBUTION;
    from: {
        costCentre: string;
        percentage: number;
        hours: number;
    };
    to: {
        costCentre: string;
        percentage: number;
        hours: number;
    } | null;
};

export type DeleteCoworkerDelta = {
    type: ChangeType.DELETE_COWORKER;
    from: null;
    to: null;
};

export type ModulationDelta = {
    type: ChangeType.MODULATION_HOURS;
    fromHours: number;
    fromMinutes: number;
    toHours: number;
    toMinutes: number;
    costCentre: string;
};

export type TypeScenarioNameRequestAPI = {
    name: string;
};

export type ScenarioDeltaType =
    WeeklyHoursScenarioDelta
    | AvailabilityScenarioDelta
    | ContractHoursScenarioDelta
    | NewCoworkerScenarioDelta
    | ContractTypeScenarioDelta
    | CostDistributionScenarioDelta
    | DeleteCoworkerDelta
    | ModulationDelta
    | ContractDatesAction;
