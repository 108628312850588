import { logWarning } from 'utils/log';

export const createAnalyticsWorker = () => {
    try {
        const worker = new Worker(new URL('analyticsWorker.ts', import.meta.url));

        return worker;
    } catch {
        // Does not affect user experience, just prevents the gap analytics from updating with latest data.
        // Still, send a message to Sentry in production to quantify the issue.
        logWarning('Failed to create analytics worker');

        return null;
    }
};
