import moment from 'moment';
import React from 'react';
import Text from '@ingka/text';
import { DAYS_IN_A_WEEK, toIntlMonthShort } from 'utils/date';
import classes from './HiringNeeds.module.scss';

const weekNumberElement = (eachWeek: string) => (
    <Text tagName="span" bodySize="m" id="hiring-needs-week" data-testid="hiring-needs-week">
        {`W${parseInt(eachWeek.slice(6), 10)}`}
    </Text>
);

const HiringNeedsWeekHeader = ({ timeArray }:{ timeArray: string[] }) => (
    timeArray.map((eachWeek, index) => {
        const currentDate = moment(eachWeek).endOf('isoWeek');
        const isFirstWeekOfTheMonth = currentDate.date() <= DAYS_IN_A_WEEK;

        return (
            <th key={`hiring-needs-week-cell${index + 0}`}>
                {isFirstWeekOfTheMonth
                    ? (
                        <div className={classes['hiring-needs__month']}>
                            <Text tagName="span" bodySize="s" id="hiring-needs-month" data-testid="hiring-needs-month">
                                {toIntlMonthShort(currentDate.add(1, 'days'))}
                            </Text>
                            <div>
                                {weekNumberElement(eachWeek)}
                            </div>
                        </div>
                    )
                    : (
                        <div className={classes['hiring-needs__week']}>
                            {weekNumberElement(eachWeek)}
                        </div>
                    )}
            </th>
        );
    })
);

export default HiringNeedsWeekHeader;
