import useApp from 'hooks/useApp';
import useData from 'hooks/useData';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertColour } from 'utils/canvas';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import * as colours from '@ingka/variables/colours-css';
import { TFunction } from 'i18next';
import { getAlternatingColor } from 'utils/colors';
import { toIntlLongMonthFullYear } from 'utils/date';
import moment from 'moment';
import { roundDisplayValueDoubleDecimal } from 'utils/number';
import { displayRange } from 'components/ContractMix/ContractMix';
import Graph from './Graph';
import GraphHeader from './GraphHeader';
import { ChartDataWithLabel, ChartGeneratorOptions, GraphData } from './types';
import GraphLegend from './GraphLegend';
import classes from './Graph.module.scss';

const emptyDataset = (t: TFunction<'translation', undefined, 'translation'>) => [{
    label: t('NO_DATA'),
    data: [],
}];
const datasetIsEmpty = (data: ChartDataWithLabel[]) => data.length === 1 && data[0].data.length === 0;

const graphOptions = (
    t: TFunction<'translation', undefined, 'translation'>,
    graphData: GraphData,
): ChartGeneratorOptions => (
    {
        layout: {
            padleft: datasetIsEmpty(graphData.datasets) ? 0 : 20,
        },
        scales: {
            tickColor: convertColour(colours.colourStaticBlack),
            weeksThreshold: 16,
            labels: graphData.labels,
            hasNegativeValues: graphData.datasets.some(ds => ds.data.some(d => d < 0)),
            formatterYTicks: roundDisplayValueDoubleDecimal
        },
        plugins: {
            customYLabel: {
                text: '%',
                offsetX: 24,
                color: convertColour(colours.colourStaticBlack),
            },
            emptyGraph: {
                text: t('GRAPH_NO_DATA_MESSAGE')
            },
            tooltip: {
                external: {
                    tooltipDivId: 'gap-distribution-tooltip',
                    showAbovePointer: true,
                    titleFormatter: (currentTitle: string) => (
                        `${t('WEEK')} ${currentTitle.slice(-2)} (${toIntlLongMonthFullYear(moment(currentTitle).toDate())})`
                    ),
                    numberFormatter: roundDisplayValueDoubleDecimal,
                },
            }
        }

    }
);

export const ContractMixGraph = memo(() => {
    const { gridData, timeSelection } = useApp();
    const { isFetching, currentUnit, orgData } = useData();
    const { t } = useTranslation();
    const [graphData, setGraphData] = useState<GraphData>({
        datasets: emptyDataset(t),
        labels: timeSelection.timeArray,
    });

    useEffect(() => {
        if (!gridData || !gridData.contractMixData || !currentUnit || !orgData) {
            setGraphData({
                datasets: emptyDataset(t),
                labels: timeSelection.timeArray,
            });

            return;
        }

        const newDataSets: ChartDataWithLabel[] = gridData.contractMixData
            .filter(data => data.numberOfCoworkers.some(coworker => coworker > 0))
            .map((cmd, index) => ({
                type: 'line' as const,
                label: displayRange(cmd.rangeData, t),
                data: cmd.fractionOfCoworkers.map(fraction => fraction * 100),
                backgroundColor: getAlternatingColor(index),
                spanGaps: true,
                segment: {
                    borderColor: getAlternatingColor(index),
                    backgroundColor: getAlternatingColor(index),
                    borderWidth: 2, // px
                },
                pointRadius: 0, // px
                borderColor: getAlternatingColor(index),
            }));

        setGraphData({
            datasets: newDataSets,
            labels: timeSelection.timeArray
        });
    }, [currentUnit, gridData, orgData, t, timeSelection.timeArray]);

    return (
        <LoadingWrapper
            className={classes['loading-wrapper-status']}
            isLoading={isFetching}
        >
            <>
                <div className={classes['status-header-container']}>
                    <GraphHeader
                        headerTitle={t('CONTRACT_MIX_GRAPH_TITLE')}
                        headerSubTitle={t('CONTRACT_MIX_GRAPH_SUBTITLE')}
                    />
                </div>
                <Graph
                    graphData={graphData}
                    graphOptions={graphOptions(t, graphData)}
                    customOptions={{ height: '170px' }}
                />
                {(graphData.datasets.length > 0 && graphData.datasets[0].label !== t('NO_DATA')) && (
                <GraphLegend
                    legends={graphData.datasets.map(el => ({
                        label: el.label,
                        color: el.backgroundColor as string
                    }))}
                />
                )}
            </>
        </LoadingWrapper>
    );
});
