import useApp from 'hooks/useApp';
import useData from 'hooks/useData';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertColour } from 'utils/canvas';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import { getGapHours } from 'hooks/useGrid/gridFunctionsShared';
import * as colours from '@ingka/variables/colours-css';
import { TFunction } from 'i18next';
import { getDivisionHoverData } from 'hooks/useGrid/gridFunctionsHeaders';
import { getAlternatingColor } from 'utils/colors';
import { getShortCC } from 'utils/text';
import Toggle from '@ingka/toggle';
import { OrgData } from 'types/dataContext';
import { toIntlLongMonthFullYear } from 'utils/date';
import moment from 'moment';
import SSRIcon from '@ingka/ssr-icon';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import { HoverDataDivision } from 'types/appContext';
import { getValueOfChecked } from 'hooks/useOrgSelection/useOrgSelection.utils';
import { roundDisplayValueDoubleDecimal } from 'utils/number';
import Graph from './Graph';
import GraphHeader from './GraphHeader';
import { ChartDataWithLabel, ChartGeneratorOptions, GraphData } from './types';
import GraphLegend from './GraphLegend';
import classes from './Graph.module.scss';

const graphOptions = (
    t: TFunction<'translation', undefined, 'translation'>,
    graphData: GraphData,
): ChartGeneratorOptions => (
    {
        layout: {
            padleft: graphData.datasets.length && graphData.datasets[0].data.length ? 0 : 20,
        },
        scales: {
            tickColor: convertColour(colours.colourStaticBlack),
            weeksThreshold: 16,
            labels: graphData.labels,
            hasNegativeValues: graphData.datasets.some(ds => ds.data.some(d => d < 0)),
            formatterYTicks: roundDisplayValueDoubleDecimal
        },
        plugins: {
            customYLabel: {
                text: t('HOURS'),
                color: convertColour(colours.colourStaticBlack),
            },
            emptyGraph: {
                text: t('GRAPH_NO_DATA_MESSAGE')
            },
            tooltip: {
                external: {
                    tooltipDivId: 'gap-distribution-tooltip',
                    showAbovePointer: false,
                    titleFormatter: (currentTitle: string) => (
                        `${t('WEEK')} ${currentTitle.slice(-2)} (${toIntlLongMonthFullYear(moment(currentTitle).toDate())})`
                    ),
                    numberFormatter: roundDisplayValueDoubleDecimal,
                },
            }
        }

    }
);

const emptyDataset = (t: TFunction<'translation', undefined, 'translation'>) => [{
    label: t('NO_DATA'),
    data: [],
}];

const datasetIsEmpty = (data: ChartDataWithLabel[]) => data.length === 1 && data[0].data.length === 0;

export const getDivisionLabel = (divisionCode: string, orgData: OrgData | undefined): string => {
    if (divisionCode.includes(' & ')) {
        const divisions = divisionCode.split(' & ');

        return divisions.map(division => getDivisionLabel(division, orgData)).join(' & ');
    }
    if (!orgData) {
        return divisionCode;
    }

    return orgData.divisions.find(division => division.code === divisionCode)?.name ?? divisionCode;
};

export const GapDistributionGraph = memo(() => {
    const { gridData, timeSelection, orgSelection: { selections: { divisionSelection } } } = useApp();
    const { config, currentScenario, isFetching, currentUnit, orgData } = useData();
    const [toggleIndex, setToggleIndex] = useState<number>(0); // 0 means divisions(=BF), 1 means cost centres
    const [warningText, setWarningText] = useState<string | null>(null); // 0 means divisions(=BF), 1 means cost centres
    const { t } = useTranslation();
    const [graphData, setGraphData] = useState<GraphData>({
        datasets: emptyDataset(t),
        labels: timeSelection.timeArray,
    });

    useEffect(() => {
        if (!gridData || !gridData.hoverDataPerCostCentre || gridData.hoverDataPerCostCentre.length === 0 || !currentUnit || !orgData) {
            setGraphData({
                datasets: emptyDataset(t),
                labels: timeSelection.timeArray,
            });
            setWarningText(null);

            return;
        }
        const checkedDivisions = getValueOfChecked(divisionSelection ?? [], 'id');
        const filterDivisionsBySelection = ((division: HoverDataDivision) => {
            if (checkedDivisions.includes(division.divisionId)) return true;

            return false;
        });

        const divisionData = toggleIndex === 0
            ? getDivisionHoverData(gridData.hoverDataPerCostCentre, currentUnit, orgData)
            : null;

        const data = (toggleIndex === 0 && divisionData)
            ? divisionData.data.filter(filterDivisionsBySelection)
            : gridData.hoverDataPerCostCentre;

        if (toggleIndex === 0 && divisionData && divisionData.warningDoubleCounted.length > 0) {
            setWarningText(`${t('ATTENTION_MULTIPLE_BUSINESS_FUNCTIONS')}: ${
                divisionData.warningDoubleCounted.map(longCC => getShortCC(longCC)).reduce((acc, el) => (`${acc}, ${el}`))
            }`);
        } else {
            setWarningText(null);
        }

        const newDataSets: ChartDataWithLabel[] = data.map((entity, index) => ({
            type: 'line' as const,
            label: 'costCentre' in entity ? `CC ${getShortCC(entity.costCentre)}` : getDivisionLabel(entity.divisionId, orgData),
            data: entity.hoverData.map(el => getGapHours(el.gap)),
            backgroundColor: getAlternatingColor(index),
            spanGaps: true,
            segment: {
                borderColor: getAlternatingColor(index),
                backgroundColor: getAlternatingColor(index),
                borderWidth: 2, // px
            },
            pointRadius: 0, // px
            borderColor: getAlternatingColor(index),
        }));

        setGraphData({
            datasets: newDataSets,
            labels: timeSelection.timeArray
        });
    }, [gridData, config, currentScenario, t, timeSelection.timeArray, currentUnit, orgData, toggleIndex, divisionSelection]);

    return (
        <LoadingWrapper
            className={classes['loading-wrapper-status']}
            isLoading={isFetching}
        >
            <>
                <div className={classes['status-header-container']}>
                    <GraphHeader
                        headerTitle={t('GAP_DISTRIBUTION_GRAPH_TITLE')}
                        headerSubTitle={t('GAP_DISTRIBUTION_GRAPH_SUBTITLE')}
                    />
                    <Toggle
                        className={classes['capacity-status-toggle']}
                        buttons={[{ text: t('BUSINESS_FUNCTIONS') }, { text: t('COST_CENTRES') }]}
                        fluid={false}
                        activeIndex={toggleIndex ?? 0}
                        onClick={(event, index) => {
                            event.preventDefault();
                            setToggleIndex(index);
                        }}
                    />
                </div>
                <Graph
                    graphData={graphData}
                    graphOptions={graphOptions(t, graphData)}
                    customOptions={{ height: '250px' }}
                />
                {!datasetIsEmpty(graphData.datasets) && (
                    <GraphLegend
                        legends={graphData.datasets.map(el => ({
                            label: el.label,
                            color: el.backgroundColor as string
                        }))}
                    />
                )}
                {!!warningText && (
                    <div className={classes['warning-message']}>
                        <SSRIcon
                            className={classes['warning-message-icon']}
                            paths={warningTriangle}
                            data-testid="status-graph-warning"
                            width="16px"
                            height="16px"
                        />
                        <span>{warningText}</span>
                    </div>
                )}
            </>
        </LoadingWrapper>
    );
});
