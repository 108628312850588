import { EditableContractHoursCellProps } from 'types/appContext';
import { DummyCoworker } from 'types/scenario';
import EditableCell from 'components/EditableCell';
import { formatToSignedNumber, roundDisplayValueDoubleDecimal } from 'utils/number';
import useData from 'hooks/useData';
import useApp from 'hooks/useApp';
import { useUpdateScenarioAPI } from 'hooks/api/useUpdateScenarioAPI';
import { getContractHoursRange } from 'components/CoworkerDetails/helpers';
import { getGreyedOutCell } from 'components/GreyedOutCell/helpers';
import { useMemo } from 'react';
import { ModulationRange } from 'types/modulation';
import { RangeStatus } from 'types/enums';
import { Coworker } from 'types/coworker';
import { useTranslation } from 'react-i18next';
import { costCentreIsInList } from 'utils/text';
import CoworkerNameCell from './CoworkerNameCell';
import classes from './ContractHoursRow.module.scss';
import { ContractDetails } from './ContractDetails';

export const ContractHoursRow = ({
    coworker,
    showAllDetails,
    cellProps,
    canEdit,
    averageHrs,
    costCentre,
    handleClickDetails,
    handleClickModulation,
    isPrimaryRow,
    isActive,
    totalHours,
    contractStartDate,
    contractEndDate,
    modulationRange
}: {
    coworker: Coworker | DummyCoworker,
    showAllDetails: boolean,
    cellProps: Array<EditableContractHoursCellProps>,
    canEdit: boolean,
    averageHrs: number,
    totalHours: {
        contractHours: number,
        modulationHoursBalance?: number,
    },
    costCentre: string,
    handleClickDetails: () => void,
    handleClickModulation: () => void,
    isPrimaryRow: boolean,
    isActive: boolean,
    contractStartDate: string,
    contractEndDate: string,
    modulationRange: ModulationRange | undefined,
}) => {
    const { currentScenario } = useData();
    const { timeSelection, orgSelection: { utils: { selectedCostCentres } } } = useApp();
    const { updateScenario } = useUpdateScenarioAPI();
    const { t } = useTranslation();
    const isDisabled = useMemo(() => (
        (!currentScenario && !isActive) // Allow changes on inactive coworkers in a scenario
    || !selectedCostCentres.length
    || !costCentreIsInList(costCentre, selectedCostCentres.map(cc => cc.costCentreCode))
    ), [currentScenario, isActive, selectedCostCentres, costCentre]);

    const tooltipMessage = useMemo(() => {
        if (
            !selectedCostCentres.length
            || !costCentreIsInList(
                costCentre,
                selectedCostCentres.map(cc => cc.costCentreCode),
            )
        ) {
            return t('COST_CENTRE_NOT_SELECTED');
        }
        if (!isDisabled && canEdit) {
            return t('CHANGE_AVAILABILITY_DESCRIPTION');
        }

        return undefined;
    }, [canEdit, costCentre, isDisabled, selectedCostCentres, t]);

    const cells = useMemo(
        () => cellProps.map((cProps, index) => {
            const { elementId, originalValue, currentValue, key, coworkerAvailability } = cProps;
            const contractAbsentCell = getGreyedOutCell(
                contractStartDate,
                contractEndDate,
                timeSelection.timeArray,
                index,
                key,
            );

            if (contractAbsentCell !== RangeStatus.OUTSIDE_RANGE) {
                return contractAbsentCell;
            }

            return (
                <EditableCell
                    elementId={elementId}
                    originalValue={originalValue}
                    currentValue={currentValue}
                    canBeEdited={canEdit}
                    key={key}
                    coworkerAvailability={coworkerAvailability}
                    isDisabled={isDisabled}
                    coworker={coworker}
                    updateScenario={updateScenario}
                    tooltipMessage={tooltipMessage}
                    contractStartDate={contractStartDate}
                    contractEndDate={contractEndDate}
                    timeArray={timeSelection.timeArray}
                />
            );
        }).filter(el => el.toString() !== RangeStatus.OUTSIDE_RANGE && el.toString() !== RangeStatus.WITHIN_RANGE),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cellProps, canEdit, isDisabled, tooltipMessage]
    );

    return (
        <tr className={(coworker.employmentStatus === 'active' || currentScenario) ? '' : classes.disabled}>
            {isPrimaryRow ? (
                <td>
                    <div className={classes['complex-row-cell']}>
                        <CoworkerNameCell
                            coworker={coworker}
                            handleClickCoworker={handleClickDetails}
                            handleClickModulation={handleClickModulation}
                            key={coworker.personId}
                            isActive={isActive}
                            showModulationIcon={!!modulationRange}
                        />
                        <div data-testid="coworker-contract-hours">
                            {
                                !coworker.contractRange
                                    ? roundDisplayValueDoubleDecimal(coworker.hoursPerWeek ?? 0)
                                    : getContractHoursRange(coworker)
                            }
                        </div>
                    </div>
                </td>

            ) : (<td className={coworker.isDummy && isPrimaryRow ? classes['coworker-name-dummy'] : ''} />)}
            {showAllDetails && (
                <ContractDetails
                    divisionName={coworker.divisionName}
                    costCentre={costCentre}
                />
            )}
            {cells}
            <td>
                <div className={classes['table-row__footer']}>
                    {(totalHours.modulationHoursBalance || totalHours.modulationHoursBalance === 0) && currentScenario
                        && (
                        <div
                            className={`${classes['table-row__footer__cell']} 
                            ${totalHours.modulationHoursBalance !== 0 ? classes['table-row__error'] : ''}`}
                            data-testid="coworker-modulation-hours-balance"
                        >
                                {formatToSignedNumber(Number(roundDisplayValueDoubleDecimal(totalHours.modulationHoursBalance)))}
                        </div>
                        )}
                    <div className={classes['table-row__footer__cell']} data-testid="coworker-average-hours">
                        {roundDisplayValueDoubleDecimal(averageHrs)}
                    </div>
                    <div className={classes['table-row__footer__cell']} data-testid="coworker-total-hours">
                        { roundDisplayValueDoubleDecimal(totalHours.contractHours)}
                    </div>
                </div>
            </td>
        </tr>
    );
};
