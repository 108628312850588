import { DataContextValues,
    DateInterval,
    InputBudget,
    InputRates,
    WorkloadData,
    DefaultContributions,
    CustomContributions,
    PAData,
    OrgData } from 'types/dataContext';
import { ConfigurationData } from 'types/api';
import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react';
import { API } from 'services';
import { Scenario, ScenarioList } from 'types/scenario';
import useAuth from 'hooks/useAuth';
import { INDEFINITE_END_DATE } from 'utils/date';
import { Coworker } from 'types/coworker';
import { APIPath } from 'components/CapacityFactor/FileUpload/types';
import { useToast } from 'hooks/useToast';
import { sortOrgData } from './DataContext.utils';

const DataContext = createContext<DataContextValues>({
    config: undefined,
    coworkerData: undefined,
    currentScenario: undefined,
    currentCountry: undefined,
    currentUnit: undefined,
    currentUnitType: undefined,
    currentUnitBudget: undefined,
    currentUnitRates: undefined,
    defaultContributions: undefined,
    customContributions: undefined,
    isFetching: false,
    getScenarios: () => { },
    scenarioList: undefined,
    setScenarioList: () => { },
    selectCountry: () => { },
    selectScenario: () => { },
    selectUnit: () => { },
    scenarioBudget: false,
    setScenarioBudget: () => { },
    setCurrentScenario: () => { },
    setDateInterval: () => { },
    orgData: undefined,
    workload: undefined,
    getUploadedFileData: () => { },
});

/**
 * React Context used as a data layer
 */
const DataProvider = ({ children }: { children: JSX.Element }) => {
    const [orgData, setOrgData] = useState<OrgData | undefined>();
    const [coworkerData, setCoworkerData] = useState<Array<Coworker> | undefined>();
    const [currentCountry, setCurrentCountry] = useState<string | undefined>();
    const [currentUnit, setCurrentUnit] = useState<string | undefined>(undefined);
    const [currentUnitType, setCurrentUnitType] = useState<string | undefined>(undefined);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [currentUnitBudget, setCurrentUnitBudget] = useState<InputBudget | undefined>();
    const [scenarioBudget, setScenarioBudget] = useState<boolean>(false);
    const [currentUnitRates, setCurrentUnitRates] = useState<InputRates>();
    const [scenarioList, setScenarioList] = useState<undefined | ScenarioList>(undefined);
    const [currentScenario, setCurrentScenario] = useState<undefined | Scenario>(undefined);
    const [defaultContributions, setDefaultContributions] = useState<DefaultContributions>();
    const [customContributions, setCustomContributions] = useState<CustomContributions>();
    const [workload, setWorkload] = useState<WorkloadData>();
    const { access } = useAuth();
    const { displayToast } = useToast();
    // dateInterval will be set when timeSelecion is updated in AppContext
    const [dateInterval, setDateInterval] = useState<DateInterval>();
    const [config, setConfig] = useState<ConfigurationData>();

    /**
     * Gets the unit-level data which is dependent on the time selection
     */
    const getUnitData = async (unitId: string, interval: DateInterval) => {
        if (access?.api?.readInputs) {
            setIsFetching(true);
            const pUnitBudget = API().getBudget(
                currentCountry,
                currentUnit,
                currentUnitType,
                interval.startDate,
                interval.endDate,
                scenarioBudget ? currentScenario?.id : undefined
            );
            const pUnitRates = API().getRates(currentCountry, unitId, currentUnitType, interval.startDate, interval.endDate);
            const pUnitWorkload = API().getWorkload(currentCountry, unitId, currentUnitType, dateInterval?.startDate, dateInterval?.endDate);

            Promise.allSettled([pUnitRates, pUnitBudget, pUnitWorkload]).then(values => {
                const [pRates, pBudget, pWorkload] = values;
                values.forEach(value => {
                    if (value.status === 'rejected') {
                        displayToast({ title: 'ERROR', message: value.reason.message });
                    }
                });
                setCurrentUnitRates((pRates as PromiseFulfilledResult<{ data: InputRates }>).value?.data);
                setCurrentUnitBudget((pBudget as PromiseFulfilledResult<InputBudget>).value);
                setWorkload((pWorkload as PromiseFulfilledResult<{ data: { workload : WorkloadData } }>).value?.data?.workload);
            }).finally(() => {
                setTimeout(() => {
                    setIsFetching(false);
                }, 700);
            });
        }
    };

    // generic func to update the PAData in state.
    const updatePAData = (paDataResponse: PAData): void => {
        setCoworkerData(paDataResponse?.coworkers?.map(paCoworker => {
            const cwClone = { ...paCoworker };
            // If coworker contract enddate is supposed to be null but BE sends year 9999 convert it to null
            if (paCoworker.contractEndDate === INDEFINITE_END_DATE) {
                cwClone.contractEndDate = null;
            }

            /*
                Some coworker do not have their home cost centre represented in the costDistribution.
                Adding it at 0 percent if it is missing to make sure it shows up in the grid, since it's used for contract mix calculations.
            */
            if (!paCoworker?.costDistributions?.some(cd => cd.costCentre === paCoworker?.costCentre)) {
                cwClone.costDistributions.push({
                    costCentre: paCoworker?.costCentre,
                    costCentrePercent: 0
                });
            }

            return cwClone;
        }));
    };

    // Refresh/fetch latest data from uploaded file and auto update grid by refresh
    const getUploadedFileData = useCallback(async (fileCategory: string): Promise<void> => {
        if (currentCountry
            && currentUnit
            && currentUnitType
            && fileCategory
            && dateInterval?.startDate
            && dateInterval?.endDate
            && access?.api.readPAData
            && access?.api?.readInputs) {
            setIsFetching(true);
            if (fileCategory === APIPath.DEFAULT_CONFIG || fileCategory === APIPath.CUSTOM_CONTRIBUTIONS) {
                const paData: PAData = await API().getPAData(currentCountry, currentUnit, currentUnitType);
                if (paData?.coworkers?.length) {
                    updatePAData(paData);
                }
            }
            API().getUpdatedFileData(
                currentCountry,
                currentUnit,
                currentUnitType,
                fileCategory,
                dateInterval?.startDate,
                dateInterval?.endDate,
                scenarioBudget ? currentScenario?.id : undefined
            )
                .then(response => {
                    switch (fileCategory) {
                    case APIPath.CAPACITY_FACTORS:
                        setCurrentUnitRates(response.data);
                        break;
                    case APIPath.WORKLOAD:
                        setWorkload(response.data.workload);
                        break;
                    case APIPath.BUDGET_HOURS:
                        setCurrentUnitBudget(response);
                        break;
                    case APIPath.CUSTOM_CONTRIBUTIONS:
                        setCustomContributions(response);
                        break;
                    default:
                        setDefaultContributions(response);
                    }
                }).catch(err => {
                    displayToast({ title: 'ERROR', message: err.message });
                })
                .finally(() => setTimeout(() => {
                    setIsFetching(false);
                }, 700));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCountry, currentUnitType, currentUnit, dateInterval, currentScenario, scenarioBudget]);

    /**
     * Get time range dependent data for units
     */
    useEffect(() => {
        if (currentUnit && dateInterval?.startDate && dateInterval?.endDate) {
            getUnitData(currentUnit, dateInterval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUnit, dateInterval]);

    const selectUnit = useCallback(async (unitId: string): Promise<void> => {
        setCoworkerData(undefined);
        setCurrentScenario(undefined);
        setIsFetching(true);
        setCurrentUnit(unitId);
        const selectedUnitType = orgData?.businessUnits?.find(bu => bu.code === unitId)?.type;
        setCurrentUnitType(selectedUnitType);

        const pPaData = access?.api.readPAData ? API().getPAData(currentCountry, unitId, selectedUnitType) : Promise.resolve({});
        const pScenarios = access?.api.readScenarios ? API().getScenarios(currentCountry, unitId, selectedUnitType) : Promise.resolve({});
        const pDefaultContrib = access?.api?.readInputs ? API().getDefaultContribution(currentCountry, unitId, selectedUnitType) : Promise.resolve({});
        const pCustomContrib = access?.api?.readInputs ? API().getCustomContributions(currentCountry, unitId, selectedUnitType) : Promise.resolve({});

        Promise.allSettled([pPaData, pScenarios, pDefaultContrib, pCustomContrib]).then(values => {
            const [paData, scenarios, defaultContrib, customContrib] = values;
            values.forEach(value => {
                if (value.status === 'rejected') {
                    displayToast({ title: 'ERROR', message: value.reason.message });
                }
            });
            updatePAData((paData as PromiseFulfilledResult<PAData>).value);
            setScenarioList((scenarios as PromiseFulfilledResult<ScenarioList>).value);
            setDefaultContributions((defaultContrib as PromiseFulfilledResult<DefaultContributions>).value);
            setCustomContributions((customContrib as PromiseFulfilledResult<CustomContributions>).value);
        }).finally(() => {
            setTimeout(() => {
                setIsFetching(false);
            }, 700);
        });
    }, [access?.api?.readInputs, access?.api.readPAData, access?.api.readScenarios, currentCountry, displayToast, orgData?.businessUnits]);

    const selectCountry = useCallback((countryCode: string) => {
        setCoworkerData(undefined);
        setOrgData(undefined);
        setCurrentUnit(undefined);
        setScenarioList(undefined);
        setCurrentScenario(undefined);
        setCurrentCountry(countryCode);
        if (access?.api?.readOMData) {
            setIsFetching(true);
            API()
                .getOrgData(countryCode)
                .then(data => {
                    setOrgData(sortOrgData(data));
                })
                .catch(err => {
                    displayToast({ title: 'ERROR', message: err.message });
                    setOrgData(undefined);
                })
                .finally(() => {
                    setIsFetching(false);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access?.api?.readOMData]);

    // Runs only when we toggle the Enable scenario based Budget Upload
    useEffect(() => {
        const { startDate, endDate } = dateInterval ?? {};
        if (!currentCountry || !currentUnit || !currentUnitType || !startDate || !endDate) {
            return;
        }
        if (access?.api?.readInputs) {
            setIsFetching(true);
            API().getBudget(
                currentCountry,
                currentUnit,
                currentUnitType,
                startDate,
                endDate,
                scenarioBudget ? currentScenario?.id : undefined
            )
                .then(response => setCurrentUnitBudget(response))
                .catch(err => {
                    displayToast({ title: 'ERROR', message: err.message });
                    setCurrentUnitBudget(undefined);
                })
                .finally(() => {
                    setIsFetching(false);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioBudget]);

    const getScenarios = useCallback(() => {
        if (!currentCountry || !currentUnit || !currentUnitType || !access?.api?.readScenariosList) {
            return;
        }
        API().getScenarios(currentCountry, currentUnit, currentUnitType)
            .then(response => setScenarioList(response))
            .catch(err => {
                displayToast({ title: 'ERROR', message: err.message });
                setScenarioList(undefined);
            });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCountry, currentUnit]);

    const selectScenario = useCallback((scenarioId: string) => {
        if (scenarioId === 'noScenario') {
            setCurrentScenario(undefined);
        } else if (access?.api?.readScenarios) {
            API().getScenario(scenarioId, currentCountry, currentUnit, currentUnitType)
                .then(response => {
                    setCurrentScenario(response);
                })
                .catch(err => {
                    displayToast({ title: 'ERROR', message: err.message });
                    setCurrentScenario(undefined);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCountry, currentUnit]);

    useEffect(() => {
        if (currentCountry && currentUnit && currentUnitType && access?.api?.readConfig) {
            API().getConfig(currentCountry, currentUnit, currentUnitType)
                .then(response => {
                    setConfig(response);
                })
                .catch(err => {
                    displayToast({ title: 'ERROR', message: err.message });
                    setConfig(undefined);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCountry, currentUnit, currentUnitType]);

    const contextValue = useMemo(
        () => ({
            config,
            coworkerData,
            currentScenario,
            currentUnitBudget,
            currentUnitRates,
            setCurrentScenario,
            currentCountry,
            currentUnit,
            currentUnitType,
            defaultContributions,
            customContributions,
            isFetching,
            getScenarios,
            orgData,
            scenarioList,
            setScenarioList,
            selectCountry,
            selectScenario,
            selectUnit,
            scenarioBudget,
            setScenarioBudget,
            setDateInterval,
            workload,
            getUploadedFileData,
        }),
        [
            config,
            coworkerData,
            isFetching,
            currentCountry,
            currentScenario,
            currentUnitBudget,
            currentUnitRates,
            currentUnit,
            currentUnitType,
            defaultContributions,
            customContributions,
            getScenarios,
            orgData,
            scenarioList,
            setScenarioList,
            selectCountry,
            selectScenario,
            selectUnit,
            scenarioBudget,
            setScenarioBudget,
            setDateInterval,
            workload,
            getUploadedFileData,
        ],
    );

    return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

export { DataContext, DataProvider };
