import { useTranslation } from 'react-i18next';

/**
 * A hook that returns a t function that has the keyPrefix set by the prefix parameter.
 * It also includes a t function scoped to the global kexPrefix for use with global keywords.
 * @param prefix The prefix to use for the keyPrefix. t('keyvalue') will be equivalent to t('prefix.keyvalue'). Mandatory, cannot be empty string.
 * @returns A t function that has the keyPrefix set by the prefix parameter, and a t.global() function scoped to global prefix.
 * @example const t = useNestedTranslation('keyPrefix');
 * t('keyVal') is actually t('keyPrefix.keyVal') which could be 'value of keyval nested under keyPrefix in translation.json'.
 * t.global('unit') is actually t('global.unit') which would be 'Unit'.
 */
export const useNestedTranslation = <T extends string>(prefix: T extends '' ? never : T) => {
    const { t: local } = useTranslation('translation', { keyPrefix: prefix || undefined });
    const { t: global } = useTranslation('translation', { keyPrefix: 'global' });

    const t = Object.assign(local, { global });

    return t;
};
