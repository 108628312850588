import classes from './FileUpload.module.scss';

export const FileUploadPanelWrapper = ({
    heading,
    description,
    children,
}: {
    heading: string;
    description: string;
    children: React.ReactNode;
}) => (
    <div data-testid={`file-upload-heading--${heading}`} className={classes.column}>
        <div className={classes.heading}>{heading}</div>
        <div data-testid={`file-upload-description--${heading}`} className={classes.description}>
            {description}
        </div>
        {children}
    </div>
);
