import React, { useState } from 'react';
import Button from '@ingka/button';
import Pencil from '@ingka/ssr-icon/paths/pencil';
import { useTranslation } from 'react-i18next';
import useData from 'hooks/useData';
import Tooltip from '@ingka/tooltip';
import useAuth from 'hooks/useAuth';
import { EDIT_SCENARIO_BUTTON__CLICK } from 'types/analytics';
import { UserPermission } from 'types/authContext';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import ManageScenarios from './ManageScenarios';

const ManageScenario = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { currentCountry, currentUnit, scenarioList } = useData();
    const { user } = useAuth();
    const { t } = useTranslation();
    const handleClick = () => {
        setIsModalOpen(true);
    };

    const isCountryAndUnitSelected = (currentCountry && currentUnit);
    const hasScenarios = scenarioList && scenarioList.scenarios?.length > 0;
    const hasEditScenarioAccess = user?.rolePermissions.includes(UserPermission.EDIT_SCENARIO) ?? false;
    const isDisabled = !isCountryAndUnitSelected || !hasScenarios
        || !hasEditScenarioAccess;

    return (
        <span data-testid="manage-scenario">
            <Tooltip
                interactiveCustomTrigger={!isDisabled}
                tooltipText={isCountryAndUnitSelected ? t('MANAGE_SCENARIOS') : t('SELECT_A_UNIT_FIRST')}
            >
                <Button
                    data-testid="manage-scenario-button"
                    iconOnly
                    type="primary"
                    ssrIcon={Pencil}
                    onClick={handleClick}
                    size="small"
                    data-analytics={EDIT_SCENARIO_BUTTON__CLICK}
                    disabled={isDisabled}
                    // Using text with iconOnly for accessibility
                    text={t('MANAGE_SCENARIOS')}
                />
            </Tooltip>
            <Modal
                visible={isModalOpen}
                escapable
                scrollLock
                handleCloseBtn={() => setIsModalOpen(false)}
                focusLockProps={{ locked: false }}
            >
                <Sheets
                    alignment="right"
                    header={<ModalHeader title={t('MANAGE_SCENARIOS')} />}
                    footer={null}
                >
                    <ModalBody>
                        <ManageScenarios />
                    </ModalBody>
                </Sheets>
            </Modal>
        </span>
    );
};

export default ManageScenario;
