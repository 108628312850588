import useData from 'hooks/useData';
import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { CoworkerGridDataRef, GridData, OrgSelection, TimeSelection } from 'types/appContext';
import { UserProfile } from 'types/authContext';
import { Scenario } from 'types/scenario';
import { Coworker } from 'types/coworker';
import useAuth from 'hooks/useAuth';
import { updateCoworkerGridDataRef } from './gridFunctions';
import { generateGridData } from './gridFunctionsMain';

const useGrid = (
    {
        coworkers,
        timeSelection,
        currentScenario,
        user,
        orgSelection,
        setIsCalculating,
    }: {
        coworkers: Array<Coworker> | undefined,
        timeSelection: TimeSelection,
        currentScenario: Scenario | undefined,
        user: UserProfile | null,
        orgSelection: OrgSelection,
        setIsCalculating: Dispatch<SetStateAction<boolean>>,
    }
) => {
    const coworkerGridDataRef = useRef<CoworkerGridDataRef | undefined>(undefined);
    const [gridData, setGridData] = useState<GridData | undefined>(undefined);
    const { access } = useAuth();
    const {
        currentUnitRates,
        currentUnitBudget,
        defaultContributions,
        customContributions,
        workload,
        orgData,
        currentUnit,
        config
    } = useData();
    const hasModulationAccess = access?.features?.modulation ?? false;

    useEffect(() => {
        setIsCalculating(true);

        // Using setTimeout to push the calculation to the next event loop, so the loading screen can be shown
        setTimeout(() => {
            coworkerGridDataRef.current = updateCoworkerGridDataRef({
                coworkerGrid: coworkerGridDataRef.current,
                coworkers,
                timeSelection,
                user,
                currentUnit,
                orgData,
                currentUnitRates,
                defaultContributions,
                customContributions,
                hasModulationAccess,
            });

            setGridData(() => generateGridData({
                config,
                selectedCostCentres: orgSelection.utils.selectedCostCentres,
                storedNoScenarioData: coworkerGridDataRef.current,
                coworkers,
                currentScenario,
                currentUnit,
                currentUnitBudget,
                currentUnitRates,
                customContributions,
                defaultContributions,
                hasModulationAccess,
                timeSelection,
                orgData,
                user,
                workload,
            }));
            setIsCalculating(false);
        }, 0);
    }, [
        coworkers,
        config,
        user,
        timeSelection,
        hasModulationAccess,
        currentUnitRates,
        defaultContributions,
        customContributions,
        currentUnitBudget,
        currentUnit,
        setIsCalculating,
        currentScenario,
        orgSelection.utils.selectedCostCentres,
        workload,
        orgData,
    ]);

    return {
        gridData,
    };
};

export default useGrid;
