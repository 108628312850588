import { CoworkerGridData, DummyGridData, EditableContractHoursCellProps,
    EditableModulationCellProps,
    GridData,
    GridDataCoworkers,
    HoverDataCostCentre,
    HoverDataDivision,
    HeaderContribution,
    RowType,
    TimeSelection,
    WeeklyHeaderContribution } from 'types/appContext';
import { InputRates, InputBudget, WorkloadData, OrgData } from 'types/dataContext';
import { DATE_FORMAT_STANDARD, DateHelper } from 'utils/date';
import { DummyCoworker } from 'types/scenario';
import { CoworkerAvailability, Coworker } from 'types/coworker';
import { GapHoursData, ContributionsData, HoverDataContentType, HoverData } from 'types/table';
import { costCentresAreSame } from 'utils/text';
import { getContractRangeMin } from 'utils/contractRange';
import { NameId } from 'types/generic';
import { getCurrentCostCentreBudgets,
    getRatesForCostCentreAndWeek,
    getTargetCapacity,
    getWorkload } from './gridFunctionsInputFiles';
import { rowIsContractHours } from './gridFunctionsShared';

export const getHoverHeaderContribution = (
    contributedHours: number,
    costCentreId: string,
    timeSelectionWeek: string,
    inputRates: InputRates | undefined,
): Pick<WeeklyHeaderContribution, 'coworkerHours' | 'sicknessHours' | 'vacationHours' | 'otherAbsenceHours'> => {
    const costCentreWeeklyRates = getRatesForCostCentreAndWeek(inputRates, costCentreId, timeSelectionWeek);

    return {
        coworkerHours: contributedHours,
        sicknessHours: costCentreWeeklyRates.sicknessRate * contributedHours,
        vacationHours: costCentreWeeklyRates.vacationRate * contributedHours,
        otherAbsenceHours: costCentreWeeklyRates.otherAbsenceRate * contributedHours,
    };
};

/**
 * Reduces the hover data from multiple entities (e.g. two cost centres) into one
 * array containing the aggregated data. Each week contains the sum of all the hours
 * and contributions for that week.
 * @param hoverDataPerEntity
 * @returns
 */
export const aggregateHoverData = (
    hoverDataPerEntity: (HoverDataCostCentre | HoverDataDivision)[]
) => {
    if (hoverDataPerEntity.length === 0) {
        return [];
    }

    if (hoverDataPerEntity.length === 1) {
        return hoverDataPerEntity[0].hoverData;
    }

    return hoverDataPerEntity.map(data => data.hoverData).reduce((acc, curr) => {
        const mergedHoverData: HoverData[] = [];

        for (let i = 0; i < curr.length; i++) {
            mergedHoverData.push({
                gap: {
                    weekDateIso: curr[i].gap.weekDateIso,
                    contentType: curr[i].gap.contentType,
                    targetCapacity: acc[i].gap.targetCapacity + curr[i].gap.targetCapacity,
                    coworkerHours: acc[i].gap.coworkerHours + curr[i].gap.coworkerHours,
                    vacationHours: acc[i].gap.vacationHours + curr[i].gap.vacationHours,
                    sicknessHours: acc[i].gap.sicknessHours + curr[i].gap.sicknessHours,
                    otherAbsenceHours: acc[i].gap.otherAbsenceHours + curr[i].gap.otherAbsenceHours,
                },
                contributions: {
                    weekDateIso: curr[i].contributions.weekDateIso,
                    contentType: curr[i].contributions.contentType,
                    contributions: {
                        total: acc[i].contributions.contributions.total + curr[i].contributions.contributions.total,
                        evenings: acc[i].contributions.contributions.evenings + curr[i].contributions.contributions.evenings,
                        saturday: acc[i].contributions.contributions.saturday + curr[i].contributions.contributions.saturday,
                        sunday: acc[i].contributions.contributions.sunday + curr[i].contributions.contributions.sunday,
                    },
                    workload: {
                        total: acc[i].contributions.workload.total + curr[i].contributions.workload.total,
                        evenings: acc[i].contributions.workload.evenings + curr[i].contributions.workload.evenings,
                        saturday: acc[i].contributions.workload.saturday + curr[i].contributions.workload.saturday,
                        sunday: acc[i].contributions.workload.sunday + curr[i].contributions.workload.sunday,
                    },
                },
                minRangeGap: (acc[i].minRangeGap || curr[i].minRangeGap) ? {
                    weekDateIso: curr[i].minRangeGap?.weekDateIso ?? acc[i].minRangeGap?.weekDateIso ?? '',
                    contentType: HoverDataContentType.GAP,
                    targetCapacity: (acc[i].minRangeGap?.targetCapacity ?? 0) + (curr[i].minRangeGap?.targetCapacity ?? 0),
                    coworkerHours: (acc[i].minRangeGap?.coworkerHours ?? 0) + (curr[i].minRangeGap?.coworkerHours ?? 0),
                    vacationHours: (acc[i].minRangeGap?.vacationHours ?? 0) + (curr[i].minRangeGap?.vacationHours ?? 0),
                    sicknessHours: (acc[i].minRangeGap?.sicknessHours ?? 0) + (curr[i].minRangeGap?.sicknessHours ?? 0),
                    otherAbsenceHours: (acc[i].minRangeGap?.otherAbsenceHours ?? 0) + (curr[i].minRangeGap?.otherAbsenceHours ?? 0),

                } : undefined,
            });
        }

        return mergedHoverData;
    });
};

export const getModulationHeaderContribution = (
    editableCell: Pick<EditableModulationCellProps, 'currentHours' | 'currentMinutes'>,
    ccId: string,
    timeSelection: string,
    rates: InputRates | undefined,
): HeaderContribution => {
    const currentHours = editableCell.currentHours + editableCell.currentMinutes / 60;

    const contribution = {
        costCentre: ccId,
        ...getHoverHeaderContribution(
            currentHours,
            ccId,
            timeSelection,
            rates,
        ),
        eveningShifts: 0,
        saturdayShifts: 0,
        sundayShifts: 0,

    };

    return {
        contractHoursContribution: { ...contribution },
        contractRangeContributionMin: { ...contribution },
    };
};

/**
 * Gets the number that is to be used as a min value for countries with contract ranges.
 * @param weeklyHours the number which is currently in the grid. Overrides other if its the lowest
 * @param coworker the coworker, used to get the 'default' min value.
 * @param scenarioDeltas the scenario deltas which are actively used to calculate the weeklyHours
 * @returns the minRange hours for the week
 */
export const getMinRangeContractHours = (
    weeklyHours: number,
    coworker: Coworker | DummyCoworker,
    scenarioDeltas: EditableContractHoursCellProps['scenarioDeltas'] | undefined
) => {
    if (!scenarioDeltas?.latestContractDelta) {
        return Math.min(weeklyHours, coworker.contractRange?.range.min ?? weeklyHours);
    }

    const contractDeltaMin = scenarioDeltas.latestContractDelta.hoursPerWeekRange
        ? getContractRangeMin(scenarioDeltas.latestContractDelta.hoursPerWeekRange)
        : scenarioDeltas.latestContractDelta.hoursPerWeek;

    return Math.min(weeklyHours, contractDeltaMin);
};

export const getHeaderContribution = (
    coworker: Coworker | DummyCoworker,
    editableCell: Pick<EditableContractHoursCellProps, 'currentValue' | 'coworkerAvailability' | 'scenarioDeltas' | 'contributions'>,
    ccId: string,
    timeSelection: string,
    rates: InputRates | undefined,
): HeaderContribution => {
    const currentHours = typeof editableCell.currentValue === 'number' && editableCell.coworkerAvailability === CoworkerAvailability.HOURS
        ? editableCell.currentValue
        : 0;

    const minRangeHours = getMinRangeContractHours(currentHours, coworker, editableCell.scenarioDeltas);
    const contractHoursContribution = {
        costCentre: ccId,
        ...getHoverHeaderContribution(
            currentHours,
            ccId,
            timeSelection,
            rates,
        ),
        eveningShifts: editableCell.contributions.evenings,
        saturdayShifts: editableCell.contributions.saturdays,
        sundayShifts: editableCell.contributions.sundays,
    };

    return ({
        contractHoursContribution,
        contractRangeContributionMin: minRangeHours
            ? {
                costCentre: ccId,
                ...getHoverHeaderContribution(
                    minRangeHours,
                    ccId,
                    timeSelection,
                    rates,
                ),
                eveningShifts: editableCell.contributions.evenings,
                saturdayShifts: editableCell.contributions.saturdays,
                sundayShifts: editableCell.contributions.sundays,
            } : { ...contractHoursContribution },
    });
};

export const generateHeaderDataForCostCentre = (
    timeSelection: TimeSelection,
    costCentre: string,
    coworkerData: GridDataCoworkers,
    currentUnitBudget: InputBudget | undefined,
    workload: WorkloadData | undefined,
): HoverDataCostCentre => {
    const currentCostCentreBudgets = getCurrentCostCentreBudgets(currentUnitBudget, [costCentre]);
    const budgetHours = getTargetCapacity(timeSelection.timeArray, currentCostCentreBudgets);
    // filter workload data by costcenters first
    const filteredWorkload = {
        costCentres: workload?.costCentres.filter(ccWorkload => costCentresAreSame(ccWorkload.id, costCentre)) ?? []
    };

    const gapData: Array<GapHoursData> = timeSelection.timeArray.map((_date, index) => ({
        coworkerHours: 0,
        vacationHours: 0,
        sicknessHours: 0,
        otherAbsenceHours: 0,
        targetCapacity: budgetHours[index],
        weekDateIso: timeSelection.timeArray[index],
        contentType: HoverDataContentType.GAP,
    }));
    const minRangeGapData: Array<GapHoursData> = timeSelection.timeArray.map((_date, index) => ({
        coworkerHours: 0,
        vacationHours: 0,
        sicknessHours: 0,
        otherAbsenceHours: 0,
        targetCapacity: budgetHours[index],
        weekDateIso: timeSelection.timeArray[index],
        contentType: HoverDataContentType.GAP,
    }));

    const contributionsData: Array<ContributionsData> = timeSelection.timeArray.map((_date, index) => ({
        contributions: {
            total: 0,
            evenings: 0,
            saturday: 0,
            sunday: 0
        },
        workload: getWorkload(
            filteredWorkload,
            DateHelper.getISOWeekStartDate(timeSelection.timeArray[index]).format(DATE_FORMAT_STANDARD)
        ),
        weekDateIso: timeSelection.timeArray[index],
        contentType: HoverDataContentType.CONTRIBUTIONS,
    }));

    coworkerData.coworkers.forEach(coworker => {
        coworker.gridRows.forEach(row => {
            if ((rowIsContractHours(row) || row.rowType === RowType.MODULATION) && costCentresAreSame(costCentre, row.costCentre)) {
                row.headerContributions.forEach((headerContribution, index) => {
                    if (headerContribution.contractHoursContribution.coworkerHours !== 0) {
                        gapData[index].coworkerHours += headerContribution.contractHoursContribution.coworkerHours;
                        gapData[index].vacationHours += headerContribution.contractHoursContribution.vacationHours;
                        gapData[index].sicknessHours += headerContribution.contractHoursContribution.sicknessHours;
                        gapData[index].otherAbsenceHours += headerContribution.contractHoursContribution.otherAbsenceHours;

                        minRangeGapData[index].coworkerHours += headerContribution.contractRangeContributionMin?.coworkerHours
                        ?? headerContribution.contractHoursContribution.coworkerHours;
                        minRangeGapData[index].vacationHours += headerContribution.contractRangeContributionMin?.vacationHours
                        ?? headerContribution.contractHoursContribution.vacationHours;
                        minRangeGapData[index].sicknessHours += headerContribution.contractRangeContributionMin?.sicknessHours
                        ?? headerContribution.contractHoursContribution.sicknessHours;
                        minRangeGapData[index].otherAbsenceHours += headerContribution.contractRangeContributionMin?.otherAbsenceHours
                        ?? headerContribution.contractHoursContribution.otherAbsenceHours;

                        contributionsData[index].contributions.evenings += headerContribution.contractHoursContribution.eveningShifts;
                        contributionsData[index].contributions.saturday += headerContribution.contractHoursContribution.saturdayShifts;
                        contributionsData[index].contributions.sunday += headerContribution.contractHoursContribution.sundayShifts;
                        contributionsData[index].contributions.total
                            += headerContribution.contractHoursContribution.eveningShifts
                            + headerContribution.contractHoursContribution.saturdayShifts
                            + headerContribution.contractHoursContribution.sundayShifts;
                    }
                });
            }
        });
    });

    return {
        costCentre,
        hoverData: gapData.map((gap, index) => ({
            gap,
            minRangeGap: minRangeGapData[index],
            contributions: contributionsData[index],
        }))
    };
};

export const generateHoverData = (
    timeSelection: TimeSelection,
    costCentreList: Array<string>,
    coworkersData: { coworkers: (DummyGridData | CoworkerGridData)[] },
    currentUnitBudget: InputBudget | undefined,
    workload: WorkloadData | undefined

): Pick<GridData, 'hoverData' | 'hoverDataPerCostCentre'> => {
    const hoverDataPerCostCentre = costCentreList.map(costCentre => generateHeaderDataForCostCentre(
        timeSelection,
        costCentre,
        coworkersData,
        currentUnitBudget,
        workload
    ));

    const aggregatedHoverData = aggregateHoverData(hoverDataPerCostCentre);

    return {
        hoverDataPerCostCentre,
        hoverData: aggregatedHoverData,
    };
};

/**
 * Gets the division codes for the cost centre and returns it as an array. Only gets divisions
 * that are in the same unit as the cost centre.
 * @returns an array of division ids, or empty array if no divisions are found.
 */
export const getDivisionCodes = (costCentre: string, currentUnit: string, orgData: OrgData) => {
    // Map codes to IDs
    const ccId = orgData.costCentres.find(cc => cc.code === costCentre)?.id ?? '';
    const unitId = orgData.businessUnits.find(unit => unit.code === currentUnit)?.id ?? '';
    const divisionIds = orgData.departments
        .filter(dept => dept.costCentre.some(deptCc => deptCc.id === ccId)).map(dept => dept.divisionId);

    // Get get division codes
    const division = orgData.divisions
        .filter(div => div.unitId === unitId)
        .filter(div => divisionIds.includes(div.id)).map(div => div.code);

    if (!division || division?.length === 0) {
        return [];
    }

    return division;
};

/**
 * Gets the division data ({id:string, name: string}) for the cost centre and returns it as an array. Only gets divisions
 * that are in the same unit as the cost centre.
 */
export const getDivisionData = (costCentre: string, currentUnit: string, orgData: OrgData): NameId[] => {
    const divisionsFromCostCentre = getDivisionCodes(costCentre, currentUnit, orgData);
    const divisionsInUnit = orgData.divisions.filter(div => div.unitId === currentUnit);

    return divisionsFromCostCentre.map(divisionId => ({
        id: divisionId,
        name: divisionsInUnit.find(div => div.id === divisionId)?.name ?? ''
    }));
};

/**
 * Aggregates the hover data from cost centre level to division level
 * @param hoverDataPerCostCentre
 */
export const getDivisionHoverData = (
    hoverDataPerCostCentre: Array<HoverDataCostCentre>,
    currentUnit: string,
    orgData: OrgData
) => {
    const hoverDataPerDivision: Record<string, HoverDataDivision> = {};
    const doubleCountedDivisions: string[] = [];
    hoverDataPerCostCentre.forEach(ccData => {
        const divisionIds = getDivisionCodes(ccData.costCentre, currentUnit, orgData);
        if (divisionIds.length > 1) {
            doubleCountedDivisions.push(ccData.costCentre);
        }
        divisionIds.forEach(divisionId => {
            if (divisionId in hoverDataPerDivision) {
                const newData = aggregateHoverData([hoverDataPerDivision[divisionId], ccData]);
                hoverDataPerDivision[divisionId] = {
                    divisionId,
                    hoverData: newData
                };
            } else {
                hoverDataPerDivision[divisionId] = {
                    divisionId,
                    hoverData: ccData.hoverData
                };
            }
        });
    });

    return { data: Object.values(hoverDataPerDivision), warningDoubleCounted: doubleCountedDivisions };
};
