import { OrgCostCentre } from 'types/dataContext';

/**
 * Checks if a costCentre id contains its unitId in the correct position.
 * The costCentre should be `{3-letter-prefix}{unitId}XXXX` where `XXXX` is the 'short form' of the costCentre id.
 *
 * If the costCentre is in its short form, it will return true.
 */
export const costCentreIncludesUnitId = (costCentre: string, unitId: string) => {
    if (costCentre.length === 4) return true;

    return (costCentre.slice(3, 6) === unitId);
};

/**
 * Filters an array of costCentres depending on if they contain the unitId in the correct position.
 * The costCentre should be `{3-letter-prefix}{unitId}XXXX` where `XXXX` is the 'short form' of the costCentre id.
 * Used to filter away bad data from countries that can cause bugs in the application, since they can
 * cause duplicates of the XXXX costCentres.
 *
 * Any costCentre is in its short form (e.g. '1010'), will not be filtered away.
 *
 * If there are no costCentres that contain the unitId in the correct position, the original array will be returned.
 */
export const filterCostCentresByUnitId = (costCentreList: string[], unitId: string) => {
    const filteredList = costCentreList.filter(cc => costCentreIncludesUnitId(cc, unitId));

    return filteredList.length > 0 ? filteredList : costCentreList;
};

/**
 * Filters an array of cost centres depending on if their cost centre code contain the unitId in the correct position.
 * The costCentre should be `{3-letter-prefix}{unitId}XXXX` where `XXXX` is the 'short form' of the costCentre code.
 * Used to filter away bad data from countries that can cause bugs in the application, since they can
 * cause duplicates of the XXXX costCentres.
 *
 * Any costCentre is in its short form (e.g. '1010'), will not be filtered away.
 *
 * If there are no costCentres that contain the unitId in the correct position, the original array will be returned.
 */
export const filterCCByUnitCode = (costCentres: OrgCostCentre[], unitId: string) => {
    const filteredCostCentres = costCentres.filter(cc => costCentreIncludesUnitId(cc.code, unitId));

    return filteredCostCentres.length > 0 ? filteredCostCentres : costCentres;
};
