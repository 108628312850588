import React from 'react';
import classes from './ContractMix.module.scss';

const ContractMixCard = ({ label, percentage, coworkers } : {
    label: string,
    percentage: string,
    coworkers: string
}) => (
    <div data-testid="contract-mix-card" className={classes['contract-mix__card']}>
        <div data-testid="contract-mix-card-label" className={classes['contract-mix__label']}>{label}</div>
        <div className={classes['contract-mix__values']}>
            <div data-testid="contract-mix-card-percentage" className={classes['contract-mix__percentage']}>{percentage}</div>
            <div data-testid="contract-mix-card-coworkers" className={classes['contract-mix__coworkers']}>{coworkers}</div>
        </div>
    </div>
);
export default ContractMixCard;
