import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import download from '@ingka/ssr-icon/paths/arrow-down-to-line';
import Button from '@ingka/button';
import { toIntlDateTimeShort } from 'utils/date';
import { API } from 'services';
import useData from 'hooks/useData';
import { FileUploadSuccess, FileUploadFailed, FileUploadErrors } from 'types/dataContext';
import { useNestedTranslation } from 'hooks/useNestedTranslation';
import classes from './FileUpload.module.scss';
import FileUpload from './FileUpload';
import { SuccessMessage } from './SuccessMessage';
import { ErrorMessage } from './ErrorMessage';
import { APIPath } from './types';
import { FileUploadPanelWrapper } from './FileUploadPanelWrapper';

interface FileUploadPanelProps {
    id: string;
    heading: string;
    description: string;
    apiType: APIPath;
    handleDownloadFile: (load: string, category: string) => void;
    scenarioId?: string;
    disabled?: boolean;
}

const getFileInfo = (header: string) => {
    const getFileName = (headerPart: string): string => {
        if (headerPart.includes('.xlsx')) {
            return headerPart.split('=')[1].replaceAll('"', '').trim();
        }

        return '';
    };

    const getUserName = (headerPart: string): string => headerPart.split('=')[1]?.split('<')[0] || '';

    const getUpdatedDate = (headerPart: string): string => (headerPart.includes('at=') ? new Date(headerPart.split('at=')[1]).toISOString() : '');

    const [, file, by, at] = header && header.includes(';') ? header.split(';') : [];

    return {
        fileName: getFileName(file || ''),
        userName: getUserName(by || ''),
        updatedDate: getUpdatedDate(at || '')
    };
};

const FileUploadPanel = ({ id, heading, description, apiType, handleDownloadFile, scenarioId, disabled }: FileUploadPanelProps) => {
    const t = useNestedTranslation('toolbar.capacityFactors');
    const { currentCountry, currentUnit, currentUnitType, scenarioBudget } = useData();
    const [isFileAvailable, setFileAvailable] = useState<boolean>(false);
    const [fileAttribs, setFileAttribs] = useState<FileUploadSuccess | null>(null);
    const [errorMessage, setErrorMessage] = useState<FileUploadFailed | null>(null);
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);

    const successCallback = useCallback((updatedAt: string, updatedBy: string, fileName: string): void => {
        setErrorMessage(null);
        setFileAttribs({
            userAction: true,
            fileName,
            isUpdatedAt: toIntlDateTimeShort(moment(updatedAt)),
            isUpdatedBy: updatedBy
        });
    }, []);

    const errorCallback = useCallback((errorText: string, category: string, fileName?: string, errors?: FileUploadErrors[]): void => {
        setErrorMessage({
            category,
            fileName: fileName ?? '',
            errorText,
            errors: errors ?? null
        });
    }, []);

    useEffect(() => {
        if (currentCountry && currentUnit) {
            setIsFileLoading(true);
            API()
                .isFileAvailable(apiType, currentCountry, currentUnit, currentUnitType, scenarioBudget ? scenarioId : undefined)
                .then(response => {
                    setIsFileLoading(false);
                    const header = response.headers?.get('content-disposition') ?? '';
                    if (response.ok) {
                        const fileInfo = getFileInfo(header);
                        if (fileInfo.userName && fileInfo.updatedDate) {
                            setFileAttribs({
                                userAction: false,
                                fileName: fileInfo.fileName,
                                isUpdatedAt: toIntlDateTimeShort(moment(fileInfo.updatedDate)),
                                isUpdatedBy: decodeURI(fileInfo.userName)
                            });
                            setFileAvailable(true);
                        }
                    } else {
                        setFileAvailable(false);
                    }
                })
                .catch(() => {
                    setFileAvailable(false);
                    setFileAttribs(null);
                })
                .finally(() => {
                    setIsFileLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FileUploadPanelWrapper heading={heading} description={description}>
            <div>
                <div className={classes['button-column']}>
                    <div className={classes['upload-button']}>
                        <Button
                            data-testid={`file-download-button--${heading}`}
                            className={classes.button}
                            type="secondary"
                            text={t.global('download')}
                            ssrIcon={download}
                            iconPosition="trailing"
                            size="small"
                            fluid
                            loading={isFileLoading}
                            onClick={() => handleDownloadFile(t.global('download'), apiType)}
                            disabled={disabled || isFileLoading || !isFileAvailable}
                            aria-label={t('labelDownload', { sectionHeader: heading })}
                        />
                    </div>
                    <FileUpload
                        id={id}
                        category={apiType}
                        successCallback={successCallback}
                        errorCallback={errorCallback}
                        disabled={disabled}
                        scenarioId={scenarioId}
                        uploadButtonAriaLabel={t('labelUpload', { sectionHeader: heading })}
                    />
                </div>
                {fileAttribs && !errorMessage && (
                    <SuccessMessage fileName={fileAttribs.fileName} fileUploadRespData={fileAttribs} />
                )}
                {errorMessage && (
                    <ErrorMessage uploadFailedProps={errorMessage} handleDismissClick={() => setErrorMessage(null)} />
                )}
            </div>
        </FileUploadPanelWrapper>
    );
};

export default FileUploadPanel;
