import { EditableContractHoursCellProps } from 'types/appContext';
import { DummyCoworker } from 'types/scenario';
import EditableCell from 'components/EditableCell';
import useApp from 'hooks/useApp';
import { useUpdateScenarioAPI } from 'hooks/api/useUpdateScenarioAPI';
import { getGreyedOutCell } from 'components/GreyedOutCell/helpers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RangeStatus } from 'types/enums';
import { Coworker } from 'types/coworker';
import { costCentreIsInList } from 'utils/text';
import classes from './ContractHoursSubRow.module.scss';
import { ContractDetails } from './ContractDetails';

const ContractHoursSubRow = ({
    coworker,
    cellProps,
    canEdit,
    isActive,
    contractStartDate,
    contractEndDate,
    showAllDetails,
    showIndividualDetails,
    costCentre,
}: {
    coworker: Coworker | DummyCoworker,
    cellProps: Array<EditableContractHoursCellProps>,
    canEdit: boolean,
    isActive: boolean,
    contractStartDate: string,
    contractEndDate: string,
    showAllDetails: boolean,
    showIndividualDetails: boolean,
    costCentre: string,
}) => {
    const { timeSelection, orgSelection: { utils: { selectedCostCentres } } } = useApp();

    const { updateScenario } = useUpdateScenarioAPI();
    const { t } = useTranslation();
    const isDisabled = useMemo(
        () => !isActive || !selectedCostCentres.length || !costCentreIsInList(costCentre, selectedCostCentres.map(cc => cc.costCentreCode)),
        [isActive, selectedCostCentres, costCentre],
    );
    const tooltipMessage = useMemo(() => (
        !selectedCostCentres.length || !costCentreIsInList(costCentre, selectedCostCentres.map(cc => cc.costCentreCode))
            ? t('COST_CENTRE_NOT_SELECTED')
            : undefined
    ), [costCentre, selectedCostCentres, t]);

    const cells = useMemo(
        () => cellProps.map((cProps, index) => {
            const { elementId, originalValue, currentValue, key, coworkerAvailability } = cProps;
            const contractAbsentCell = getGreyedOutCell(
                contractStartDate,
                contractEndDate,
                timeSelection.timeArray,
                index,
                key,
            );

            if (contractAbsentCell !== RangeStatus.OUTSIDE_RANGE) {
                return contractAbsentCell;
            }

            return (
                <EditableCell
                    elementId={elementId}
                    originalValue={originalValue}
                    currentValue={currentValue}
                    canBeEdited={canEdit}
                    key={key}
                    coworkerAvailability={coworkerAvailability}
                    isDisabled={isDisabled}
                    coworker={coworker}
                    updateScenario={updateScenario}
                    tooltipMessage={tooltipMessage}
                    contractStartDate={contractStartDate}
                    contractEndDate={contractEndDate}
                    timeArray={timeSelection.timeArray}
                />
            );
        }).filter(el => el.toString() !== RangeStatus.OUTSIDE_RANGE && el.toString() !== RangeStatus.WITHIN_RANGE),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cellProps, canEdit, tooltipMessage, isDisabled]
    );

    return (
        <tr className={coworker.employmentStatus === 'active' ? '' : classes.inactive}>
            <td className={classes['contract-hours-row__title-cell']}>
                <b className={classes['contract-hours-row__title-cell--title']}>{t('CONTRACT_HOURS')}</b>
            </td>
            {showAllDetails && (showIndividualDetails
                ? (
                    <ContractDetails
                        divisionName={coworker.divisionName}
                        costCentre={costCentre}
                    />
                )
                : (
                    <>
                        <td />
                        <td />
                        <td />
                        <td />
                    </>
                )
            )}
            {cells}
            <td />
        </tr>
    );
};

export default ContractHoursSubRow;
