import { useCallback, useEffect, useReducer } from 'react';
import { OMFilter as OMFilterCategory } from 'types/enums';
import { UserProfile } from 'types/authContext';
import { CountriesList, OrgData } from 'types/dataContext';
import { getEmptyOrgSelection, getHomeUnitId, orgSelectionReducer } from './useOrgSelection.utils';

export const useOrgSelection = ({
    user, // used to autoselect the user's country and unit
    countries, // The list of countries the user has access to
    orgData, // The org data for the selected country
    selectCountry, // Triggers api call to get org data for the selected country
    selectUnit, // Triggers api call to get PA data for the selected unit
}: {
    user: UserProfile | null;
    countries: CountriesList | undefined;
    orgData: OrgData | undefined;
    selectCountry: (s: string) => void;
    selectUnit: (unitId: string) => void;
}) => {
    const [orgSelection, dispatch] = useReducer(orgSelectionReducer, getEmptyOrgSelection());

    const updateOrgSelection = useCallback(
        (category: OMFilterCategory, key: string) => {
            if (category === OMFilterCategory.COUNTRY) {
                selectCountry(key);
            }
            if (category === OMFilterCategory.UNIT) {
                selectUnit(key);
            }
            dispatch({ type: 'updateSelection', orgData, category, key });
        },
        [selectCountry, selectUnit, orgData],
    );

    useEffect(() => {
        // Load countries
        if (countries) {
            const homeCountry = countries.find(country => country.code === user?.country)?.code;
            dispatch({ type: 'populateCountries', countries, homeCountry });
            if (homeCountry) {
                selectCountry(homeCountry);
            }
        }
    }, [countries, selectCountry, user?.country]);

    useEffect(() => {
        // Load country specific org data
        if (orgData) {
            const homeUnit = orgData.businessUnits.find(u => u.code === getHomeUnitId(user?.businessUnit))?.code;
            dispatch({ type: 'populateOrgData', orgData, homeUnit });
            if (homeUnit) {
                selectUnit(homeUnit);
            }
        }
    }, [orgData, selectUnit, user?.businessUnit]);

    return {
        orgSelection,
        updateOrgSelection,
    };
};
