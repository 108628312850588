/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { isProd } from './environments';

const logError = (...args: any[]) => {
    console.error(...args);
};

const generateHttpErrorMessage = (response: Response): void => {
    const err = response?.statusText || response?.status ? `HTTP Error: ${response.status}` : 'Unknown error';
    if (isProd) {
        Sentry.captureMessage(err);
    } else {
        logError(err);
    }
};

const generateRuntimeErrorMessage = (error: Error) => {
    if (isProd) {
        Sentry.captureException(error);
    }

    const err = `Runtime Error: ${error.name}, ${error.message}, ${error.cause}, ${error.stack}`;
    logError(err);
};

const logWarning = (message: string) => {
    if (isProd) {
        Sentry.captureMessage(message, 'warning');
    } else {
        console.warn(message);
    }
};

export { logError, logWarning, generateHttpErrorMessage, generateRuntimeErrorMessage };
