import React, { memo } from 'react';
import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import Modal, { Sheets, ModalFooter, ModalHeader, ModalBody } from '@ingka/modal';
import { ScenarioListItem } from 'types/scenario';
import InlineMessage from '@ingka/inline-message';
import useData from 'hooks/useData';
import informativeCircle from '@ingka/ssr-icon/paths/information-circle';
import _ from 'lodash';
import { useDeleteScenarioAPI } from 'hooks/api/useDeleteScenarioAPI';
import { useToast } from 'hooks/useToast';

import classes from './ManageScenarios.module.scss';

const RemoveLastMainScenario = (
    { deletedScenario, modalVisibility, setModalVisibility }:
    { deletedScenario : ScenarioListItem, modalVisibility : boolean, setModalVisibility : React.Dispatch<React.SetStateAction<boolean>> }
) => {
    const { t } = useTranslation();
    const { currentUnit, scenarioList, currentScenario, setScenarioBudget, selectScenario, setScenarioList } = useData();

    const { deleteScenario } = useDeleteScenarioAPI(deletedScenario?.id ?? '');
    const { displayToast } = useToast();

    const handleClose = () => {
        setModalVisibility(false);
    };

    const handleDeleteScenario = _.debounce(() => {
        if (!scenarioList?.scenarios?.length) {
            return;
        }

        deleteScenario().then(response => {
            if (response.error) {
                displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });

                return;
            }
            displayToast({ title: t('SUCCESS'), message: t('SCENARIO_DELETED') });

            setScenarioList(
                {
                    scenarios: [
                        ...scenarioList.scenarios.filter(scenList => Number(scenList.id) !== Number(deletedScenario?.id)),
                    ]
                }
            );

            if (Number(deletedScenario?.id) === Number(currentScenario?.id)) {
                selectScenario('noScenario');
                setScenarioBudget(false);
            }
        }).catch(() => {
            displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });
        });
    }, 500, { trailing: true });

    return (
        <div>
            <Modal
                visible={modalVisibility}
                handleCloseBtn={handleClose}
                escapable
                onModalClosed={() => handleClose}
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={<ModalHeader title={t('MAIN_SCENARIO_REMOVE_HEADER')} backBtnClick={handleClose} className={classes['add-scenario__header']} />}
                    footer={(
                        <ModalFooter>
                            <Button
                                onClick={handleClose}
                                text={t('CANCEL')}
                                type="secondary"
                                size="small"
                                data-testid="cancel-delete-last-main-scenario-button"
                            />
                            <Button
                                onClick={handleDeleteScenario}
                                text={t('REMOVE')}
                                type="primary"
                                size="small"
                                loading={false}
                                data-testid="delete-last-main-scenario-button"
                            />

                        </ModalFooter>
                    )}
                >
                    <form data-testid="remove-last-main-scenario-form">
                        <ModalBody>
                            <div className={classes['manage-scenarios']} data-testid="remove-last-main-scenario">
                                <span>
                                    <InlineMessage
                                        data-testid="remove_last_main_scenario"
                                        ssrIcon={informativeCircle}
                                        title={t('DELETE_LAST_MAIN_SCENARIO_TITLE')}
                                        body={t('DELETING_PRIMARY_SCENARIO_INFORMATIVE_BODY', { unitCode: currentUnit })}
                                        dismissable={false}
                                        subtle={false}
                                    />
                                </span>
                            </div>
                        </ModalBody>
                    </form>
                </Sheets>
            </Modal>

        </div>
    );
};

export default memo(RemoveLastMainScenario);
