import { ConfigurationData } from 'types/api';
import { CoworkerGridDataRef, GridData, OrgSelection, TimeSelection } from 'types/appContext';
import { UserProfile } from 'types/authContext';
import { Coworker } from 'types/coworker';
import { InputBudget, InputRates, CustomContributions, DefaultContributions, WorkloadData, OrgData } from 'types/dataContext';
import { Scenario } from 'types/scenario';
import { filterCostCentresByUnitId } from 'utils/filters';
import { generateContractMixData } from './gridFunctionContractMix';
import { getScenarioGridRows, mergeRowsData, filterGridDataByCostCentre, generateGridRows } from './gridFunctions';
import { generateHoverData } from './gridFunctionsHeaders';

export type GridDataProps = {
    config: ConfigurationData | undefined,
    selectedCostCentres: OrgSelection['utils']['selectedCostCentres'],
    storedNoScenarioData: CoworkerGridDataRef | undefined,
    coworkers: Array<Coworker> | undefined,
    currentScenario: Scenario | undefined,
    currentUnit: string | undefined,
    currentUnitBudget: InputBudget | undefined,
    currentUnitRates: InputRates | undefined,
    customContributions: CustomContributions | undefined,
    defaultContributions: DefaultContributions | undefined,
    hasModulationAccess: boolean,
    timeSelection: TimeSelection,
    orgData: OrgData | undefined,
    user: UserProfile | null,
    workload: WorkloadData | undefined,
};
/**
 * Main function for generating grid data. Maintain as a separate function to allow both local usage and analytics usage.
 */
export const generateGridData = ({
    config,
    selectedCostCentres,
    storedNoScenarioData,
    coworkers,
    currentScenario,
    currentUnit,
    currentUnitBudget,
    currentUnitRates,
    customContributions,
    defaultContributions,
    hasModulationAccess,
    timeSelection,
    orgData,
    user,
    workload,
}: GridDataProps): GridData | undefined => {
    if (!user || !timeSelection || !timeSelection.valid || !currentUnit || selectedCostCentres.length === 0) {
        return undefined;
    }

    // Filter out costCentres whos costCentreId does not contain the current unitId
    // This is a (temporary?) workaround for bad GV data
    const uniqueCostCentres = filterCostCentresByUnitId(selectedCostCentres.map(cc => cc.costCentreCode), currentUnit);

    const noScenarioGridRows = storedNoScenarioData || {
        coworkers: generateGridRows(
            {
                coworkers,
                scenario: undefined,
                timeSelection,
                costCentreList: uniqueCostCentres,
                hasModulationAccess,
                currentUnitRates,
                defaultContributions,
                customContributions,
            }
        )
    };

    const scenarioGridRows = getScenarioGridRows(
        {
            costCentreList: uniqueCostCentres,
            orgData,
            currentUnit,
            coworkers,
            currentScenario,
            timeSelection,
            hasModulationAccess,
            currentUnitRates,
            defaultContributions,
            customContributions,
        }
    );

    const mergedRowsData = mergeRowsData(noScenarioGridRows, scenarioGridRows);
    const gridDataCostCentreFiltered = filterGridDataByCostCentre(mergedRowsData, uniqueCostCentres);

    if (!gridDataCostCentreFiltered) return undefined;

    const allHoverData = generateHoverData(
        timeSelection,
        uniqueCostCentres,
        gridDataCostCentreFiltered,
        currentUnitBudget,
        workload
    );

    const contractMixData = generateContractMixData({
        coworkers: gridDataCostCentreFiltered.coworkers,
        timeArray: timeSelection.timeArray,
        config,
        costCentreList: selectedCostCentres.map(cc => cc.costCentreCode)
    });

    return {
        ...gridDataCostCentreFiltered,
        ...allHoverData,
        contractMixData
    };
};
