import { Dispatch, SetStateAction, useCallback } from 'react';
import { AppSwitcherDrawer, AppSwitcherDrawerProps } from '@ingka-group-digital/cwds-react-app-switcher';
import useOpp from 'hooks/useOpp';
import { useTranslation } from 'react-i18next';

const AppSwitcherModal = ({
    isAppSwitcherOpen,
    setIsAppSwitcherOpen
}: {
    isAppSwitcherOpen: boolean;
    setIsAppSwitcherOpen: Dispatch<SetStateAction<boolean>>
}) => {
    const { t } = useTranslation();
    const { oppHomeUrl, availableApps } = useOpp();
    const handleHomeClick = useCallback<NonNullable<AppSwitcherDrawerProps['onHomeClick']>>(
        async event => {
            event.preventDefault();
            if (oppHomeUrl) {
                window.location.href = oppHomeUrl;
            }
        },
        [oppHomeUrl]
    );

    return (
        <AppSwitcherDrawer
            visible={isAppSwitcherOpen}
            accessibleApps={availableApps}
            isSeeAllAppsShown={false}
            onHomeClick={handleHomeClick}
            onAppSwitcherClose={() => setIsAppSwitcherOpen(false)}
            appSwitcherLabels={{ title: t('PEOPLE_PLANNING_CAPABILITIES'), goToHome: t('GO_TO_HOME', { home: 'One people planning' }) }}
        />
    );
};

export default AppSwitcherModal;
