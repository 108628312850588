import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import Select, { Option } from '@ingka/select';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'context/AppContext';
import { CheckBox } from 'types/appContext';
import { OMFilter } from 'types/enums';
import classes from './CountrySelector.module.scss';

const CountrySelector = () => {
    const { t } = useTranslation();
    const { orgSelection: { selections: { countrySelection } }, updateOrgSelection } = useContext(AppContext);
    const [selectedCountry, setSelectedCountry] = useState<string>('');

    const handleSelectCountry = useMemo(() => (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedCountry(e.target.value);
        const countryCode = countrySelection?.find(({ label }) => label === e.target.value)?.id;
        if (countryCode) updateOrgSelection(OMFilter.COUNTRY, countryCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="config-page-country-header" className={classes.country__header}>
            <Text
                tagName="h2"
                headingSize="m"
                id="config-page-country-title"
                data-testid="config-page-country-title"
                className={classes['country__header-text']}
            >
                {t('COUNTRY_CONFIG_TITLE')}
            </Text>
            <Text
                tagName="span"
                bodySize="m"
                id="config-page-country-sub-title"
                data-testid="config-page-country-sub-title"
            >
                {t('COUNTRY_CONFIG_SUBTITLE')}
            </Text>
            <div id="config-page-country-dropdown-div" className={classes.country__dropdown}>
                <Select
                    id="config-contry-dropdown"
                    data-testid="config-contry-dropdown"
                    label={t('COUNTRY')}
                    name={selectedCountry || countrySelection?.find(({ defaultChecked }) => defaultChecked)?.label}
                    value={selectedCountry || countrySelection?.find(({ defaultChecked }) => defaultChecked)?.label}
                    disabled={countrySelection?.length === 1}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => handleSelectCountry(e)}
                >
                    {countrySelection?.map((country: CheckBox) => (
                        <Option
                            data-testid={`config-contry-option-${country.label}`}
                            key={`config-contry-${country.id}`}
                            value={country.label}
                            name={country.label}
                            defaultChecked={country.defaultChecked}
                        />
                    ))}
                </Select>
            </div>
        </div>
    );
};

export default CountrySelector;
