import React, { useState, useEffect, useCallback } from 'react';
import Button from '@ingka/button';
import plus from '@ingka/ssr-icon/paths/plus';
import trash from '@ingka/ssr-icon/paths/trash-can';
import Select, { Option } from '@ingka/select';
import Toggle from '@ingka/toggle';
import DatePickerDisplay from 'components/TimeFilter/DatePickerDisplay';
import InputField from '@ingka/input-field';
import { ContractDelta, CostCentre, ContractLegacy, CoworkerDetailsEditForm, DummyCoworker } from 'types/scenario';
import FormField from '@ingka/form-field';
import useData from 'hooks/useData';
import { FieldArrayWithId, UseFormGetValues, UseFormSetValue,
    Controller,
    Control,
    FieldErrorsImpl,
    UseFormTrigger,
    UseFormClearErrors } from 'react-hook-form';
import moment from 'moment';
import { DATE_FORMAT_FULL_YEAR_ISO_WEEK } from 'utils/date';
import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import { CheckBox } from 'types/appContext';
import InlineMessage from '@ingka/inline-message';
import { displayContractRangeWithDescription, getShortCC, isNullOrEmpty, toFirstLetterUpperCase } from 'utils/text';
import { isInteger, isIntegerOrDecimal } from 'utils/validation';
import { Coworker } from 'types/coworker';
import { sortContractRangeDesc } from 'utils/sorting';
import { zeroHoursContractRange } from 'utils/constants';
import { useNestedTranslation } from 'hooks/useNestedTranslation';
import CoworkerData from './CoworkerData';
import CoworkerContractType from './CoworkerContract';
import classes from './CoworkerDetails.module.scss';
import CoworkerCostCentreList from './CoworkerCostCenterList';
import { getContractHoursRange } from './helpers';

export const CoworkerDetailsEditDisplay = ({
    coworker,
    costCentres,
    costDistributions,
    addCostDistribution,
    deleteCostDistribution,
    costCentreDropdownList,
    setValue,
    getValues,
    defaultValues,
    control,
    errors,
    trigger,
    clearErrors,
}: {
    coworker: Coworker | DummyCoworker,
    costCentres: CostCentre[],
    costDistributions: FieldArrayWithId<ContractDelta, 'costDistributions', 'id'>[],
    addCostDistribution: () => void,
    deleteCostDistribution: (index: number) => void,
    costCentreDropdownList: Array<CheckBox>,
    setValue: UseFormSetValue<ContractDelta>,
    getValues: UseFormGetValues<ContractDelta>,
    defaultValues: CoworkerDetailsEditForm,
    control: Control<ContractDelta, object>,
    errors: Partial<FieldErrorsImpl<CoworkerDetailsEditForm>>,
    trigger: UseFormTrigger<ContractDelta>,
    clearErrors: UseFormClearErrors<CoworkerDetailsEditForm>
}) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { orgData, config } = useData();
    const t = useNestedTranslation('modifyCoworkerContract');
    const getContractRangeFromType = useCallback((type: string | undefined) => {
        if (type === zeroHoursContractRange.type) {
            return zeroHoursContractRange;
        }
        if (type && config && config.contractRanges && config.contractRanges.length > 0 && config.isContractRangeSupported) {
            return config.contractRanges.find(cr => cr.type === type) ?? null;
        }

        return null;
    }, [config]);

    useEffect(() => {
        if (activeIndex === null) { return; }
        if (activeIndex) {
            costCentreDropdownList?.sort((current, next) => Number(current.subLabel) - Number(next.subLabel));

            return;
        }
        costCentreDropdownList?.sort((current, next) => current.label.localeCompare(next.label));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex]);

    return (
        <div className={classes['coworker-edit-modal-container']} data-testid="edit-coworker">
            <div className={classes['column-info']}>
                <CoworkerData
                    title={t('contractHours')}
                    data={!coworker.contractRange ? coworker.hoursPerWeek : getContractHoursRange(coworker as Coworker)}
                />
                <CoworkerContractType title={t('contractType')} data={coworker.contractType} />
                <CoworkerData title={t('jobTitle')} data={coworker.jobTitle} />
                <CoworkerData title={t.global('unit')} data={coworker.personalAreaName} />
                <CoworkerData title={t.global('department')} data={coworker.departmentName} />
                <CoworkerCostCentreList
                    title={t.global('costCentre')}
                    costCentres={costCentres}
                />
                <CoworkerData title={t('idNumber')} data={coworker.isDummy ? undefined : coworker.personId} />
            </div>
            <div className={classes['row-info']}>
                <div className={classes['main-heading']}>
                    {t('newChange')}
                </div>
                <div className={classes.heading}>
                    {t('periodOfChange')}
                </div>
                <div className={classes.block}>
                    <div className={classes.item}>
                        <Controller
                            control={control}
                            name="startDate"
                            render={({
                                field: { value },
                                fieldState: { error },
                            }) => (
                                <FormField
                                    valid={!error?.message}
                                    shouldValidate
                                    validation={{
                                        msg: error?.message,
                                    }}
                                >
                                    <DatePickerDisplay
                                        data-testId="coworkeredit-start-time"
                                        header={t('changeStart')}
                                        inputType="week"
                                        inputValue={value}
                                        maxValue={getValues('type') === ContractLegacy.temporary ? getValues('endDate') : null}
                                        handleChange={({ target: { value: val } }) => {
                                            setValue(
                                                'startDate',
                                                val,
                                                { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                                            );
                                        }}
                                        handleBlur={({ target: { value: val } }) => setValue(
                                            'startDate',
                                            val,
                                            { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                                        )}
                                    />
                                </FormField>
                            )}
                        />
                    </div>
                    <div className={classes.item}>
                        <Controller
                            control={control}
                            name="endDate"
                            render={({
                                field: { value },
                                fieldState: { error },
                            }) => (
                                <FormField
                                    valid={!error?.message}
                                    shouldValidate
                                    validation={{
                                        msg: error?.message,
                                    }}
                                >
                                    <DatePickerDisplay
                                        data-testId="coworkeredit-end-time"
                                        header={t('changeEnd')}
                                        inputType="week"
                                        inputValue={value}
                                        minValue={moment(getValues('startDate')).format(DATE_FORMAT_FULL_YEAR_ISO_WEEK)}
                                        handleChange={({ target: { value: val } }) => {
                                            setValue(
                                                'endDate',
                                                isNullOrEmpty(val) ? null : val,
                                                { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                                            );

                                            setValue(
                                                'type',
                                                val ? 'TEMPORARY' : 'PERMANENT',
                                                { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                                            );
                                        }}
                                        handleBlur={({ target: { value: val } }) => setValue(
                                            'endDate',
                                            isNullOrEmpty(val) ? null : val,
                                            { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                                        )}
                                    />
                                </FormField>

                            )}
                        />
                    </div>
                </div>
                <div className={classes.heading}>
                    {t('contract')}
                </div>
                <div className={classes.block}>
                    <div className={classes.item}>
                        <Controller
                            control={control}
                            name="type"
                            render={({
                                field: { onChange, onBlur, name, value }
                            }) => (
                                <Select
                                    name={name}
                                    value={value}
                                    onChange={event => {
                                        clearErrors('globalError');
                                        onChange(event);
                                        if (event.target.value === ContractLegacy.permanent) {
                                            setValue('endDate', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                                        } else {
                                            trigger('endDate');
                                        }
                                    }}
                                    onBlur={onBlur}
                                    id="coworker-edit-contract-type"
                                    data-testid="coworker-edit-contract-type"
                                    hintText=""
                                    label={t('contractType')}
                                >
                                    {Object.values(ContractLegacy).map(val => (
                                        <Option key={val} value={val} name={t(val)} />
                                    ))}
                                </Select>
                            )}
                        />
                    </div>
                    <div className={classes.item}>
                        {defaultValues.hoursPerWeek !== null && (
                        <Controller
                            control={control}
                            name="hoursPerWeek"
                            render={({
                                field: { value, name, onChange, onBlur },
                                fieldState: { error, isTouched },
                            }) => (
                                <FormField
                                    valid={!error?.message}
                                    shouldValidate={isTouched}
                                    validation={{
                                        msg: error?.message
                                    }}
                                >
                                    <InputField
                                        autoComplete="off"
                                        id="coworker-edit-contract-hours"
                                        data-testid="coworker-edit-contract-hours"
                                        label={t('contractHours')}
                                        type="number"
                                        name={name}
                                        value={value ?? 0}
                                        onChange={event => {
                                            clearErrors('globalError');
                                            if (!isIntegerOrDecimal(event.target.value)) {
                                                return;
                                            }
                                            // convert value from string to number for except finland
                                            const newEvent = { ...event, target: { ...event.target, value: event.target.value } };
                                            onChange(newEvent);
                                        }}
                                        onBlur={() => { clearErrors('globalError'); onBlur(); }}
                                    />
                                </FormField>
                            )}
                        />
                        )}
                        {defaultValues.hoursPerWeekRange !== null && (
                        <Controller
                            control={control}
                            name="hoursPerWeekRange"
                            render={({
                                field: { value, name, onChange, onBlur },
                            }) => (
                                <Select
                                    name={name}
                                    value={value?.type ?? ''}
                                    onChange={event => {
                                        clearErrors('globalError');
                                        onChange(event);
                                        setValue(
                                            'hoursPerWeekRange',
                                            getContractRangeFromType(event.target.value),
                                            { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                                        );
                                    }}
                                    onBlur={onBlur}
                                    id="coworker-edit-contract-range"
                                    data-testid="coworker-edit-contract-range"
                                    hintText=""
                                    label={t('contractHours')}
                                >
                                    {config?.isContractRangeSupported && config.contractRanges
                                        .sort(sortContractRangeDesc)
                                        .map(cr => (
                                            <Option
                                                key={`contract-range-${cr.type}`}
                                                value={cr.type}
                                                name={displayContractRangeWithDescription(cr)}
                                            />
                                        ))}
                                    <Option
                                        key={`contract-range-${zeroHoursContractRange.type}`}
                                        value={zeroHoursContractRange.type}
                                        name={`${t(zeroHoursContractRange.description)} 
                                        (${zeroHoursContractRange.range.min} - ${zeroHoursContractRange.range.max})`}
                                    />
                                </Select>
                            )}
                        />
                        )}
                    </div>
                </div>
                <div className={classes['cost-centre-row']}>
                    <div className={classes.heading}>
                        {t.global('costCentre')}
                    </div>
                    <div className={classes['cost-centre-buttons-row']}>
                        <div>
                            <Button
                                data-testid="add-cost-centre"
                                ssrIcon={plus}
                                size="small"
                                type="secondary"
                                text={t('addCostCentre')}
                                onClick={() => { addCostDistribution(); setActiveIndex(null); }}
                            />
                        </div>
                        <div>
                            <Toggle
                                className={classes['om-filters-toggle-button']}
                                buttons={[{
                                    text: t.global('aToZ'),
                                    'aria-label': t('sortCcAlphabetically')
                                }, {
                                    text: '0-9',
                                    'aria-label': t('sortCcNumerically')
                                }]}
                                fluid={false}
                                activeIndex={activeIndex ?? 0}
                                onClick={(event, index) => {
                                    setActiveIndex(index);
                                    event.preventDefault(); // prevent form submit
                                    trigger('costDistributions'); // trigger validation and rerendering of costDistributions, to update sorting
                                }}
                                aria-label={t('ariaLabelSortCostCentres')}
                            />
                        </div>
                    </div>
                </div>

                {costDistributions.map((cd, index) => {
                    const isDisabled = coworker.costDistributions.map(cwCd => cwCd.costCentre).includes(cd.costCentre);

                    return (
                        <div key={cd.id + cd.costCentre} className={classes.block}>
                            <div className={classes.item}>
                                <Controller
                                    control={control}
                                    name={`costDistributions.${index}.costCentre`}
                                    render={({
                                        field: { onChange, onBlur, name, value },
                                        fieldState: { error }
                                    }) => (
                                        <FormField
                                            valid={!error?.message}
                                            shouldValidate
                                            validation={{
                                                msg: error?.message
                                            }}
                                        >
                                            <Select
                                                name={name}
                                                value={value}
                                                onChange={event => { clearErrors('globalError'); onChange(event); }}
                                                onBlur={onBlur}
                                                id="coworker-edit-cost-centre"
                                                label={toFirstLetterUpperCase(t.global('costCentre'))}
                                                hintText=""
                                            >
                                                <Option
                                                    value={cd.costCentre}
                                                    name={cd.costCentre !== ''
                                                        ? `CC ${getShortCC(cd.costCentre)} - 
                                                            ${getCostCentreDescription(cd.costCentre, orgData)}`
                                                        : t('selectCostCentre')}
                                                />
                                                {costCentreDropdownList?.map(cc => (
                                                    <Option
                                                        key={cc.label}
                                                        value={cc.id}
                                                        name={`CC ${getShortCC(cc.value)} - 
                                                            ${getCostCentreDescription(cc.value, orgData)}`}
                                                    />
                                                ))}
                                            </Select>
                                        </FormField>
                                    )}
                                />
                            </div>
                            <div className={classes.item}>
                                <Controller
                                    control={control}
                                    name={`costDistributions.${index}.costCentrePercent`}
                                    render={({
                                        field: { value, name },
                                        fieldState: { error, isTouched },
                                    }) => (
                                        <FormField
                                            valid={!error?.message}
                                            shouldValidate={isTouched}
                                            validation={{
                                                msg: error?.message
                                            }}
                                        >
                                            <InputField
                                                autoComplete="off"
                                                id={`coworker-edit-cost-centre-${cd.costCentre}-percent`}
                                                data-testid="edit-cost-centre-input"
                                                label={t('percentage')}
                                                max={100}
                                                min={0}
                                                suffixLabel="%"
                                                type="number"
                                                name={name}
                                                value={value}
                                                onChange={event => {
                                                    clearErrors('globalError');
                                                    if (!isInteger(event.target.value)) {
                                                        return;
                                                    }

                                                    setValue(
                                                        `costDistributions.${index}.costCentrePercent`,
                                                        Number(event.target.value),
                                                        { shouldTouch: true, shouldDirty: true, shouldValidate: true }
                                                    );
                                                }}
                                                onBlur={() => trigger('costDistributions')}
                                            />
                                        </FormField>
                                    )}
                                />
                            </div>
                            <div className={classes.button}>
                                <Button
                                    data-testid="cost-centre-delete-button"
                                    ssrIcon={trash}
                                    size="small"
                                    type="secondary"
                                    iconOnly
                                    onClick={() => { deleteCostDistribution(index); setActiveIndex(null); }}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                    );
                })}
                {errors.globalError?.type === 'FIELD_INTERACTION_REQUIRED_FOR_SAVE'
                && (<div style={{ marginBottom: '12px' }}><InlineMessage title={errors.globalError?.message} variant="cautionary" /></div>)}
                {errors.costDistributions?.type === 'PERCENT_100_MANDATORY'
                && (<div style={{ marginBottom: '12px' }}><InlineMessage title={errors.costDistributions.message} variant="negative" /></div>)}
            </div>
        </div>
    );
};
