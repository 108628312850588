import { TFunction } from 'i18next';
import { CheckBox, TimeSelection } from 'types/appContext';
import { isBetweenTimePeriod, isoWeekFromDateString, generateTimeArray } from 'utils/date';
import { HiringNeedsData, HiringNeedsRowProps,
    RecruitmentNeedsDelta, WeekBasedData, HiringNeedsRowTypes,
    NewHiresDelta, ContractsDelta, TurnOverDelta } from 'types/recruitmentNeeds';
import { calculateSum } from 'utils/number';

const isSomeCcSelected = (costCenterOMData: CheckBox[] | undefined): boolean => costCenterOMData?.some(({ defaultChecked }) => defaultChecked) ?? false;

const getCCLabel = (costCenter: string, costCenterData: CheckBox[] | undefined): string => costCenterData?.find(({ id }) => costCenter === id)?.label ?? '';

const getFilteredNewHires = (
    costCenterOMData: CheckBox[] | undefined,
    recruitmentNeedsData: RecruitmentNeedsDelta[] | undefined,
    timeSelection: TimeSelection
) => {
    const allNewHires = recruitmentNeedsData?.flatMap(({ newHires }) => newHires);
    if (isSomeCcSelected(costCenterOMData)) {
        return allNewHires?.filter(({ startDate, costCentre }) => costCenterOMData?.find(({ id }) => id === costCentre)?.defaultChecked
            && isBetweenTimePeriod(startDate, timeSelection)) ?? null;
    }

    return allNewHires?.filter(({ startDate }) => isBetweenTimePeriod(startDate, timeSelection)) ?? null;
};

const getWeeklyHires = (allNewHires: NewHiresDelta[]): string[] => {
    const availableWeeks: string[] = [];

    allNewHires?.forEach(({ startDate }) => {
        const isoWeek = isoWeekFromDateString(startDate);
        if (!availableWeeks?.includes(isoWeek)) {
            availableWeeks.push(isoWeek);
        }
    });

    return availableWeeks;
};

/**
    * Filters on week basis from Recuitments data
    * @param recruitmentNeedsData original array from response data
    * @param eachWeek string
    * @returns array of matching object per each week
*/
const getWeeklyData = (recruitmentNeedsData: RecruitmentNeedsDelta[], eachWeek: string): (TurnOverDelta & ContractsDelta)[] => recruitmentNeedsData
    ?.flatMap(({ data }) => data?.filter(({ startDate, endDate }) => generateTimeArray(startDate, endDate, 'week').toString() === eachWeek));

/**
* Calculates the TurnOver percentage
* @param estimatedTurnOverHeadCount number
* @param coworkerHeadCount number
* @returns number
*/
const getTurnOverPercentage = (
    estimatedTurnOverHeadCount: number,
    coworkerHeadCount: number
) => (coworkerHeadCount === 0 ? 0 : (estimatedTurnOverHeadCount / coworkerHeadCount) * 100);

const generateWeeklyData = (recruitmentNeedsData: RecruitmentNeedsDelta[], timeArray: string[]): HiringNeedsData => {
    const dataForEachWeek: WeekBasedData[] = timeArray.map(eachWeek => {
        const weekData = getWeeklyData(recruitmentNeedsData, eachWeek);
        const permanentContracts = calculateSum(weekData?.map(weekObj => weekObj?.permanentContracts ?? 0));
        const temporaryContracts = calculateSum(weekData?.map(weekObj => weekObj?.temporaryContracts ?? 0));
        const contingentContracts = calculateSum(weekData?.map(weekObj => weekObj?.contigentContracts ?? 0));
        const turnOverHeadCounts = calculateSum(weekData?.map(weekObj => (weekObj?.coworkerHeadCount ?? 0) * (weekObj?.turnOverRate ?? 0)));
        const turnOverPercentage = getTurnOverPercentage(turnOverHeadCounts, calculateSum(weekData?.map(weekObj => weekObj?.coworkerHeadCount ?? 0)));
        const recruitmentActions = calculateSum([permanentContracts, temporaryContracts, contingentContracts, turnOverHeadCounts]);

        return {
            eachWeek,
            permanentContracts,
            temporaryContracts,
            contingentContracts,
            turnOverHeadCount: turnOverHeadCounts,
            turnOverRate: turnOverPercentage,
            internalMovements: 0, // Todo: Implement this calculation once BE data is available
            recruitmentActions,
        };
    });

    const calculateTotal = (key: keyof Omit<WeekBasedData, 'eachWeek'>) => calculateSum(dataForEachWeek.map(week => week[key] ?? 0));

    return {
        week: dataForEachWeek,
        totalPermanentContracts: calculateTotal(HiringNeedsRowTypes.PERMANENT_CONTRACTS),
        totalTemporaryContracts: calculateTotal(HiringNeedsRowTypes.TEMPORARY_CONTRACTS),
        totalContingentContracts: calculateTotal(HiringNeedsRowTypes.CONTIGENT_CONTRACTS),
        totalTurnOverRates: calculateTotal(HiringNeedsRowTypes.ESTIMATED_TURNOVER_RATE),
        totalTurnOverHeadCount: calculateTotal(HiringNeedsRowTypes.ESTIMATED_TURNOVER_HEADCOUNT),
        totalInternalMovements: 0, // Todo: Implement this calculation once BE data is available
        totalActions: calculateTotal(HiringNeedsRowTypes.RECRUITMENT_ACTIONS),
    };
};

const generateHiringNeedsData = (
    costCenterOMData: CheckBox[] | undefined,
    tableData: RecruitmentNeedsDelta[] | undefined,
    timeArray: string[],
) => {
    // TODO: Once the full Cost center ID is sent from BE use it for filtering below instead last four digit as subLabel
    const filteredData = isSomeCcSelected(costCenterOMData)
        ? tableData?.filter(({ id }) => costCenterOMData?.find(({ subLabel }) => subLabel === id)?.defaultChecked ?? false)
        : tableData?.filter(({ id }) => costCenterOMData?.find(({ subLabel }) => subLabel === id) ?? false);

    if (!filteredData?.length) {
        return null;
    }

    return generateWeeklyData(filteredData, timeArray);
};

const generateHiringNeedsRows = (
    hiringNeedsRowsArray: HiringNeedsRowProps | null,
    tableData: HiringNeedsData,
) => {
    if (!tableData) {
        return null;
    }
    const weekData = tableData?.week;

    return hiringNeedsRowsArray?.map(({ title, tooltip, type, total }) => ({
        rowTitle: title,
        tooltipText: tooltip,
        data: weekData?.map((item: WeekBasedData) => item[type]),
        type,
        total,
    })) ?? null;
};

const getHiringNeedsRows = (hiringNeedsData: HiringNeedsData | null, t: TFunction<'translation', undefined, 'translation'>) => {
    if (!hiringNeedsData) {
        return null;
    }
    const hiringNeedsRowsArray = [
        {
            title: t('PERMANENT_CONTRACTS'),
            tooltip: t('PERMANENT_CONTRACTS_TOOLTIP'),
            type: HiringNeedsRowTypes.PERMANENT_CONTRACTS,
            total: hiringNeedsData.totalPermanentContracts
        },
        {
            title: t('TEMPORARY'),
            tooltip: t('TEMPORARY_CONTRACTS_TOOLTIP'),
            type: HiringNeedsRowTypes.TEMPORARY_CONTRACTS,
            total: hiringNeedsData.totalTemporaryContracts
        },
        {
            title: t('CONTIGENT_CONTRACTS'),
            tooltip: t('CONTIGENT_CONTRACTS_TOOLTIP'),
            type: HiringNeedsRowTypes.CONTIGENT_CONTRACTS,
            total: hiringNeedsData.totalContingentContracts
        },
        {
            title: t('ESTIMATED_TURNOVER_RATE'),
            tooltip: t('ESTIMATED_TURNOVER_RATE_TOOLTIP'),
            type: HiringNeedsRowTypes.ESTIMATED_TURNOVER_RATE,
            total: hiringNeedsData.totalTurnOverRates
        },
        {
            title: t('ESTIMATED_TURNOVER_HEADCOUNT'),
            tooltip: t('ESTIMATED_TURNOVER_HEADCOUNT_TOOLTIP'),
            type: HiringNeedsRowTypes.ESTIMATED_TURNOVER_HEADCOUNT,
            total: hiringNeedsData.totalTurnOverHeadCount
        },
        {
            title: t('INTERNAL_MOVEMENTS'),
            tooltip: t('INTERNAL_MOVEMENTS_TOOLTIP'),
            type: HiringNeedsRowTypes.INTERNAL_MOVEMENTS,
            total: hiringNeedsData.totalInternalMovements
        },
        {
            title: t('RECRUITMENT_ACTIONS'),
            tooltip: t('RECRUITMENT_ACTIONS_TOOLTIP'),
            type: HiringNeedsRowTypes.RECRUITMENT_ACTIONS,
            total: hiringNeedsData.totalActions
        }
    ];

    return generateHiringNeedsRows(hiringNeedsRowsArray, hiringNeedsData);
};

export { getFilteredNewHires, getCCLabel, generateHiringNeedsData, getHiringNeedsRows, getWeeklyHires };
