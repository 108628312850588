import { useCallback, useEffect, useRef, useState } from 'react';
import { EVENT_DISPLAY_TOAST } from 'types/events';
import { useEvent } from './useEvent';

export const TOAST_DEFAULT_VALUES = {
    isOpen: false,
    error: false,
    title: '',
    message: ''
};

export const useToast = (autoCloseDelay: number = 3000, global = true) => {
    const [toast, setToast] = useState<{ isOpen: boolean, error: boolean, title: string, message: string }>(TOAST_DEFAULT_VALUES);
    const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
    const isMounted = useRef<boolean>(false);
    const { publish } = useEvent();

    const resetToast = () => setToast(TOAST_DEFAULT_VALUES);

    const displayToast = useCallback(({ title, message }:{ title: string, message: string }) => setToast({ isOpen: true, error: false, title, message }), []);

    useEffect(() => {
        // If toast is triggered globally and hook is already mounted -> publish toast notification
        if (global && isMounted.current) {
            publish(EVENT_DISPLAY_TOAST, toast);
        }

        // Clear last timeout in case it's still running to avoid race condition
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Autohide toast
        const localTimeoutId = setTimeout(() => {
            resetToast();
        }, autoCloseDelay);

        // Keep track of the last timeout
        setTimeoutId(localTimeoutId);

        // Set hook as mounted on the first run
        if (!isMounted.current) {
            isMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toast]);

    return {
        toast, // Only used if toast is displayed locally
        displayToast,
    };
};
