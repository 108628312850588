import React, { useMemo } from 'react';
import Text from '@ingka/text';
import { TFunction } from 'i18next';
import useData from 'hooks/useData';
import useApp from 'hooks/useApp';
import { useTranslation } from 'react-i18next';
import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information';
import Table, { TableHeader, TableBody } from '@ingka/table';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import { HiringNeedsRowTypes, RecruitmentNeedsDelta } from 'types/recruitmentNeeds';
import HiringNeedsWeekHeader from './HiringNeedsWeekHeader';
import HiringNeedsRow from './HiringNeedsRow';
import classes from './HiringNeeds.module.scss';
import { generateHiringNeedsData, getHiringNeedsRows } from './RecruitmentNeedsFunctions';

type HiringNeedsTableProps = {
    t: TFunction<'translation', undefined, 'translation'>;
    isLoading: boolean;
    timeArray: string[];
    hiringNeedsRows: {
        rowTitle: string;
        tooltipText: string;
        data: number[];
        type: HiringNeedsRowTypes;
        total: number;
    }[] | null;
};

const HiringNeedsTable: React.FC<HiringNeedsTableProps> = ({ t, isLoading, timeArray, hiringNeedsRows }) => (
    <LoadingWrapper isLoading={isLoading} isLoadingIndicatorFixed>
        <Table fullWidth className={classes['hiring-needs__table']}>
            <TableHeader sticky>
                <tr>
                    <th colSpan={2}>&nbsp;</th>
                    <HiringNeedsWeekHeader timeArray={timeArray} />
                    <th><Text tagName="span" headingSize="xs">{t('TOTAL')}</Text></th>
                </tr>
            </TableHeader>
            <TableBody>
                {hiringNeedsRows?.map(({ type, rowTitle, tooltipText, data, total }) => (
                    <HiringNeedsRow
                        key={type}
                        rowTitle={rowTitle}
                        tooltipText={tooltipText}
                        data={data}
                        type={type}
                        totalCell={total}
                    />
                ))}
            </TableBody>
        </Table>
    </LoadingWrapper>
);

const HiringNeeds: React.FC<{ isLoading: boolean, recruitmentNeeds: RecruitmentNeedsDelta[] | null }> = ({ isLoading, recruitmentNeeds }) => {
    const { t } = useTranslation();
    const { currentScenario } = useData();
    const { orgSelection: { selections: { costCentreSelection } }, timeSelection } = useApp();
    const { timeArray } = timeSelection;

    const hiringNeedsData = useMemo(() => {
        if (recruitmentNeeds?.length) {
            const data = generateHiringNeedsData(costCentreSelection, recruitmentNeeds, timeArray);

            return getHiringNeedsRows(data ?? null, t);
        }

        return null;
    }, [recruitmentNeeds, costCentreSelection, timeArray, t]);

    return (
        <div className={classes['hiring-needs']}>
            <Text tagName="h5" headingSize="xs" id="hiring-needs-header" data-testid="hiring-needs-header">
                {t('DETAILS')}
            </Text>
            <div id="hiring-needs-sub-header" className={classes['hiring-needs__sub-header']}>
                <Text tagName="h1" bodySize="s" id="hiring-needs-sub-header" data-testid="hiring-needs-sub-header">
                    {t('HIRING_TABLE_REVIEW')}
                </Text>
            </div>
            <div className={classes['hiring-needs__table-container']}>
                {(!isLoading && !hiringNeedsData) || !currentScenario ? (
                    <InlineMessage
                        id="hiring-needs-inline-message"
                        data-testid="hiring-needs-inline-message"
                        ssrIcon={information}
                        title={!currentScenario ? t('COWORKERS_SELECT_SCENARIO') : t('NO_DATA_MESSAGE')}
                    />
                ) : (
                    <HiringNeedsTable t={t} isLoading={isLoading} timeArray={timeArray} hiringNeedsRows={hiringNeedsData} />
                )}
            </div>
        </div>
    );
};

export default HiringNeeds;
